import "./App.css";
import { useEffect, useState } from "react";
import styled from "styled-components";
// router imports
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// msal imports
import { useMsal } from "@azure/msal-react";
// component imports
import Login from "./pages/Login";
import PageNotFound from "./pages/PageNotFound";
import Header from "./components/common/headers/Header";
import ProfireTools from "./pages/ProfireTools";
import FlamecoDataSheet from "./pages/FlamecoDataSheet";
import axios, { InternalAxiosRequestConfig } from "axios";
import PageUnauthorized from "./pages/PageUnauthorized";
import Navbar from "./components/common/navigation/Navbar";
import Loading from "./components/common/modals/Loading";
import { InteractionStatus } from "@azure/msal-browser";
import EWP from "./pages/EWP";
import SingleEWPForm from "./pages/SingleEWPForm";
import { verifyEWPUser } from "./utilities/apiCalls/ewp/users";
import { ewpBackendErrorCodes } from "./utilities/staticObjects";

const App: React.FC = () => {
  const { accounts, inProgress } = useMsal();

  const [loading, setLoading] = useState<boolean>(false);
  const [isProfireUser, setIsProfireUser] = useState<boolean>(false);
  const [isEWPUser, setIsEWPUser] = useState<boolean>(false);
  const [user, setUser] = useState<any>(undefined);

  useEffect(() => {
    if (accounts.length > 0) {
      setLoading(true);
      if (accounts[0]?.username.includes("@profireenergy.com")) {
        setIsProfireUser(true);
        setUser(accounts[0]);
        verifyEWPUser(accounts[0].localAccountId).then((res: any) => {
          if (ewpBackendErrorCodes.includes(res.status)) {
            setIsEWPUser(false);
          } else {
            setIsEWPUser(true);
          }
        });
      } else {
        setUser(null);
      }
    } else {
      setIsProfireUser(false);
      setUser(null);
    }
    setLoading(false);
  }, [accounts]);

  axios.interceptors.request.use(
    (request: InternalAxiosRequestConfig) => {
      request.headers = request.headers ?? {};

      //safely set headers...?
      if (accounts[0] !== undefined) {
        request.headers["azureId"] = accounts[0].localAccountId;
      }

      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  if (user === undefined || loading || inProgress !== InteractionStatus.None) {
    return <Loading dataTestname={"app-loading"} />;
  } else if (!isProfireUser) {
    return (
      <>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <Login setIsProfireUser={setIsProfireUser} setUser={setUser} />
              }
            />
            <Route path="*" element={<PageUnauthorized />} />
          </Routes>
        </Router>
      </>
    );
  } else {
    return (
      <MainContainer className="App">
        <Router>
          <Header dataTestname="app-header" user={user} />
          <Navbar dataTestname={"app-navbar"} setAppLoading={setLoading} />
          <MainContent>
            <Routes>
              <Route
                path="/"
                element={<Navigate replace to="/profiretools" />}
              />
              <Route
                path="/profiretools"
                element={<ProfireTools isEWPUser={isEWPUser} />}
              />
              <Route
                path="/profiretools/flameco"
                element={<FlamecoDataSheet />}
              />
              {isEWPUser && (
                <Route path="/profiretools/ewp" element={<EWP user={user} />} />
              )}
              {isEWPUser && (
                <Route
                  path="/profiretools/ewp/:formId"
                  element={<SingleEWPForm user={user} />}
                />
              )}
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </MainContent>
        </Router>
      </MainContainer>
    );
  }
};

export default App;

const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
