import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import {
  ITemperatureAssignment,
  ITemperatureConfiguration,
} from "../../../types/tools";
import Dropdown from "../../common/formInputs/Dropdown";

interface AddTemperatureAssignmentProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: ITemperatureAssignment | undefined;
  aConfigurationDropdown: [string, string][];
  bConfigurationDropdown: [string, string][];
  temperatureConfigs: ITemperatureConfiguration[];
  existingTemperatureAssignment: ITemperatureAssignment[];
}

const AddTemperatureAssignment: React.FC<AddTemperatureAssignmentProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  aConfigurationDropdown,
  bConfigurationDropdown,
  temperatureConfigs,
  existingTemperatureAssignment,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [tempCardName, setTempCardName] = useState<string>(
    retainedData === undefined ? "" : retainedData.tempcardname!
  );
  const [tempCardNameErrors, setTempCardNameErrors] = useState<string>("");
  const handleTempCardName = (e: React.FormEvent<HTMLInputElement>) => {
    setTempCardName(e.currentTarget.value);
    setTempCardNameErrors("");
  };

  const [tc1a, setTC1A] = useState<string>(
    retainedData === undefined
      ? aConfigurationDropdown[0][0]!
      : retainedData.tc1a!
  );
  const handleTC1A = (newChosen: string) => {
    setTC1A(newChosen);
    if (
      temperatureConfigs.find((e) => e.temptag === newChosen)?.temptype ===
      "Dual"
    ) {
      setTC1B("Dual");
      setTC1BLock(true);
    } else {
      setTC1BLock(false);
    }
  };

  const [tc1b, setTC1B] = useState<string>(
    retainedData === undefined
      ? bConfigurationDropdown[0][0]!
      : retainedData.tc1b!
  );
  const [tc1bLock, setTC1BLock] = useState<boolean>(false);
  const handleTC1B = (newChosen: string) => {
    setTC1B(newChosen);
  };

  const [tc2a, setTC2A] = useState<string>(
    retainedData === undefined ? "" : retainedData.tc2a!
  );
  const handleTC2A = (newChosen: string) => {
    setTC2A(newChosen);
    if (
      temperatureConfigs.find((e) => e.temptag === newChosen)?.temptype ===
      "Dual"
    ) {
      setTC2B("Dual");
      setTC2BLock(true);
    } else {
      setTC2BLock(false);
    }
  };

  const [tc2b, setTC2B] = useState<string>(
    retainedData === undefined ? "" : retainedData.tc2b!
  );
  const [tc2bLock, setTC2BLock] = useState<boolean>(false);
  const handleTC2B = (newChosen: string) => {
    setTC2B(newChosen);
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTempCardNameErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!tempCardName) {
      setTempCardNameErrors("Temp Card Name is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingTemperatureAssignment.find(
        (card) => card.tempcardname === tempCardName
      ) !== undefined
    ) {
      setTempCardNameErrors("Temp Card Name is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newTemperatureAssignment: ITemperatureAssignment = {
        tempcardname: tempCardName,
        tc1a: tc1a,
        tc1b: tc1b,
        tc2a: tc2a,
        tc2b: tc2b,
      };

      onAdd(newTemperatureAssignment);
    }
  }
  return (
    <ModalBox
      dataTestname="add-temperature-assignment-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Temperature Assignment"
      maxHeight={"50%"}
    >
      <StyledAddTemperatureAssignmentDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-temperature-assignment-temp-card-name-common-input"
                type={"text"}
                labelText={"Temp Card Name"}
                required={true}
                readOnly={false}
                value={tempCardName}
                onChange={handleTempCardName}
                maxLength={50}
                placeholder={""}
                errors={tempCardNameErrors}
                autoFocus={true}
              />
              <Dropdown
                dataTestname={"add-temperature-assignment-tc1a-dropdown"}
                columns={aConfigurationDropdown}
                labelText={"TC1A"}
                onchange={handleTC1A}
                selected={tc1a}
              />
              <Dropdown
                dataTestname={"add-temperature-assignment-tc1b-dropdown"}
                columns={bConfigurationDropdown}
                labelText={"TC1B"}
                onchange={handleTC1B}
                selected={tc1b}
                readOnly={tc1bLock}
              />
              <Dropdown
                dataTestname={"add-temperature-assignment-tc2a-dropdown"}
                columns={aConfigurationDropdown}
                labelText={"TC2A"}
                onchange={handleTC2A}
                selected={tc2a}
              />
              <Dropdown
                dataTestname={"add-temperature-assignment-tc2b-dropdown"}
                columns={bConfigurationDropdown}
                labelText={"TC2B"}
                onchange={handleTC2B}
                selected={tc2b}
                readOnly={tc2bLock}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-temperature-assignment-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-temperature-assignment-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddTemperatureAssignmentDiv>
    </ModalBox>
  );
};

export default AddTemperatureAssignment;

const StyledAddTemperatureAssignmentDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
