export const customerTypeDropdown: [string, string][] = [
  ["Select a type...", "0"],
  ["End User", "End User"],
  ["OEM", "OEM"],
  ["Reseller", "Reseller"],
  ["Other", "Other"],
];

export const stackLocationDropdown: [string, string][] = [
  ["Left", "Left"],
  ["Right", "Right"],
  ["Top", "Top"],
];

export const applicationDropdown: [string, string][] = [
  ["Heater", "Heater"],
  ["Reboiler", "Reboiler"],
  ["Salt Bath", "Salt Bath"],
  ["Treater", "Treater"],
  ["Other", "Other"],
];

export const environmentDropdown: [string, string][] = [
  ["Normal", "Normal"],
  [
    "Polar (High snowfall requiring larger hood or elevated air supply)",
    "Polar (High snowfall requiring larger hood or elevated air supply)",
  ],
  ["High Elevation (Above 3000')", "High Elevation (Above 3000')"],
  [
    "High Humidity (Average humidity above 80%)",
    "High Humidity (Average humidity above 80%)",
  ],
  ["Offshore", "Offshore"],
];

export const hingeOrientationDropdown: [string, string][] = [
  ["Hinge on Left", "Hinge on Left"],
  ["Hinge on Right", "Hinge on Right"],
];

export const booleanDropdown: [string, string][] = [
  ["No", "No"],
  ["Yes", "Yes"],
];

export const ewpBackendErrorCodes: number[] = [404, 422, 429, 500, 502];

export const ioTypeDropdown: [string, string][] = [
  ["Pressure", "Pressure"],
  ["Flow", "Flow"],
  ["Level", "Level"],
  ["Custom", "Custom"],
  ["Appliance Firing Rate", "Appliance Firing Rate"],
  ["FARC Fuel Position", "FARC Fuel Position"],
  ["FARC Air Position", "FARC Air Position"],
  ["Bleed Valve Proof of Open", "Bleed Valve Proof of Open"],
  ["FARC Aux Position", "FARC Aux Position"],
  ["O₂ Sensor Reading", "O₂ Sensor Reading"],
];

export const ioSignalDropdown: [string, string][] = [
  ["Digital", "Digital"],
  ["4-20", "4-20"],
];

export const ioModeDropdown: [string, string][] = [
  ["Alarm", "Alarm"],
  ["Wait", "Wait"],
  ["Warning", "Warning"],
  ["Main Permissive", "Main Permissive"],
  ["Local Proof of Airflow", "Local Proof of Airflow"],
  ["Secondary PID Input", "Secondary PID Input"],
  ["Display Only", "Display Only"],
];

export const fourTwentyOutputDropdown: [string, string][] = [
  ["PID Output Controlled by 4-20mA Input", "PID Output Controlled by 4-20mA Input"],
  ["PID Output Controlled by TC Input", "PID Output Controlled by TC Input"],
  ["Air Position Controlled by FARC", "Air Position Controlled by FARC"],
  ["Aux Position Controlled by FARC", "Aux Position Controlled by FARC"],
];

export const normallyOpenClosedDryContactModeDropdown: [string, string][] = [
  ["Disabled", "Disabled"],
  ["Started Status", "Started Status"],
  ["High Temp Status", "High Temp Status"],
  ["No Alert Status", "No Alert Status"],
  ["Appliance Proc Control", "Appliance Proc Control"],
  ["Controller Proc Control", "Controller Proc Control"],
  ["Temp Setpoint Trip", "Temp Setpoint Trip"],
  ["Input Setpoint Trip", "Input Setpoint Trip"],
  ["Purge Status", "Purge Status"],
];

export const moduleVoltageDropdown: [string, string][] = [
  ["12V", "12V"],
  ["24V", "24V"],
];

export const dryContactTripConfigurationDropdown: [string, string][] = [
  ["Any configured temperature input", "Any configured temperature input"],
  ["Any configured I/O expansion input", "Any configured I/O expansion input"],
];

export const tempControlModeDropdown: [string, string][] = [
  ["Primary Process", "Primary Process"],
  ["Disabled", "Disabled"],
  ["Display Only", "Display Only"],
  ["High Temp ESD", "High Temp ESD"],
  ["Aux Process", "Aux Process"],
];

export const tempApplianceShutdownDropdown: [string, string][] = [
  ["Enabled", "Enabled"],
  ["Disabled", "Disabled"],
];

export const tempTypeDropdown: [string, string][] = [
  ["Dual", "Dual"],
  ["Single", "Single"],
];