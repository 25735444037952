import axios from "axios";

export const getEWPUsers = async () => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/users/`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};

export const verifyEWPUser = async (user_id: string) => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/users/id/${user_id}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};