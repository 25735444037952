import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IDWGInfo } from "../../../types/tools";

interface AddDWGInfoProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IDWGInfo | undefined;
  existingDWGInfo: IDWGInfo[];
}

const AddDWGInfo: React.FC<AddDWGInfoProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingDWGInfo,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [dwgNumber, setDWGNumber] = useState<string>(
    retainedData === undefined ? "" : retainedData.dwgnumber!
  );
  const [dwgNumberErrors, setDWGNumberErrors] = useState<string>("");
  const handleDWGNumber = (e: React.FormEvent<HTMLInputElement>) => {
    setDWGNumber(e.currentTarget.value);
    setDWGNumberErrors("");
  };

  const [dwgDescription, setDWGDescription] = useState<string>(
    retainedData === undefined ? "" : retainedData.dwgdesc!
  );
  const [dwgDescriptionErrors, setDWGDescriptionErrors] = useState<string>("");
  const handleDWGDescription = (e: React.FormEvent<HTMLInputElement>) => {
    setDWGDescription(e.currentTarget.value);
    setDWGDescriptionErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setDWGNumberErrors("");
    setDWGDescriptionErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!dwgNumber) {
      setDWGNumberErrors("DWG Number is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingDWGInfo.find((dwg) => dwg.dwgnumber === dwgNumber) !== undefined
    ) {
      setDWGNumberErrors("DWG Number is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newDWGInfo: IDWGInfo = {
        dwgnumber: dwgNumber,
        dwgdesc: dwgDescription,
      };

      onAdd(newDWGInfo);
    }
  }
  return (
    <ModalBox
      dataTestname="add-dwg-info-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add DWG Info"
      maxHeight={"50%"}
    >
      <StyledAddDWGInfoDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-dwg-info-dwg-number-common-input"
                type={"text"}
                labelText={"DWG Number"}
                required={true}
                readOnly={false}
                value={dwgNumber}
                onChange={handleDWGNumber}
                maxLength={50}
                placeholder={""}
                errors={dwgNumberErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="add-dwg-info-dwg-description-common-input"
                type={"text"}
                labelText={"DWG Description"}
                required={false}
                readOnly={false}
                value={dwgDescription}
                onChange={handleDWGDescription}
                maxLength={50}
                placeholder={""}
                errors={dwgDescriptionErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-dwg-info-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-dwg-info-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddDWGInfoDiv>
    </ModalBox>
  );
};

export default AddDWGInfo;

const StyledAddDWGInfoDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
