import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  IEWPAutomationForm,
  IEWPForm,
  IEWPFormListDetails,
  IEWPUser,
  temp,
} from "../types/tools";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import { useNavigate } from "react-router";
import ProfireButton from "../components/common/buttons/ProfireButton";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import AddForm from "../components/ewp/form/AddForm";
import {
  addNewEWPForm,
  getActiveEWPForms,
  toggleActiveEWPForm,
} from "../utilities/apiCalls/ewp/forms";
import { ewpBackendErrorCodes } from "../utilities/staticObjects";
import {
  CopyObjectNoPointer,
  PropertyWrite,
} from "../utilities/propertyAccess";
import { AccountInfo } from "@azure/msal-common";
import { getEWPUsers } from "../utilities/apiCalls/ewp/users";

interface EWPProps {
  user: AccountInfo;
}

const EWP: React.FC<EWPProps> = ({ user }) => {
  const [forms, setForms] = useState<IEWPFormListDetails[]>([]);

  const navigate = useNavigate();
  const handleRedirect = (e: IEWPForm) => {
    navigate(`${e.id}`);
  };

  useEffect(() => {
    let ewpUsers: IEWPUser[] = [];

    //gets all users to obtain matching email address per user_id
    getEWPUsers().then((res: any) => {
      if (ewpBackendErrorCodes.includes(res.status)) {
        ewpUsers = [];
      } else {
        ewpUsers = res.data;
      }
    });

    getActiveEWPForms(user.localAccountId!).then((res: any) => {
      if (ewpBackendErrorCodes.includes(res.status)) {
        setForms([]);
      } else {
        //sorts the forms and assigns them to existingForms
        //loop through existingForms and correct the owner_id to show the email, push each form into fixedForms array
        //then sort fixedForms again to ensure order and assign it using setForms
        let existingForms: IEWPFormListDetails[] = res.data.sort(
          (a: IEWPFormListDetails, b: IEWPFormListDetails) => a.id - b.id
        );
        let fixedForms: IEWPFormListDetails[] = [];

        existingForms.forEach((form) => {
          let fixedForm: IEWPFormListDetails = {
            active: form.active,
            id: form.id,
            last_edit_date: form.last_edit_date,
            submit_date: form.submit_date,
            title: form.title,
            owner_id:
              ewpUsers.find((user) => user.id === form.owner_id)?.email !==
              undefined
                ? ewpUsers.find((user) => user.id === form.owner_id)?.email!
                : form.owner_id,
          };

          fixedForms.push(fixedForm);
        });
        setForms(
          fixedForms.sort(
            (a: IEWPFormListDetails, b: IEWPFormListDetails) => a.id - b.id
          )
        );
      }
    });
  }, [user]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", ""]);

  const [headers] = useState<string[]>(["title", "owner_id", "", ""]);

  const [overwriteHeaders] = useState<string[]>([
    "Project Number",
    "Owner",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleRedirect,
    showDeleteFormConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, false, true, true]);

  //Add Form
  const [showAddForm, setShowAddForm] = useState<boolean>(false);
  const [addFormCBActive, setAddFormCBActive] = useState<boolean>(false);
  const [addFormCBText, setAddFormCBText] = useState<string>("");
  const [retainedProjectNumber, setRetainedProjectNumber] = useState<string>();
  const newForm = useRef(temp);

  //changing fields/data will activate this function, updating the local state
  const handleUpdate = (newValue: any, field: string) => {
    let data: IEWPAutomationForm = temp;
    PropertyWrite(data, field, newValue);

    newForm.current = CopyObjectNoPointer(data);
  };

  function handleShowAddForm() {
    if (showAddForm) {
      setRetainedProjectNumber(undefined);
    }
    setShowAddForm(!showAddForm);
  }

  //This function runs on clicking Save in Add Form
  function handleAddForm(e: string) {
    setShowAddForm(false);
    setAddFormCBActive(true);

    handleUpdate(e, "projectnumber");
    addNewEWPForm(user.localAccountId!, newForm.current).then((res: any) => {
      if (ewpBackendErrorCodes.includes(res.status)) {
        setAddFormCBText(
          `Form was not created, details below:\n${res.data.detail}`
        );
        fetchForms();
      } else {
        setAddFormCBText(`${e} successfully created`);
        setRetainedProjectNumber(undefined);
        fetchForms();
      }
    });
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddForm() {
    setAddFormCBActive(false);
    setAddFormCBText("");

    if (retainedProjectNumber !== undefined) {
      setShowAddForm(true);
    }
  }

  function fetchForms() {
    let ewpUsers: IEWPUser[] = [];

    getEWPUsers().then((res: any) => {
      if (ewpBackendErrorCodes.includes(res.status)) {
        ewpUsers = [];
      } else {
        ewpUsers = res.data;
      }
    });

    getActiveEWPForms(user.localAccountId!).then((res: any) => {
      if (ewpBackendErrorCodes.includes(res.status)) {
        setForms([]);
      } else {
        //sorts the forms and assigns them to existingForms
        //loop through existingForms and correct the owner_id to show the email, push each form into fixedForms array
        //then sort fixedForms again to ensure order and assign it using setForms
        let existingForms: IEWPFormListDetails[] = res.data.sort(
          (a: IEWPFormListDetails, b: IEWPFormListDetails) => a.id - b.id
        );
        let fixedForms: IEWPFormListDetails[] = [];

        existingForms.forEach((form) => {
          let fixedForm: IEWPFormListDetails = {
            active: form.active,
            id: form.id,
            last_edit_date: form.last_edit_date,
            submit_date: form.submit_date,
            title: form.title,
            owner_id:
              ewpUsers.find((user) => user.id === form.owner_id)?.email !==
              undefined
                ? ewpUsers.find((user) => user.id === form.owner_id)?.email!
                : form.owner_id,
          };

          fixedForms.push(fixedForm);
        });
        setForms(
          fixedForms.sort(
            (a: IEWPFormListDetails, b: IEWPFormListDetails) => a.id - b.id
          )
        );
      }
    });
  }

  //Delete Form
  const [deleteFormCBActive, setDeleteFormCBActive] = useState<boolean>(false);
  const [deleteFormCBText, setDeleteFormCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteFormCBFinalDisplay, setDeleteFormCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteForm, setChosenDeleteForm] =
    useState<IEWPFormListDetails>();

  //This function runs on clicking the Delete icon in the Forms Table
  function showDeleteFormConfirmationBox(e: IEWPFormListDetails) {
    setChosenDeleteForm(e);
    setDeleteFormCBActive(true);
    setDeleteFormCBText(`Are you sure you want to delete ${e.title}?`);
  }

  //This function runs on clicking Cancel in Delete Form
  function hideDeleteFormConfirmationBox(e: any) {
    setDeleteFormCBActive(false);
    setDeleteFormCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete Form
  function handleDeleteForm() {
    setDeleteFormCBText("");

    toggleActiveEWPForm(user.localAccountId!, chosenDeleteForm?.id!).then(
      (res: any) => {
        if (ewpBackendErrorCodes.includes(res.status)) {
          setDeleteFormCBText(
            `Form was not deleted, details below:\n${res.data.detail}`
          );
          fetchForms();
        } else {
          setDeleteFormCBText(
            `${chosenDeleteForm?.title} successfully deleted`
          );
          fetchForms();
        }
      }
    );

    setDeleteFormCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete Form
  function completeDeleteForm(e: any) {
    setDeleteFormCBActive(false);
    setDeleteFormCBText("");
    setDeleteFormCBFinalDisplay(false);
    setChosenDeleteForm(undefined);
  }

  return (
    <StyledProfireToolsDiv>
      <StyledMainDiv>
        <ConfirmationBox
          dataTestname="ewp-add-form-confirmation-box"
          heading={"Information"}
          message={addFormCBText}
          active={addFormCBActive}
          onOk={completeAddForm}
          displayCancel={false}
        />
        <ConfirmationBox
          dataTestname="ewp-delete-form-confirmation-box"
          heading={"Information"}
          message={deleteFormCBText}
          active={deleteFormCBActive}
          onOk={completeDeleteForm}
          onYes={handleDeleteForm}
          onCancel={hideDeleteFormConfirmationBox}
          displayCancel={true}
          finalDisplay={deleteFormCBFinalDisplay}
        />
        <DefaultContainer
          dataTestname="ewp-default-container"
          title="Active EWP Forms"
          darkBackground={true}
        >
          <CommonCustomizableTable
            dataTestname="ewp-flameco-common-customizable-table"
            usageIdentifier="ewp"
            data={forms}
            elements={elements}
            elementsInfo={elementsInfo}
            headers={headers}
            overwriteHeaders={overwriteHeaders}
            tableWidth={"100%"}
            tableMinWidth={"100%"}
            tableMaxHeight={"100%"}
            desktopColWidths={["50% - 33px", "50% - 33px", "3.33%", "3.33%"]}
            desktopColMinWidths={["50% - 33px", "50% - 33px", "33px", "33px"]}
            mobileColWidths={["100% - 33px - 33px", "0px", "3.33%", "3.33%"]}
            mobileColMinWidths={["100% - 33px - 33px", "0px", "33px", "33px"]}
            elementsOnClick={elementsOnClick}
            textAlign={["left", "left", "left", "left"]}
            tdHeight={"45px"}
            colDisplayOnMobile={colDisplayOnMobile}
            addPaddingRightForScroll={true}
            colJustify={[]}
            tdHeightMobile={"45px"}
            colFlexDirection={[]}
          />
          <StyledSpaceDiv />
          <StyledAddButtonDiv data-testid="ewp-add-button-div">
            <ProfireButton
              dataTestname="ewp-create-new-form-button"
              onClickFunction={handleShowAddForm}
              text="Create New Form"
              size={6}
            />
            {showAddForm && (
              <AddForm
                active={showAddForm}
                onCancel={handleShowAddForm}
                onAdd={handleAddForm}
                retainedData={retainedProjectNumber}
                existingForms={forms}
              />
            )}
          </StyledAddButtonDiv>
        </DefaultContainer>
      </StyledMainDiv>
    </StyledProfireToolsDiv>
  );
};

export default EWP;

const StyledProfireToolsDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 93px 16px 20px 16px;
  height: Calc(100% - 113px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 93px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;
`;
