import React, { useState } from "react";
import styled from "styled-components";
import { IApplianceController, IEWPAutomationForm } from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddController from "./applianceDetails/AddController";
import EditController from "./applianceDetails/EditController";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ApplianceDetails: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [controllers, setControllers] = useState<IApplianceController[]>(
    CopyObjectNoPointer(form.applianceconfigtable)
  );

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>([
    "controllername",
    "controllercomments",
    "",
    "",
  ]);

  const [overwriteHeaders] = useState<string[]>(["Name", "Comments", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditController,
    showDeleteControllerConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add Controller
  const [showAddController, setShowAddController] = useState<boolean>(false);
  const [addControllerCBActive, setAddControllerCBActive] =
    useState<boolean>(false);
  const [addControllerCBText, setAddControllerCBText] = useState<string>("");
  const [retainedNewController, setRetainedNewController] =
    useState<IApplianceController>();

  function handleShowAddController() {
    if (showAddController) {
      setRetainedNewController(undefined);
    }
    setShowAddController(!showAddController);
  }

  //This function runs on clicking Save in Add Controller
  function handleAddController(e: IApplianceController) {
    setShowAddController(false);
    setAddControllerCBActive(true);

    controllers.push(e);
    onChange(controllers, "applianceconfigtable");

    setAddControllerCBText(`${e.controllername} successfully added`);
    setRetainedNewController(undefined);
    setControllerAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddController() {
    setAddControllerCBActive(false);
    setAddControllerCBText("");

    if (retainedNewController !== undefined) {
      setShowAddController(true);
    }
  }

  function setControllerAfterAdd() {
    setControllers(form.applianceconfigtable);
  }

  //Edit Controller
  const [showEditController, setShowEditController] = useState<boolean>(false);
  const [editControllerCBActive, setEditControllerCBActive] =
    useState<boolean>(false);
  const [editControllerCBText, setEditControllerCBText] = useState<string>("");
  const [editControllerCBFinalDisplay, setEditControllerCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditController is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditController, setRetainedEditController] = useState<
    IApplianceController | undefined
  >();
  const [chosenEditController, setChosenEditController] =
    useState<IApplianceController>();

  function handleShowEditController(e: any = undefined) {
    if (showEditController) {
      setShowEditController(false);
      setChosenEditController(undefined);
    } else {
      setShowEditController(!showEditController);
      setChosenEditController(e);
    }
  }

  //This function runs on clicking Save in Edit Controller
  function showEditControllerConfirmationBox(e: IApplianceController) {
    setShowEditController(false);
    setEditControllerCBActive(true);
    setEditControllerCBText("Are you sure you want to save these changes?");
    setRetainedEditController(e);
  }

  //This function runs on clicking Cancel in Edit Controller
  function hideEditControllerConfirmationBox() {
    setShowEditController(false);
    setEditControllerCBActive(false);
    setEditControllerCBText("");
    setRetainedEditController(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit Controller
  function handleEditController() {
    setEditControllerCBText("");

    setControllers(
      controllers.splice(
        GetEqualityIndex(controllers, chosenEditController),
        1,
        retainedEditController!
      )
    );
    onChange(controllers, "applianceconfigtable");

    setEditControllerCBText(
      `${retainedEditController!.controllername} successfully updated`
    );
    setRetainedEditController(undefined);
    setControllerAfterAdd();
    save();

    setEditControllerCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit Controller
  function completeEditController() {
    setEditControllerCBActive(false);
    setEditControllerCBText("");
    setEditControllerCBFinalDisplay(false);
    setChosenEditController(undefined);

    if (retainedEditController !== undefined) {
      setShowEditController(true);
    }
  }

  //Delete Controller
  const [deleteControllerCBActive, setDeleteControllerCBActive] =
    useState<boolean>(false);
  const [deleteControllerCBText, setDeleteControllerCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteControllerCBFinalDisplay, setDeleteControllerCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteController, setChosenDeleteController] =
    useState<IApplianceController>();

  //This function runs on clicking Save in Delete Controller
  function showDeleteControllerConfirmationBox(e: IApplianceController) {
    setChosenDeleteController(e);
    setDeleteControllerCBActive(true);
    setDeleteControllerCBText(
      `Are you sure you want to delete ${e.controllername}?`
    );
  }

  //This function runs on clicking Cancel in Delete Controller
  function hideDeleteControllerConfirmationBox(e: any) {
    setDeleteControllerCBActive(false);
    setDeleteControllerCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete Controller
  function handleDeleteController() {
    setDeleteControllerCBText("");

    setControllers(
      controllers.splice(
        GetEqualityIndex(controllers, chosenDeleteController),
        1
      )
    );
    onChange(controllers, "applianceconfigtable");

    setDeleteControllerCBText(
      `${chosenDeleteController!.controllername} successfully deleted`
    );
    setControllerAfterAdd();
    save();

    setDeleteControllerCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete Controller
  function completeDeleteController(e: any) {
    setDeleteControllerCBActive(false);
    setDeleteControllerCBText("");
    setDeleteControllerCBFinalDisplay(false);
    setChosenDeleteController(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="appliance-details-appliance-add-appliance-confirmation-box"
        heading={"Information"}
        message={addControllerCBText}
        active={addControllerCBActive}
        onOk={completeAddController}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-appliance-edit-appliance-confirmation-box"
        heading={"Information"}
        message={editControllerCBText}
        active={editControllerCBActive}
        onOk={completeEditController}
        onYes={handleEditController}
        onCancel={hideEditControllerConfirmationBox}
        displayCancel={true}
        finalDisplay={editControllerCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-appliance-delete-appliance-confirmation-box"
        heading={"Information"}
        message={deleteControllerCBText}
        active={deleteControllerCBActive}
        onOk={completeDeleteController}
        onYes={handleDeleteController}
        onCancel={hideDeleteControllerConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteControllerCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-common-customizable-table"
        usageIdentifier="appliance-details"
        data={controllers}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-add-button"}
          onClickFunction={handleShowAddController}
          text={"Add Controller"}
          size={4}
        />
        {showAddController && (
          <AddController
            active={showAddController}
            onCancel={handleShowAddController}
            onAdd={handleAddController}
            retainedData={retainedNewController}
            existingController={form.applianceconfigtable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditController && (
        <EditController
          active={showEditController}
          controller={chosenEditController!}
          onCancel={handleShowEditController}
          onEdit={showEditControllerConfirmationBox}
          retainedData={retainedEditController}
          existingController={controllers}
        />
      )}
    </StyledMainDiv>
  );
};

export default ApplianceDetails;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
