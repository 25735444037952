import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ITool } from "../types/tools";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import CommonCustomizableTable from "../components/common/tables/CommonCustomizableTable";
import { useNavigate } from "react-router";

interface ProfireToolsProps {
  isEWPUser: boolean;
}

const ProfireTools: React.FC<ProfireToolsProps> = ({ isEWPUser }) => {
  const [tools, setTools] = useState<ITool[]>(
    isEWPUser
      ? [
          {
            name: "Flameco Data Sheet Submission",
            description: "Enter gathered customer data for Flameco Appliance",
            route: "flameco",
          },
          {
            name: "EWP Automation Tool",
            description: "Enter data to generate EWP Documents",
            route: "ewp",
          },
        ]
      : [
          {
            name: "Flameco Data Sheet Submission",
            description: "Enter gathered customer data for Flameco Appliance",
            route: "flameco",
          },
        ]
  );

  const navigate = useNavigate();
  const handleRedirect = (e: ITool) => {
    navigate(`${e.route}`);
  };

  useEffect(() => {
    setTools(
      isEWPUser
        ? [
            {
              name: "Flameco Data Sheet Submission",
              description: "Enter gathered customer data for Flameco Appliance",
              route: "flameco",
            },
            {
              name: "EWP Automation Tool",
              description: "Enter data to generate EWP Documents",
              route: "ewp",
            },
          ]
        : [
            {
              name: "Flameco Data Sheet Submission",
              description: "Enter gathered customer data for Flameco Appliance",
              route: "flameco",
            },
          ]
    );
  }, [isEWPUser]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "RouteButton"]);

  const [elementsInfo] = useState<string[]>(["", "", "View"]);

  const [headers] = useState<string[]>(["name", "description", "route"]);

  const [overwriteHeaders] = useState<string[]>(["Name", "Description", ""]);

  const [elementsOnClick] = useState<any[]>([null, null, handleRedirect]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, false, true]);

  const [justifyRow] = useState<string[]>(["start", "start", "end"]);

  return (
    <StyledProfireToolsDiv>
      <StyledMainDiv>
        <DefaultContainer
          dataTestname="profire-tools-default-container"
          title="Tools"
          darkBackground={true}
        >
          <CommonCustomizableTable
            dataTestname="profire-tools-flameco-common-customizable-table"
            usageIdentifier="tool"
            data={tools}
            elements={elements}
            elementsInfo={elementsInfo}
            headers={headers}
            overwriteHeaders={overwriteHeaders}
            tableWidth={"100%"}
            tableMinWidth={"100%"}
            tableMaxHeight={"100%"}
            desktopColWidths={["75%", "150%", "37.5%"]}
            mobileColWidths={["75%", "150%", "37.5%"]}
            desktopColMinWidths={["160px", "295px", "105px"]}
            mobileColMinWidths={["160px", "295px", "105px"]}
            elementsOnClick={elementsOnClick}
            textAlign={["left", "left", "left"]}
            tdHeight={"45px"}
            colDisplayOnMobile={colDisplayOnMobile}
            addPaddingRightForScroll={true}
            colJustify={justifyRow}
            tdHeightMobile={"45px"}
            colFlexDirection={[]}
          />
        </DefaultContainer>
      </StyledMainDiv>
    </StyledProfireToolsDiv>
  );
};

export default ProfireTools;

const StyledProfireToolsDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 93px 16px 20px 16px;
  height: Calc(100% - 113px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 93px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
