import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { ITCV } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";

interface EditDocumentInfoProps {
  active: boolean;
  tcvInfo: ITCV;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: ITCV | undefined;
  existingTCVInfo: ITCV[];
}

const EditDocumentInfo: React.FC<EditDocumentInfoProps> = ({
  active,
  tcvInfo,
  onCancel,
  onEdit,
  retainedData,
  existingTCVInfo,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [tag, setTag] = useState<string>(
    retainedData === undefined
      ? tcvInfo.tcvtag === undefined
        ? ""
        : tcvInfo.tcvtag!
      : retainedData.tcvtag!
  );
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    setTag(e.currentTarget.value);
    setTagErrors("");
  };

  const [purgePosition, setPurgePosition] = useState<string>(
    retainedData === undefined
      ? tcvInfo.tcvpurgeposition === undefined
        ? ""
        : tcvInfo.tcvpurgeposition!
      : retainedData.tcvpurgeposition!
  );
  const [purgePositionErrors, setPurgePositionErrors] = useState<string>("");
  const handlePurgePosition = (e: React.FormEvent<HTMLInputElement>) => {
    setPurgePosition(e.currentTarget.value);
    setPurgePositionErrors("");
  };

  const [pilotPosition, setPilotPosition] = useState<string>(
    retainedData === undefined
      ? tcvInfo.tcvpilotposition === undefined
        ? ""
        : tcvInfo.tcvpilotposition!
      : retainedData.tcvpilotposition!
  );
  const [pilotPositionErrors, setPilotPositionErrors] = useState<string>("");
  const handlePilotPosition = (e: React.FormEvent<HTMLInputElement>) => {
    setPilotPosition(e.currentTarget.value);
    setPilotPositionErrors("");
  };

  const [minimumPosition, setMinimumPosition] = useState<string>(
    retainedData === undefined
      ? tcvInfo.tcvminimumposition === undefined
        ? ""
        : tcvInfo.tcvminimumposition!
      : retainedData.tcvminimumposition!
  );
  const [minimumPositionErrors, setMinimumPositionErrors] =
    useState<string>("");
  const handleMinimumPosition = (e: React.FormEvent<HTMLInputElement>) => {
    setMinimumPosition(e.currentTarget.value);
    setMinimumPositionErrors("");
  };

  const [isFARCOutput, setIsFARCOutput] = useState<boolean>(
    retainedData === undefined
      ? tcvInfo.tcv_is_farc_output === undefined
        ? false
        : tcvInfo.tcv_is_farc_output
      : retainedData.tcv_is_farc_output!
  );
  const handleIsFARCOutput = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setIsFARCOutput(checked);
  };

  const [farcName, setFarcName] = useState<string>(
    retainedData === undefined
      ? tcvInfo.farcwizardchannelsettingname === undefined
        ? ""
        : tcvInfo.farcwizardchannelsettingname!
      : retainedData.farcwizardchannelsettingname!
  );
  const [farcNameErrors, setFarcNameErrors] = useState<string>("");
  const handleFarcName = (e: React.FormEvent<HTMLInputElement>) => {
    setFarcName(e.currentTarget.value);
    setFarcNameErrors("");
  };

  const [farcPosition, setFarcPosition] = useState<string>(
    retainedData === undefined
      ? tcvInfo.farcwizardchannelsettingoffposition === undefined
        ? ""
        : tcvInfo.farcwizardchannelsettingoffposition!
      : retainedData.farcwizardchannelsettingoffposition!
  );
  const [farcPositionErrors, setFarcPositionErrors] = useState<string>("");
  const handleFarcPosition = (e: React.FormEvent<HTMLInputElement>) => {
    setFarcPosition(e.currentTarget.value);
    setFarcPositionErrors("");
  };

  const [externalPowerRequired, setExternalPowerRequired] = useState<boolean>(
    retainedData === undefined
      ? tcvInfo.external_power_required === undefined
        ? false
        : tcvInfo.external_power_required
      : retainedData.external_power_required!
  );
  const handleExternalPowerRequired = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setExternalPowerRequired(checked);
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      tcvInfo.tcvtag !== tag &&
      existingTCVInfo.find((tcv) => tcv.tcvtag === tag) !== undefined
    ) {
      setTagErrors("Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const updatedDocumentInfo: ITCV = {
        tcvtag: tag,
        tcvpurgeposition: purgePosition,
        tcvpilotposition: pilotPosition,
        tcvminimumposition: minimumPosition,
        tcv_is_farc_output: isFARCOutput,
        farcwizardchannelsettingname: farcName,
        farcwizardchannelsettingoffposition: farcPosition,
        external_power_required: externalPowerRequired,
      };

      onEdit(updatedDocumentInfo);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-tcv-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Document Info"
      maxHeight={"50%"}
    >
      <StyledEditDocumentInfoDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-tcv-tcv-tag-common-input"
                type={"text"}
                labelText={"TCV Tag"}
                required={true}
                readOnly={false}
                value={tag}
                onChange={handleTag}
                maxLength={50}
                placeholder={""}
                errors={tagErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-tcv-tcv-purge-position-common-input"
                type={"text"}
                labelText={"TCV Purge Position"}
                required={false}
                readOnly={false}
                value={purgePosition}
                onChange={handlePurgePosition}
                maxLength={50}
                placeholder={""}
                errors={purgePositionErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-tcv-tcv-pilot-position-common-input"
                type={"text"}
                labelText={"TCV Pilot Position"}
                required={false}
                readOnly={false}
                value={pilotPosition}
                onChange={handlePilotPosition}
                maxLength={50}
                placeholder={""}
                errors={pilotPositionErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-tcv-pilot-minimum-position-common-input"
                type={"text"}
                labelText={"TCV Minimum Position"}
                required={false}
                readOnly={false}
                value={minimumPosition}
                onChange={handleMinimumPosition}
                maxLength={50}
                placeholder={""}
                errors={minimumPositionErrors}
                autoFocus={false}
              />
              <Checkbox
                dataTestname={"edit-tcv-is-farc-output-checkbox"}
                isChecked={isFARCOutput === null ? false : isFARCOutput}
                handleChange={handleIsFARCOutput}
                label={"Is FARC Output?"}
                arrayLetter={"T"}
              />
              <CommonInput
                dataTestname="edit-tcv-farc-wizard-channel-setting-name-common-input"
                type={"text"}
                labelText={"FARC Wizard Channel Setting Name"}
                required={false}
                readOnly={false}
                value={farcName}
                onChange={handleFarcName}
                maxLength={50}
                placeholder={""}
                errors={farcNameErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-tcv-farc-wizard-channel-setting-off-position-common-input"
                type={"text"}
                labelText={"FARC Wizard Channel Setting Off Position"}
                required={false}
                readOnly={false}
                value={farcPosition}
                onChange={handleFarcPosition}
                maxLength={50}
                placeholder={""}
                errors={farcPositionErrors}
                autoFocus={false}
              />
              <Checkbox
                dataTestname={"edit-tcv-external-power-required-checkbox"}
                isChecked={
                  externalPowerRequired === null ? false : externalPowerRequired
                }
                handleChange={handleExternalPowerRequired}
                label={"External Power Required?"}
                arrayLetter={"T"}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-tcv-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-tcv-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditDocumentInfoDiv>
    </ModalBox>
  );
};

export default EditDocumentInfo;

const StyledEditDocumentInfoDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
