import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm, IGenerateErrors } from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import { submitGenerateEWPForm } from "../../utilities/apiCalls/ewp/forms";
import { AccountInfo } from "@azure/msal-common";
import { useParams } from "react-router-dom";
import { DecodeBackendResponse } from "../../utilities/apiCalls/ewp/ewpValidationDecoder";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import { ewpBackendErrorCodes } from "../../utilities/staticObjects";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  user: AccountInfo;
  generateErrors: IGenerateErrors[];
  setGenerateErrors: Function;
  save: Function;
  saving: boolean;
}

const Generate: React.FC<SectionProps> = ({
  onChange,
  form,
  user,
  generateErrors,
  setGenerateErrors,
  save,
  saving,
}) => {
  const params = useParams();

  const [generating, setGenerating] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean | null>(null);

  const handleGenerate = () => {
    setGenerateErrors([]);
    setGenerating(true);
    setSuccess(null);

    submitGenerateEWPForm(user.localAccountId!, parseInt(params.formId!)).then(
      (res: any) => {
        if (res === undefined) {
          setGenerating(false);
          setSuccess(false);
        } else if (res.status === 422) {
          setGenerating(false);
          setGenerateErrors(DecodeBackendResponse(res.data));
          setSuccess(false);
        } else if (ewpBackendErrorCodes.includes(res.status)) {
          setGenerating(false);
          setSuccess(false);
        } else {
          setGenerating(false);
          setSuccess(true);
        }
      }
    );
  };

  const handleSave = () => {
    save();
  };

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Data", "Data"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>(["section", "entry", "field", "error"]);

  const [elementsOnClick] = useState<any[]>([null, null, null, null]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  return (
    <StyledMainDiv>
      <StyledMessageDiv data-testname={"generate-info-message"}>
        Once all information has been entered in the other sections, use the
        button below to Generate the EWP documents.
      </StyledMessageDiv>
      <StyledImportantMessageDiv data-testname={"generate-info-message"}>
        Important! Only Saved changes will be submitted. Please ensure all
        changes have been saved before clicking Generate.
      </StyledImportantMessageDiv>
      <StyledSpaceDiv />
      <StyledSaveButtonDiv>
        <StyledButtonContainerDiv>
          <ProfireButton
            dataTestname={"ewp-save-button"}
            text={"Save"}
            onClickFunction={handleSave}
            loading={saving}
            disabled={generating}
          />
        </StyledButtonContainerDiv>
        <StyledButtonContainerDiv>
          <ProfireButton
            dataTestname={"ewp-save-button"}
            text={"Generate"}
            onClickFunction={handleGenerate}
            loading={generating}
            disabled={saving}
          />
        </StyledButtonContainerDiv>
      </StyledSaveButtonDiv>
      <StyledSpaceDiv />
      {generateErrors.length > 0 && (
        <>
          <StyledErrorsMessageDiv data-testname={"generate-info-error-message"}>
            Encountered the following errors when attempting to generate
            documents:
          </StyledErrorsMessageDiv>
          <StyledSpaceDiv />
        </>
      )}
      {success && (
        <>
          <StyledSuccessMessageDiv
            data-testname={"generate-info-success-message"}
          >
            Documents successfully generated. They will be emailed to you
            shortly.
          </StyledSuccessMessageDiv>
          <StyledSpaceDiv />
        </>
      )}
      {success === false && generateErrors.length === 0 && (
        <>
          <StyledErrorsMessageDiv
            data-testname={"generate-info-other-error-message"}
          >
            A server error has occurred. Please let Chad know that you saw this
            message so that it can be debugged.
          </StyledErrorsMessageDiv>
          <StyledSpaceDiv />
        </>
      )}
      {generateErrors.length > 0 && (
        <CommonCustomizableTable
          dataTestname="ewp-flameco-common-customizable-table"
          usageIdentifier="ewp"
          data={generateErrors}
          elements={elements}
          elementsInfo={elementsInfo}
          headers={headers}
          tableWidth={"100%"}
          tableMinWidth={"100%"}
          tableMaxHeight={"100%"}
          desktopColWidths={["20%", "10%", "20%", "50%"]}
          desktopColMinWidths={["20%", "10%", "20%", "50%"]}
          mobileColWidths={["10%", "10%", "20%", "60%"]}
          mobileColMinWidths={["110px", "50px", "100px", "160px"]}
          elementsOnClick={elementsOnClick}
          textAlign={["left", "left", "left", "left"]}
          tdHeight={"45px"}
          colDisplayOnMobile={colDisplayOnMobile}
          addPaddingRightForScroll={true}
          colJustify={[]}
          tdHeightMobile={"45px"}
          colFlexDirection={[]}
        />
      )}
    </StyledMainDiv>
  );
};

export default Generate;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledImportantMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.confirmationBoxButtonTextWeight};
`;

const StyledErrorsMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.confirmationBoxButtonTextWeight};
  color: ${(props) => props.theme.profireRed};
`;

const StyledSuccessMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.confirmationBoxButtonTextWeight};
  color: ${(props) => props.theme.successGreen};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledSaveButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledButtonContainerDiv = styled.div`
  margin-left: 10px;
`;
