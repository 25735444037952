import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IPilotBurner, IPilotValve } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";
import { GetEqualityIndex } from "../../../utilities/propertyAccess";
import CommonCustomizableTable from "../../common/tables/CommonCustomizableTable";
import ConfirmationBox from "../../common/modals/ConfirmationBox";
import AddPilotBurner from "./AddPilotBurner";
import EditPilotBurner from "./EditPilotBurner";

interface AddPilotValveProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IPilotValve | undefined;
  existingPilotValve: IPilotValve[];
}

const AddPilotValve: React.FC<AddPilotValveProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingPilotValve,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [tag, setTag] = useState<string>(
    retainedData === undefined ? "" : retainedData.pilotvalvetag!
  );
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    setTag(e.currentTarget.value);
    setTagErrors("");
  };

  const [isBleedValve, setIsBleedValve] = useState<boolean>(
    retainedData === undefined ? false : retainedData.is_bleed_valve!
  );
  const handleIsBleedValve = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setIsBleedValve(checked);
  };

  const [pilotBurners, setPilotBurners] = useState<IPilotBurner[]>([]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>([
    "pilotburnerelement",
    "pilotigniterelement",
    "",
    "",
  ]);

  const [overwriteHeaders] = useState<string[]>([
    "Burner Element",
    "Igniter Element",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditPilotBurner,
    showDeletePilotBurnerConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add PilotBurner
  const [showAddPilotBurner, setShowAddPilotBurner] = useState<boolean>(false);
  const [addPilotBurnerCBActive, setAddPilotBurnerCBActive] =
    useState<boolean>(false);
  const [addPilotBurnerCBText, setAddPilotBurnerCBText] = useState<string>("");
  const [retainedNewPilotBurner, setRetainedNewPilotBurner] =
    useState<IPilotBurner>();

  function handleShowAddPilotBurner() {
    if (showAddPilotBurner) {
      setRetainedNewPilotBurner(undefined);
    }
    setShowAddPilotBurner(!showAddPilotBurner);
  }

  //This function runs on clicking Save in Add PilotBurner
  function handleAddPilotBurner(e: IPilotBurner) {
    setShowAddPilotBurner(false);
    setAddPilotBurnerCBActive(true);

    pilotBurners.push(e);

    setAddPilotBurnerCBText(`${e.pilotburnerelement} successfully added`);
    setRetainedNewPilotBurner(undefined);
  }

  //This function runs on clicking OK after clicking Save in Add PilotBurner Info
  function completeAddPilotBurner() {
    setAddPilotBurnerCBActive(false);
    setAddPilotBurnerCBText("");

    if (retainedNewPilotBurner !== undefined) {
      setShowAddPilotBurner(true);
    }
  }

  //Edit PilotBurner
  const [showEditPilotBurner, setShowEditPilotBurner] =
    useState<boolean>(false);
  const [editPilotBurnerCBActive, setEditPilotBurnerCBActive] =
    useState<boolean>(false);
  const [editPilotBurnerCBText, setEditPilotBurnerCBText] =
    useState<string>("");
  const [editPilotBurnerCBFinalDisplay, setEditPilotBurnerCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditPilotBurner is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditPilotBurner, setRetainedEditPilotBurner] = useState<
    IPilotBurner | undefined
  >();
  const [chosenEditPilotBurner, setChosenEditPilotBurner] =
    useState<IPilotBurner>();

  function handleShowEditPilotBurner(e: any = undefined) {
    if (showEditPilotBurner) {
      setShowEditPilotBurner(false);
      setChosenEditPilotBurner(undefined);
    } else {
      setShowEditPilotBurner(!showEditPilotBurner);
      setChosenEditPilotBurner(e);
    }
  }

  //This function runs on clicking Save in Edit PilotBurner
  function showEditPilotBurnerConfirmationBox(e: IPilotBurner) {
    setShowEditPilotBurner(false);
    setEditPilotBurnerCBActive(true);
    setEditPilotBurnerCBText("Are you sure you want to save these changes?");
    setRetainedEditPilotBurner(e);
  }

  //This function runs on clicking Cancel in Edit PilotBurner
  function hideEditPilotBurnerConfirmationBox() {
    setShowEditPilotBurner(false);
    setEditPilotBurnerCBActive(false);
    setEditPilotBurnerCBText("");
    setRetainedEditPilotBurner(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit PilotBurner
  function handleEditPilotBurner() {
    setEditPilotBurnerCBText("");

    let pbUpdate: IPilotBurner[] = [...pilotBurners];
    pbUpdate.splice(
      GetEqualityIndex(pbUpdate, chosenEditPilotBurner),
      1,
      retainedEditPilotBurner!
    );
    setPilotBurners(pbUpdate);

    setEditPilotBurnerCBText(
      `${retainedEditPilotBurner!.pilotburnerelement} successfully updated`
    );
    setRetainedEditPilotBurner(undefined);

    setEditPilotBurnerCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit PilotBurner
  function completeEditPilotBurner() {
    setEditPilotBurnerCBActive(false);
    setEditPilotBurnerCBText("");
    setEditPilotBurnerCBFinalDisplay(false);
    setChosenEditPilotBurner(undefined);

    if (retainedEditPilotBurner !== undefined) {
      setShowEditPilotBurner(true);
    }
  }

  //Delete PilotBurner
  const [deletePilotBurnerCBActive, setDeletePilotBurnerCBActive] =
    useState<boolean>(false);
  const [deletePilotBurnerCBText, setDeletePilotBurnerCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deletePilotBurnerCBFinalDisplay, setDeletePilotBurnerCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeletePilotBurner, setChosenDeletePilotBurner] =
    useState<IPilotBurner>();

  //This function runs on clicking Save in Delete PilotBurner
  function showDeletePilotBurnerConfirmationBox(e: IPilotBurner) {
    setChosenDeletePilotBurner(e);
    setDeletePilotBurnerCBActive(true);
    setDeletePilotBurnerCBText(
      `Are you sure you want to delete ${e.pilotburnerelement}?`
    );
  }

  //This function runs on clicking Cancel in Delete PilotBurner
  function hideDeletePilotBurnerConfirmationBox(e: any) {
    setDeletePilotBurnerCBActive(false);
    setDeletePilotBurnerCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete PilotBurner
  function handleDeletePilotBurner() {
    setDeletePilotBurnerCBText("");

    let pbUpdate: IPilotBurner[] = [...pilotBurners];
    pbUpdate.splice(GetEqualityIndex(pbUpdate, chosenDeletePilotBurner), 1);
    setPilotBurners(pbUpdate);

    setDeletePilotBurnerCBText(
      `${chosenDeletePilotBurner!.pilotburnerelement} successfully deleted`
    );

    setDeletePilotBurnerCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete PilotBurner
  function completeDeletePilotBurner(e: any) {
    setDeletePilotBurnerCBActive(false);
    setDeletePilotBurnerCBText("");
    setDeletePilotBurnerCBFinalDisplay(false);
    setChosenDeletePilotBurner(undefined);
  }

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingPilotValve.find((valve) => valve.pilotvalvetag === tag) !==
      undefined
    ) {
      setTagErrors("Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newPilotValve: IPilotValve = {
        pilotvalvetag: tag,
        is_bleed_valve: isBleedValve,
        pilotburnertable: pilotBurners,
      };

      onAdd(newPilotValve);
    }
  }
  return (
    <ModalBox
      dataTestname="add-pilot-valve-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Pilot Valve"
      maxHeight={"70%"}
    >
      <StyledAddPilotValveDiv>
        <StyledMainDiv data-testid="main-div">
          <ConfirmationBox
            dataTestname="add-pilot-valve-add-pilot-burner-confirmation-box"
            heading={"Information"}
            message={addPilotBurnerCBText}
            active={addPilotBurnerCBActive}
            onOk={completeAddPilotBurner}
            displayCancel={false}
          />
          <ConfirmationBox
            dataTestname="add-pilot-valve-edit-pilot-burner-confirmation-box"
            heading={"Information"}
            message={editPilotBurnerCBText}
            active={editPilotBurnerCBActive}
            onOk={completeEditPilotBurner}
            onYes={handleEditPilotBurner}
            onCancel={hideEditPilotBurnerConfirmationBox}
            displayCancel={true}
            finalDisplay={editPilotBurnerCBFinalDisplay}
          />
          <ConfirmationBox
            dataTestname="add-pilot-valve-delete-pilot-burner-confirmation-box"
            heading={"Information"}
            message={deletePilotBurnerCBText}
            active={deletePilotBurnerCBActive}
            onOk={completeDeletePilotBurner}
            onYes={handleDeletePilotBurner}
            onCancel={hideDeletePilotBurnerConfirmationBox}
            displayCancel={true}
            finalDisplay={deletePilotBurnerCBFinalDisplay}
          />
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-pilot-valve-tag-common-input"
                type={"text"}
                labelText={"Pilot Valve Tag"}
                required={true}
                readOnly={false}
                value={tag}
                onChange={handleTag}
                maxLength={50}
                placeholder={""}
                errors={tagErrors}
                autoFocus={true}
              />
              <Checkbox
                dataTestname={"add-pilot-valve-is-bleed-valve-checkbox"}
                isChecked={isBleedValve === null ? false : isBleedValve}
                handleChange={handleIsBleedValve}
                label={"Is Bleed Valve?"}
                arrayLetter={"T"}
              />
              <CommonCustomizableTable
                dataTestname="add-pilot-valve-pilot-burners-table"
                usageIdentifier="appliance-details-add-pilot-valve"
                data={pilotBurners}
                elements={elements}
                elementsInfo={elementsInfo}
                headers={headers}
                overwriteHeaders={overwriteHeaders}
                tableWidth={"100%"}
                tableMinWidth={"100%"}
                tableMaxHeight={"300px"}
                desktopColWidths={[
                  "33.33% - 33px",
                  "66.66% - 33px",
                  "3.33%",
                  "3.33%",
                ]}
                desktopColMinWidths={[
                  "33.33% - 33px",
                  "66.66% - 33px",
                  "33px",
                  "33px",
                ]}
                mobileColWidths={[
                  "33.33% - 33px",
                  "66.66% - 33px",
                  "3.33%",
                  "3.33%",
                ]}
                mobileColMinWidths={[
                  "33.33% - 33px",
                  "66.66% - 33px",
                  "33px",
                  "33px",
                ]}
                elementsOnClick={elementsOnClick}
                textAlign={["left", "left", "left", "left"]}
                tdHeight={"45px"}
                colDisplayOnMobile={colDisplayOnMobile}
                addPaddingRightForScroll={true}
                colJustify={[]}
                tdHeightMobile={"45px"}
                colFlexDirection={[]}
              />
              <StyledSpaceDiv />
            </StyledSectionDiv>
            <StyledAddButtonDiv>
              <ProfireButton
                dataTestname={"add-pilot-valve-add-pilot-burner-button"}
                onClickFunction={handleShowAddPilotBurner}
                text={"Add Pilot Burner"}
                size={5}
              />
              {showAddPilotBurner && (
                <AddPilotBurner
                  active={showAddPilotBurner}
                  onCancel={handleShowAddPilotBurner}
                  onAdd={handleAddPilotBurner}
                  retainedData={retainedNewPilotBurner}
                  existingPilotBurner={pilotBurners}
                />
              )}
            </StyledAddButtonDiv>
            <StyledSpaceDiv />
            <StyledSpaceDiv />
            {showEditPilotBurner && (
              <EditPilotBurner
                active={showEditPilotBurner}
                pilotBurner={chosenEditPilotBurner!}
                onCancel={handleShowEditPilotBurner}
                onEdit={showEditPilotBurnerConfirmationBox}
                retainedData={retainedEditPilotBurner}
                existingPilotBurner={pilotBurners}
              />
            )}
            {existingErrors.current && (
              <StyledErrorMessage data-testid="submit-errors">
                {"Please fix above errors and resubmit."}
              </StyledErrorMessage>
            )}
            <StyledButtonRow>
              <StyledCancelButtonHolder>
                <ProfireButton
                  dataTestname="add-pilot-valve-cancel-button"
                  text="Cancel"
                  onClickFunction={handleCancel}
                />
              </StyledCancelButtonHolder>
              <StyledSaveButtonHolder>
                <ProfireButton
                  dataTestname="add-pilot-valve-save-button"
                  text="Save"
                  onClickFunction={ErrorHandler}
                  defaultButton={true}
                />
              </StyledSaveButtonHolder>
            </StyledButtonRow>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddPilotValveDiv>
    </ModalBox>
  );
};

export default AddPilotValve;

const StyledAddPilotValveDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
