import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IApplianceController,
  IEWPAutomationForm,
  ITCV,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import Dropdown from "../common/formInputs/Dropdown";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import EditTCV from "./adTCV/EditTCV";
import AddTCV from "./adTCV/AddTCV";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ADTCV: React.FC<SectionProps> = ({ onChange, form, save, saving }) => {
  const [applianceConfigTable, setApplianceConfigTable] = useState<
    IApplianceController[]
  >(CopyObjectNoPointer(form.applianceconfigtable));

  let appliances: [string, string][];
  appliances = [];

  applianceConfigTable.forEach((appliance, index) => {
    appliances.push([appliance.controllername, index.toString()]);
  });

  //Section states
  const [chosenAppliance, setChosenAppliance] = useState<string>(
    appliances.length > 0 ? appliances[0][1] : ""
  );

  function updateChosenAppliance(newAppliance: string) {
    setChosenAppliance(newAppliance);
  }

  useEffect(() => {
    setApplianceConfigTable(form.applianceconfigtable);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>(["tcvtag", "tcvpurgeposition", "", ""]);

  const [overwriteHeaders] = useState<string[]>([
    "Tag",
    "Purge Position",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditTCV,
    showDeleteTCVConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add TCV
  const [showAddTCV, setShowAddTCV] = useState<boolean>(false);
  const [addTCVCBActive, setAddTCVCBActive] = useState<boolean>(false);
  const [addTCVCBText, setAddTCVCBText] = useState<string>("");
  const [retainedNewTCV, setRetainedNewTCV] = useState<ITCV>();

  function handleShowAddTCV() {
    if (showAddTCV) {
      setRetainedNewTCV(undefined);
    }
    setShowAddTCV(!showAddTCV);
  }

  //This function runs on clicking Save in Add TCV
  function handleAddTCV(e: ITCV) {
    setShowAddTCV(false);
    setAddTCVCBActive(true);

    let pcUpdate: ITCV[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].tcvtable,
    ];
    pcUpdate.push(e);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].tcvtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setAddTCVCBText(`${e.tcvtag} successfully added`);
    setRetainedNewTCV(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddTCV() {
    setAddTCVCBActive(false);
    setAddTCVCBText("");

    if (retainedNewTCV !== undefined) {
      setShowAddTCV(true);
    }
  }

  //Edit TCV
  const [showEditTCV, setShowEditTCV] = useState<boolean>(false);
  const [editTCVCBActive, setEditTCVCBActive] = useState<boolean>(false);
  const [editTCVCBText, setEditTCVCBText] = useState<string>("");
  const [editTCVCBFinalDisplay, setEditTCVCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditTCV is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditTCV, setRetainedEditTCV] = useState<ITCV | undefined>();
  const [chosenEditTCV, setChosenEditTCV] = useState<ITCV>();

  function handleShowEditTCV(e: any = undefined) {
    if (showEditTCV) {
      setShowEditTCV(false);
      setChosenEditTCV(undefined);
    } else {
      setShowEditTCV(!showEditTCV);
      setChosenEditTCV(e);
    }
  }

  //This function runs on clicking Save in Edit TCV
  function showEditTCVConfirmationBox(e: ITCV) {
    setShowEditTCV(false);
    setEditTCVCBActive(true);
    setEditTCVCBText("Are you sure you want to save these changes?");
    setRetainedEditTCV(e);
  }

  //This function runs on clicking Cancel in Edit TCV
  function hideEditTCVConfirmationBox() {
    setShowEditTCV(false);
    setEditTCVCBActive(false);
    setEditTCVCBText("");
    setRetainedEditTCV(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit TCV
  function handleEditTCV() {
    setEditTCVCBText("");

    let pcUpdate: ITCV[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].tcvtable,
    ];
    pcUpdate.splice(
      GetEqualityIndex(pcUpdate, chosenEditTCV),
      1,
      retainedEditTCV!
    );
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].tcvtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setEditTCVCBText(`${retainedEditTCV!.tcvtag} successfully updated`);
    setRetainedEditTCV(undefined);
    save();

    setEditTCVCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit TCV
  function completeEditTCV() {
    setEditTCVCBActive(false);
    setEditTCVCBText("");
    setEditTCVCBFinalDisplay(false);
    setChosenEditTCV(undefined);

    if (retainedEditTCV !== undefined) {
      setShowEditTCV(true);
    }
  }

  //Delete TCV
  const [deleteTCVCBActive, setDeleteTCVCBActive] = useState<boolean>(false);
  const [deleteTCVCBText, setDeleteTCVCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteTCVCBFinalDisplay, setDeleteTCVCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteTCV, setChosenDeleteTCV] = useState<ITCV>();

  //This function runs on clicking Save in Delete TCV
  function showDeleteTCVConfirmationBox(e: ITCV) {
    setChosenDeleteTCV(e);
    setDeleteTCVCBActive(true);
    setDeleteTCVCBText(`Are you sure you want to delete ${e.tcvtag}?`);
  }

  //This function runs on clicking Cancel in Delete TCV
  function hideDeleteTCVConfirmationBox(e: any) {
    setDeleteTCVCBActive(false);
    setDeleteTCVCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete TCV
  function handleDeleteTCV() {
    setDeleteTCVCBText("");

    let pcUpdate: ITCV[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].tcvtable,
    ];
    pcUpdate.splice(GetEqualityIndex(pcUpdate, chosenDeleteTCV), 1);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].tcvtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setDeleteTCVCBText(`${chosenDeleteTCV!.tcvtag} successfully deleted`);
    save();

    setDeleteTCVCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete TCV
  function completeDeleteTCV(e: any) {
    setDeleteTCVCBActive(false);
    setDeleteTCVCBText("");
    setDeleteTCVCBFinalDisplay(false);
    setChosenDeleteTCV(undefined);
  }

  return appliances.length === 0 ? (
    <StyledMessageDiv data-testname={"add-controller-error-message"}>
      Please add a controller in "Appliance Details" to access the Appliance
      Details sub-pages
    </StyledMessageDiv>
  ) : (
    <StyledMainDiv>
      <Dropdown
        dataTestname={"appliance-details-tcv-appliance-dropdown"}
        columns={appliances}
        labelText={"Appliance"}
        onchange={updateChosenAppliance}
        selected={chosenAppliance}
      />
      <ConfirmationBox
        dataTestname="appliance-details-tcv-add-tcv-confirmation-box"
        heading={"Information"}
        message={addTCVCBText}
        active={addTCVCBActive}
        onOk={completeAddTCV}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-tcv-edit-tcv-confirmation-box"
        heading={"Information"}
        message={editTCVCBText}
        active={editTCVCBActive}
        onOk={completeEditTCV}
        onYes={handleEditTCV}
        onCancel={hideEditTCVConfirmationBox}
        displayCancel={true}
        finalDisplay={editTCVCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-tcv-delete-tcv-confirmation-box"
        heading={"Information"}
        message={deleteTCVCBText}
        active={deleteTCVCBActive}
        onOk={completeDeleteTCV}
        onYes={handleDeleteTCV}
        onCancel={hideDeleteTCVConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteTCVCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-tcv-common-customizable-table"
        usageIdentifier="appliance-details-pilot-card"
        data={applianceConfigTable[parseInt(chosenAppliance)].tcvtable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-tcv-add-button"}
          onClickFunction={handleShowAddTCV}
          text={"Add TCV"}
          size={4}
        />
        {showAddTCV && (
          <AddTCV
            active={showAddTCV}
            onCancel={handleShowAddTCV}
            onAdd={handleAddTCV}
            retainedData={retainedNewTCV}
            existingTCV={
              applianceConfigTable[parseInt(chosenAppliance)].tcvtable
            }
          />
        )}
      </StyledAddButtonDiv>
      {showEditTCV && (
        <EditTCV
          active={showEditTCV}
          tcvInfo={chosenEditTCV!}
          onCancel={handleShowEditTCV}
          onEdit={showEditTCVConfirmationBox}
          retainedData={retainedEditTCV}
          existingTCVInfo={
            applianceConfigTable[parseInt(chosenAppliance)].tcvtable
          }
        />
      )}
    </StyledMainDiv>
  );
};

export default ADTCV;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
