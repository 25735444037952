import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IPilotCard } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";
import CommonTextArea from "../../common/formInputs/CommonTextArea";

interface AddPilotCardProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IPilotCard | undefined;
  existingPilotCard: IPilotCard[];
}

const AddPilotCard: React.FC<AddPilotCardProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingPilotCard,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [name, setName] = useState<string>(
    retainedData === undefined ? "" : retainedData.pilotcardname!
  );
  const [nameErrors, setNameErrors] = useState<string>("");
  const handleName = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setNameErrors("");
  };

  const [uv, setUV] = useState<boolean>(
    retainedData === undefined ? false : retainedData.is_uv_pilot_card!
  );
  const handleUV = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setUV(checked);
  };

  const [comments, setComments] = useState<string>(
    retainedData === undefined ? "" : retainedData.pilotcardcomments!
  );
  const [commentsErrors, setCommentsErrors] = useState<string>("");
  const handleComments = (e: React.FormEvent<HTMLInputElement>) => {
    setComments(e.currentTarget.value);
    setCommentsErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setNameErrors("");
    setCommentsErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!name) {
      setNameErrors("Name is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingPilotCard.find((card) => card.pilotcardname === name) !==
      undefined
    ) {
      setNameErrors("Name is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newPilotCard: IPilotCard = {
        pilotcardname: name,
        is_uv_pilot_card: uv,
        pilotcardcomments: comments,
      };

      onAdd(newPilotCard);
    }
  }
  return (
    <ModalBox
      dataTestname="add-pilot-card-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Pilot Card"
      maxHeight={"50%"}
    >
      <StyledAddPilotCardDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-pilot-card-pilot-card-name-common-input"
                type={"text"}
                labelText={"Pilot Card Name"}
                required={true}
                readOnly={false}
                value={name}
                onChange={handleName}
                maxLength={50}
                placeholder={""}
                errors={nameErrors}
                autoFocus={true}
              />
              <Checkbox
                dataTestname={"add-pilot-card-pilot-card-uv-checkbox"}
                isChecked={uv === null ? false : uv}
                handleChange={handleUV}
                label={"Is UV Pilot Card?"}
                arrayLetter={"A"}
              />
              <CommonTextArea
                dataTestname={
                  "add-pilot-card-pilot-card-comments-common-text-area"
                }
                labelText="Pilot Card Comments"
                value={comments === null ? "" : comments}
                onChange={handleComments}
                errors={commentsErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-pilot-card-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-pilot-card-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddPilotCardDiv>
    </ModalBox>
  );
};

export default AddPilotCard;

const StyledAddPilotCardDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
