import styled from "styled-components";
import OpenIcon from "../../../assets/images/clickables/open-button.svg";

interface OpenButtonProps {
  dataTestname: string;
  onClickFunction: React.MouseEventHandler<HTMLDivElement>;
}

const OpenButton: React.FC<OpenButtonProps> = ({
  dataTestname,
  onClickFunction,
}) => {
  return (
    <StyledOpenButtonDiv
      data-testname={dataTestname}
      onClick={onClickFunction}
      data-testid="open-button"
    >
      <img src={OpenIcon} alt="open button" />
    </StyledOpenButtonDiv>
  );
};

export default OpenButton;

const StyledOpenButtonDiv = styled.div`
  width: 20px;
  height: 20px;
  margin-left: 13px;
  position: relative;

  :hover {
    cursor: pointer;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    :before {
      content: "Open";
      visibility: hidden;
      opacity: 0;
      width: 60px;
      background-color: #aaa;
      color: #fff;
      text-align: center;
      border-radius: 3px;
      padding: 2px;
      transition: opacity 0.5s ease-in-out;

      position: absolute;
      z-index: 111;
      left: -45px;
      top: -28px;
    }

    :hover:before {
      opacity: 1;
      visibility: visible;
    }
  }
`;
