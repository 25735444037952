import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm } from "../../types/tools";
import CommonRadioButtonGroup from "../common/formInputs/ProfireRadioButtonGroup";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
}

const ProcessControlDetails: React.FC<SectionProps> = ({ onChange, form }) => {
  const [pilotOffMode, setPilotOffMode] = useState<string | null>(
    form.pilot_off_mode_setting_set_to_disabled
      ? "Set to Disabled"
      : form.pilot_off_mode_setting_set_to_off_at_setpoint
      ? "Set to Off at Setpoint"
      : form.pilot_off_mode_setting_set_to_off_after_main
      ? "Set to Off after Main"
      : form.pilot_off_mode_setting_set_to_follow_main
      ? "Set to Follow Main"
      : ""
  );
  const [pilotOffModeErrors, setPilotOffModeErrors] = useState<string>("");
  const handlePilotOffMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPilotOffMode(value);
    setPilotOffModeErrors("");

    if (value === "Set to Disabled") {
      onChange(true, "pilot_off_mode_setting_set_to_disabled");
      onChange(false, "pilot_off_mode_setting_set_to_off_at_setpoint");
      onChange(false, "pilot_off_mode_setting_set_to_off_after_main");
      onChange(false, "pilot_off_mode_setting_set_to_follow_main");
    } else if (value === "Set to Off at Setpoint") {
      onChange(false, "pilot_off_mode_setting_set_to_disabled");
      onChange(true, "pilot_off_mode_setting_set_to_off_at_setpoint");
      onChange(false, "pilot_off_mode_setting_set_to_off_after_main");
      onChange(false, "pilot_off_mode_setting_set_to_follow_main");
    } else if (value === "Set to Off after Main") {
      onChange(false, "pilot_off_mode_setting_set_to_disabled");
      onChange(false, "pilot_off_mode_setting_set_to_off_at_setpoint");
      onChange(true, "pilot_off_mode_setting_set_to_off_after_main");
      onChange(false, "pilot_off_mode_setting_set_to_follow_main");
    } else if (value === "Set to Follow Main") {
      onChange(false, "pilot_off_mode_setting_set_to_disabled");
      onChange(false, "pilot_off_mode_setting_set_to_off_at_setpoint");
      onChange(false, "pilot_off_mode_setting_set_to_off_after_main");
      onChange(true, "pilot_off_mode_setting_set_to_follow_main");
    } else {
      onChange(false, "pilot_off_mode_setting_set_to_disabled");
      onChange(false, "pilot_off_mode_setting_set_to_off_at_setpoint");
      onChange(false, "pilot_off_mode_setting_set_to_off_after_main");
      onChange(false, "pilot_off_mode_setting_set_to_follow_main");
    }
  };

  const [lowFireMode, setLowFireMode] = useState<string | null>(
    form.low_fire_mode_setting_set_to_disabled
      ? "Set to Disabled"
      : form.low_fire_mode_setting_set_to_on_at_process_sp
      ? "Set to On at Process SP"
      : form.low_fire_mode_setting_set_to_on_at_low_fire_sp
      ? "Set to On at Low Fire SP"
      : ""
  );
  const [lowFireModeErrors, setLowFireModeErrors] = useState<string>("");
  const handleLowFireMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLowFireMode(value);
    setLowFireModeErrors("");

    if (value === "Set to Disabled") {
      onChange(true, "low_fire_mode_setting_set_to_disabled");
      onChange(false, "low_fire_mode_setting_set_to_on_at_process_sp");
      onChange(false, "low_fire_mode_setting_set_to_on_at_low_fire_sp");
    } else if (value === "Set to On at Process SP") {
      onChange(false, "low_fire_mode_setting_set_to_disabled");
      onChange(true, "low_fire_mode_setting_set_to_on_at_process_sp");
      onChange(false, "low_fire_mode_setting_set_to_on_at_low_fire_sp");
    } else if (value === "Set to On at Low Fire SP") {
      onChange(false, "low_fire_mode_setting_set_to_disabled");
      onChange(false, "low_fire_mode_setting_set_to_on_at_process_sp");
      onChange(true, "low_fire_mode_setting_set_to_on_at_low_fire_sp");
    } else {
      onChange(false, "low_fire_mode_setting_set_to_disabled");
      onChange(false, "low_fire_mode_setting_set_to_on_at_process_sp");
      onChange(false, "low_fire_mode_setting_set_to_on_at_low_fire_sp");
    }
  };

  return (
    <StyledMainDiv>
      <CommonRadioButtonGroup
        dataTestname={"process-control-details-pilot-radio-button-group"}
        legendText="Please Select Pilot Off Mode Setting"
        options={[
          "Set to Disabled",
          "Set to Off at Setpoint",
          "Set to Off after Main",
          "Set to Follow Main",
        ]}
        onChange={handlePilotOffMode}
        identifier={"P"}
        selected={pilotOffMode === null ? "" : pilotOffMode}
        errors={pilotOffModeErrors}
      />
      <CommonRadioButtonGroup
        dataTestname={"process-control-details-low-fire-radio-button-group"}
        legendText="Please Select Low Fire Mode Setting"
        options={[
          "Set to Disabled",
          "Set to On at Process SP",
          "Set to On at Low Fire SP",
        ]}
        onChange={handleLowFireMode}
        identifier={"L"}
        selected={lowFireMode === null ? "" : lowFireMode}
        errors={lowFireModeErrors}
      />
    </StyledMainDiv>
  );
};

export default ProcessControlDetails;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
