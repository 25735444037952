import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { ITemperatureConfiguration } from "../../../types/tools";
import Dropdown from "../../common/formInputs/Dropdown";
import {
  tempApplianceShutdownDropdown,
  tempControlModeDropdown,
  tempTypeDropdown,
} from "../../../utilities/staticObjects";

interface EditTemperatureConfigurationProps {
  active: boolean;
  temperatureConfiguration: ITemperatureConfiguration;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: ITemperatureConfiguration | undefined;
  existingTemperatureConfiguration: ITemperatureConfiguration[];
}

const EditTemperatureConfiguration: React.FC<
  EditTemperatureConfigurationProps
> = ({
  active,
  temperatureConfiguration,
  onCancel,
  onEdit,
  retainedData,
  existingTemperatureConfiguration,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [temperatureTag, setTemperatureTag] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.temptag === undefined
        ? ""
        : temperatureConfiguration.temptag!
      : retainedData.temptag!
  );
  const [temperatureTagErrors, setTemperatureTagErrors] = useState<string>("");
  const handleTemperatureTag = (e: React.FormEvent<HTMLInputElement>) => {
    setTemperatureTag(e.currentTarget.value);
    setTemperatureTagErrors("");
  };

  const [temperatureDescription, setTemperatureDescription] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempdesc === undefined
        ? ""
        : temperatureConfiguration.tempdesc!
      : retainedData.tempdesc!
  );
  const [temperatureDescriptionErrors, setTemperatureDescriptionErrors] =
    useState<string>("");
  const handleTemperatureDescription = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setTemperatureDescription(e.currentTarget.value);
    setTemperatureDescriptionErrors("");
  };

  const [temperatureType, setTemperatureType] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.temptype === undefined
        ? tempTypeDropdown[0][1]
        : temperatureConfiguration.temptype!
      : retainedData.temptype!
  );
  const handleTemperatureType = (newChosen: string) => {
    setTemperatureType(newChosen);
  };

  const [temperatureIOType, setTemperatureIOType] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempiotype === undefined
        ? ""
        : temperatureConfiguration.tempiotype!
      : retainedData.tempiotype!
  );
  const [temperatureIOTypeErrors, setTemperatureIOTypeErrors] =
    useState<string>("");
  const handleTemperatureIOType = (e: React.FormEvent<HTMLInputElement>) => {
    setTemperatureIOType(e.currentTarget.value);
    setTemperatureIOTypeErrors("");
  };

  const [temperatureControlMode, setTemperatureControlMode] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempcontrolmode === undefined
        ? tempControlModeDropdown[0][1]
        : temperatureConfiguration.tempcontrolmode!
      : retainedData.tempcontrolmode!
  );
  const handleTemperatureControlMode = (newChosen: string) => {
    setTemperatureControlMode(newChosen);
  };

  const [temperatureApplianceShutdown, setTemperatureApplianceShutdown] =
    useState<string>(
      retainedData === undefined
        ? temperatureConfiguration.tempapplianceshutdown === undefined
          ? tempApplianceShutdownDropdown[0][1]
          : temperatureConfiguration.tempapplianceshutdown!
        : retainedData.tempapplianceshutdown!
    );
  const handleTemperatureApplianceShutdown = (newChosen: string) => {
    setTemperatureApplianceShutdown(newChosen);
  };

  const [temperatureESDSP, setTemperatureESDSP] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempesdsp === undefined
        ? ""
        : temperatureConfiguration.tempesdsp!
      : retainedData.tempesdsp!
  );
  const [temperatureESDSPErrors, setTemperatureESDSPErrors] =
    useState<string>("");
  const handleTemperatureESDSP = (e: React.FormEvent<HTMLInputElement>) => {
    setTemperatureESDSP(e.currentTarget.value);
    setTemperatureESDSPErrors("");
  };

  const [temperatureMainOffSP, setTemperatureMainOffSP] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempmainoffsp === undefined
        ? ""
        : temperatureConfiguration.tempmainoffsp!
      : retainedData.tempmainoffsp!
  );
  const [temperatureMainOffSPErrors, setTemperatureMainOffSPErrors] =
    useState<string>("");
  const handleTemperatureMainOffSP = (e: React.FormEvent<HTMLInputElement>) => {
    setTemperatureMainOffSP(e.currentTarget.value);
    setTemperatureMainOffSPErrors("");
  };

  const [temperaturePilotOffSP, setTemperaturePilotOffSP] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.temppilotoffsp === undefined
        ? ""
        : temperatureConfiguration.temppilotoffsp!
      : retainedData.temppilotoffsp!
  );
  const [temperaturePilotOffSPErrors, setTemperaturePilotOffSPErrors] =
    useState<string>("");
  const handleTemperaturePilotOffSP = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setTemperaturePilotOffSP(e.currentTarget.value);
    setTemperaturePilotOffSPErrors("");
  };

  const [temperatureProcessSP, setTemperatureProcessSP] = useState<string>(
    retainedData === undefined
      ? temperatureConfiguration.tempprocesssp === undefined
        ? ""
        : temperatureConfiguration.tempprocesssp!
      : retainedData.tempprocesssp!
  );
  const [temperatureProcessSPErrors, setTemperatureProcessSPErrors] =
    useState<string>("");
  const handleTemperatureProcessSP = (e: React.FormEvent<HTMLInputElement>) => {
    setTemperatureProcessSP(e.currentTarget.value);
    setTemperatureProcessSPErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTemperatureTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!temperatureTag) {
      setTemperatureTagErrors("Temperature Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      temperatureConfiguration.temptag !== temperatureTag &&
      existingTemperatureConfiguration.find(
        (card) => card.temptag === temperatureTag
      ) !== undefined
    ) {
      setTemperatureTagErrors("Temperature Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const updatedTemperatureConfiguration: ITemperatureConfiguration = {
        temptag: temperatureTag,
        tempdesc: temperatureDescription,
        temptype: temperatureType,
        tempiotype: temperatureIOType,
        tempcontrolmode: temperatureControlMode,
        tempapplianceshutdown: temperatureApplianceShutdown,
        tempesdsp: temperatureESDSP,
        tempmainoffsp: temperatureMainOffSP,
        temppilotoffsp: temperaturePilotOffSP,
        tempprocesssp: temperatureProcessSP,
      };

      onEdit(updatedTemperatureConfiguration);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-temperature-configuration-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Temperature Configuration"
      maxHeight={"50%"}
    >
      <StyledEditTemperatureConfigurationDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-tag-common-input"
                type={"text"}
                labelText={"Temp Tag"}
                required={true}
                readOnly={false}
                value={temperatureTag}
                onChange={handleTemperatureTag}
                maxLength={50}
                placeholder={""}
                errors={temperatureTagErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-description-common-input"
                type={"text"}
                labelText={"Temp Description"}
                required={false}
                readOnly={false}
                value={temperatureDescription}
                onChange={handleTemperatureDescription}
                maxLength={50}
                placeholder={""}
                errors={temperatureDescriptionErrors}
                autoFocus={false}
              />
              <Dropdown
                dataTestname={
                  "edit-temperature-configuration-temp-type-dropdown"
                }
                columns={tempTypeDropdown}
                labelText={"Temp Type"}
                onchange={handleTemperatureType}
                selected={temperatureType}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-io-type-common-input"
                type={"text"}
                labelText={"Temp IO Type"}
                required={false}
                readOnly={false}
                value={temperatureIOType}
                onChange={handleTemperatureIOType}
                maxLength={50}
                placeholder={""}
                errors={temperatureIOTypeErrors}
                autoFocus={false}
              />
              <Dropdown
                dataTestname={
                  "edit-temperature-configuration-temp-control-mode-dropdown"
                }
                columns={tempControlModeDropdown}
                labelText={"Temp Control Mode"}
                onchange={handleTemperatureControlMode}
                selected={temperatureControlMode}
              />
              <Dropdown
                dataTestname={
                  "add-temperature-configuration-temp-appliance-shutdown-dropdown"
                }
                columns={tempApplianceShutdownDropdown}
                labelText={"Temp Appliance Shutdown"}
                onchange={handleTemperatureApplianceShutdown}
                selected={temperatureApplianceShutdown}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-esd-sp-common-input"
                type={"text"}
                labelText={"Temp ESD SP"}
                required={false}
                readOnly={false}
                value={temperatureESDSP}
                onChange={handleTemperatureESDSP}
                maxLength={50}
                placeholder={""}
                errors={temperatureESDSPErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-main-off-sp-common-input"
                type={"text"}
                labelText={"Temp Main Off SP"}
                required={false}
                readOnly={false}
                value={temperatureMainOffSP}
                onChange={handleTemperatureMainOffSP}
                maxLength={50}
                placeholder={""}
                errors={temperatureMainOffSPErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-pilot-off-sp-common-input"
                type={"text"}
                labelText={"Temp Pilot Off SP"}
                required={false}
                readOnly={false}
                value={temperaturePilotOffSP}
                onChange={handleTemperaturePilotOffSP}
                maxLength={50}
                placeholder={""}
                errors={temperaturePilotOffSPErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-temperature-configuration-temp-process-sp-common-input"
                type={"text"}
                labelText={"Temp Process SP"}
                required={false}
                readOnly={false}
                value={temperatureProcessSP}
                onChange={handleTemperatureProcessSP}
                maxLength={50}
                placeholder={""}
                errors={temperatureProcessSPErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-temperature-configuration-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-temperature-configuration-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditTemperatureConfigurationDiv>
    </ModalBox>
  );
};

export default EditTemperatureConfiguration;

const StyledEditTemperatureConfigurationDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
