import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IEWPFormListDetails } from "../../../types/tools";

interface AddFormProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: string | undefined;
  existingForms: IEWPFormListDetails[];
}

const AddForm: React.FC<AddFormProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingForms,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [projectNumber, setProjectNumber] = useState<string>(
    retainedData === undefined ? "" : retainedData!
  );
  const [projectNumberErrors, setProjectNumberErrors] = useState<string>("");
  const handleProjectNumber = (e: React.FormEvent<HTMLInputElement>) => {
    setProjectNumber(e.currentTarget.value);
    setProjectNumberErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setProjectNumberErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!projectNumber) {
      setProjectNumberErrors("Project Number is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingForms.find((form) => form.title === projectNumber) !== undefined
    ) {
      setProjectNumberErrors("Project Number already exists.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      onAdd(projectNumber);
    }
  }
  return (
    <ModalBox
      dataTestname="add-form-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Form"
      maxHeight={"50%"}
    >
      <StyledAddFormDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-form-project-number-common-input"
                type={"text"}
                labelText={"Project Number"}
                required={true}
                readOnly={false}
                value={projectNumber}
                onChange={handleProjectNumber}
                maxLength={50}
                placeholder={""}
                errors={projectNumberErrors}
                autoFocus={true}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-form-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-form-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddFormDiv>
    </ModalBox>
  );
};

export default AddForm;

const StyledAddFormDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
