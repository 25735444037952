export const handleDateDisplay = (date: Date) => {
  if (
    date === new Date() ||
    date === null ||
    date === undefined ||
    date.toString() === "Invalid Date"
  ) {
    return "N/A";
  }
  let stringToReturn: string = "";

  const months: [string, string][] = [
    ["January", "1"],
    ["February", "2"],
    ["March", "3"],
    ["April", "4"],
    ["May", "5"],
    ["June", "6"],
    ["July", "7"],
    ["August", "8"],
    ["September", "9"],
    ["October", "10"],
    ["November", "11"],
    ["December", "12"],
  ];

  stringToReturn += months[date.getUTCMonth()][0];
  stringToReturn += " " + date.getUTCDate();
  stringToReturn += ", " + date.getUTCFullYear();

  return stringToReturn;
};

export const dateToStringNoTime = (date: Date | string | null | undefined) => {
  var stringToReturn: string = "";
  if (date === null || date === undefined) {
    return stringToReturn;
  } else {
    stringToReturn = new Date(date).toISOString().split("T")[0]!;

    return stringToReturn;
  }
};

export const handleTimestampDisplay = (date: Date) => {
  if (
    date === new Date() ||
    date === null ||
    date === undefined ||
    date.toString() === "Invalid Date"
  ) {
    return "N/A";
  }
  let stringToReturn: string = "";

  stringToReturn += date.getFullYear();
  stringToReturn +=
    date.getMonth() < 9 ? `-0${(date.getMonth()+1)}` : "-" + (date.getMonth()+1);
  stringToReturn +=
    date.getDate() < 10 ? `-0${date.getDate()}` : "-" + date.getDate();
  stringToReturn +=
    date.getHours() < 10 ? ` 0${date.getHours()}` : " " + date.getHours();
  stringToReturn +=
    date.getMinutes() < 10 ? `:0${date.getMinutes()}` : ":" + date.getMinutes();
  stringToReturn +=
    date.getSeconds() < 10 ? `:0${date.getSeconds()}` : ":" + date.getSeconds();

  return stringToReturn;
};

export const handleDurationDisplay = (timespan: string) => {
  if (timespan === null) {
    return "N/A";
  }

  let stringToReturn: string = "";

  let dotSplit = timespan.split(".");

  if (dotSplit.length === 3) {
    stringToReturn += `${dotSplit[0]} Days `;

    let colonSplit = dotSplit[1].split(":");

    stringToReturn += `${colonSplit[0]} hrs `;
    stringToReturn += `${colonSplit[1]} mins `;
  } else {
    let colonSplit = dotSplit[0].split(":");

    stringToReturn += `${colonSplit[0]} hrs `;
    stringToReturn += `${colonSplit[1]} mins `;
  }

  return stringToReturn;
};
