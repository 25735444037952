import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IApplianceController,
  IEWPAutomationForm,
  IHighFireValve,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import Dropdown from "../common/formInputs/Dropdown";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import EditHighFireValve from "./adHighFireValve/EditHighFireValve";
import AddHighFireValve from "./adHighFireValve/AddHighFireValve";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ADHighFireValve: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [applianceConfigTable, setApplianceConfigTable] = useState<
    IApplianceController[]
  >(CopyObjectNoPointer(form.applianceconfigtable));

  let appliances: [string, string][];
  appliances = [];

  applianceConfigTable.forEach((appliance, index) => {
    appliances.push([appliance.controllername, index.toString()]);
  });

  //Section states
  const [chosenAppliance, setChosenAppliance] = useState<string>(
    appliances.length > 0 ? appliances[0][1] : ""
  );

  function updateChosenAppliance(newAppliance: string) {
    setChosenAppliance(newAppliance);
  }

  useEffect(() => {
    setApplianceConfigTable(form.applianceconfigtable);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", ""]);

  const [headers] = useState<string[]>(["hfvvalvetag", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Tag", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    handleShowEditHighFireValve,
    showDeleteHighFireValveConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true]);

  //Add HighFireValve
  const [showAddHighFireValve, setShowAddHighFireValve] =
    useState<boolean>(false);
  const [addHighFireValveCBActive, setAddHighFireValveCBActive] =
    useState<boolean>(false);
  const [addHighFireValveCBText, setAddHighFireValveCBText] =
    useState<string>("");
  const [retainedNewHighFireValve, setRetainedNewHighFireValve] =
    useState<IHighFireValve>();

  function handleShowAddHighFireValve() {
    if (showAddHighFireValve) {
      setRetainedNewHighFireValve(undefined);
    }
    setShowAddHighFireValve(!showAddHighFireValve);
  }

  //This function runs on clicking Save in Add HighFireValve
  function handleAddHighFireValve(e: IHighFireValve) {
    setShowAddHighFireValve(false);
    setAddHighFireValveCBActive(true);

    let pcUpdate: IHighFireValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable,
    ];
    pcUpdate.push(e);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].highfirevalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setAddHighFireValveCBText(`${e.hfvvalvetag} successfully added`);
    setRetainedNewHighFireValve(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddHighFireValve() {
    setAddHighFireValveCBActive(false);
    setAddHighFireValveCBText("");

    if (retainedNewHighFireValve !== undefined) {
      setShowAddHighFireValve(true);
    }
  }

  //Edit HighFireValve
  const [showEditHighFireValve, setShowEditHighFireValve] =
    useState<boolean>(false);
  const [editHighFireValveCBActive, setEditHighFireValveCBActive] =
    useState<boolean>(false);
  const [editHighFireValveCBText, setEditHighFireValveCBText] =
    useState<string>("");
  const [editHighFireValveCBFinalDisplay, setEditHighFireValveCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditHighFireValve is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditHighFireValve, setRetainedEditHighFireValve] = useState<
    IHighFireValve | undefined
  >();
  const [chosenEditHighFireValve, setChosenEditHighFireValve] =
    useState<IHighFireValve>();

  function handleShowEditHighFireValve(e: any = undefined) {
    if (showEditHighFireValve) {
      setShowEditHighFireValve(false);
      setChosenEditHighFireValve(undefined);
    } else {
      setShowEditHighFireValve(!showEditHighFireValve);
      setChosenEditHighFireValve(e);
    }
  }

  //This function runs on clicking Save in Edit HighFireValve
  function showEditHighFireValveConfirmationBox(e: IHighFireValve) {
    setShowEditHighFireValve(false);
    setEditHighFireValveCBActive(true);
    setEditHighFireValveCBText("Are you sure you want to save these changes?");
    setRetainedEditHighFireValve(e);
  }

  //This function runs on clicking Cancel in Edit HighFireValve
  function hideEditHighFireValveConfirmationBox() {
    setShowEditHighFireValve(false);
    setEditHighFireValveCBActive(false);
    setEditHighFireValveCBText("");
    setRetainedEditHighFireValve(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit HighFireValve
  function handleEditHighFireValve() {
    setEditHighFireValveCBText("");

    let pcUpdate: IHighFireValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable,
    ];
    pcUpdate.splice(
      GetEqualityIndex(pcUpdate, chosenEditHighFireValve),
      1,
      retainedEditHighFireValve!
    );
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].highfirevalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setEditHighFireValveCBText(
      `${retainedEditHighFireValve!.hfvvalvetag} successfully updated`
    );
    setRetainedEditHighFireValve(undefined);
    save();

    setEditHighFireValveCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit HighFireValve
  function completeEditHighFireValve() {
    setEditHighFireValveCBActive(false);
    setEditHighFireValveCBText("");
    setEditHighFireValveCBFinalDisplay(false);
    setChosenEditHighFireValve(undefined);

    if (retainedEditHighFireValve !== undefined) {
      setShowEditHighFireValve(true);
    }
  }

  //Delete HighFireValve
  const [deleteHighFireValveCBActive, setDeleteHighFireValveCBActive] =
    useState<boolean>(false);
  const [deleteHighFireValveCBText, setDeleteHighFireValveCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteHighFireValveCBFinalDisplay,
    setDeleteHighFireValveCBFinalDisplay,
  ] = useState<boolean>(false);
  const [chosenDeleteHighFireValve, setChosenDeleteHighFireValve] =
    useState<IHighFireValve>();

  //This function runs on clicking Save in Delete HighFireValve
  function showDeleteHighFireValveConfirmationBox(e: IHighFireValve) {
    setChosenDeleteHighFireValve(e);
    setDeleteHighFireValveCBActive(true);
    setDeleteHighFireValveCBText(
      `Are you sure you want to delete ${e.hfvvalvetag}?`
    );
  }

  //This function runs on clicking Cancel in Delete HighFireValve
  function hideDeleteHighFireValveConfirmationBox(e: any) {
    setDeleteHighFireValveCBActive(false);
    setDeleteHighFireValveCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete HighFireValve
  function handleDeleteHighFireValve() {
    setDeleteHighFireValveCBText("");

    let pcUpdate: IHighFireValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable,
    ];
    pcUpdate.splice(GetEqualityIndex(pcUpdate, chosenDeleteHighFireValve), 1);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].highfirevalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setDeleteHighFireValveCBText(
      `${chosenDeleteHighFireValve!.hfvvalvetag} successfully deleted`
    );
    save();

    setDeleteHighFireValveCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete HighFireValve
  function completeDeleteHighFireValve(e: any) {
    setDeleteHighFireValveCBActive(false);
    setDeleteHighFireValveCBText("");
    setDeleteHighFireValveCBFinalDisplay(false);
    setChosenDeleteHighFireValve(undefined);
  }

  return appliances.length === 0 ? (
    <StyledMessageDiv data-testname={"add-controller-error-message"}>
      Please add a controller in "Appliance Details" to access the Appliance
      Details sub-pages
    </StyledMessageDiv>
  ) : (
    <StyledMainDiv>
      <Dropdown
        dataTestname={"appliance-details-high-fire-valve-appliance-dropdown"}
        columns={appliances}
        labelText={"Appliance"}
        onchange={updateChosenAppliance}
        selected={chosenAppliance}
      />
      <ConfirmationBox
        dataTestname="appliance-details-high-fire-valve-add-high-fire-valve-confirmation-box"
        heading={"Information"}
        message={addHighFireValveCBText}
        active={addHighFireValveCBActive}
        onOk={completeAddHighFireValve}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-high-fire-valve-edit-high-fire-valve-confirmation-box"
        heading={"Information"}
        message={editHighFireValveCBText}
        active={editHighFireValveCBActive}
        onOk={completeEditHighFireValve}
        onYes={handleEditHighFireValve}
        onCancel={hideEditHighFireValveConfirmationBox}
        displayCancel={true}
        finalDisplay={editHighFireValveCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-high-fire-valve-delete-high-fire-valve-confirmation-box"
        heading={"Information"}
        message={deleteHighFireValveCBText}
        active={deleteHighFireValveCBActive}
        onOk={completeDeleteHighFireValve}
        onYes={handleDeleteHighFireValve}
        onCancel={hideDeleteHighFireValveConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteHighFireValveCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-high-fire-valve-common-customizable-table"
        usageIdentifier="appliance-details-high-fire-valve"
        data={
          applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable
        }
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["100% - 66px", "3.33%", "3.33%"]}
        desktopColMinWidths={["100% - 66px", "33px", "33px"]}
        mobileColWidths={["100% - 66px", "3.33%", "3.33%"]}
        mobileColMinWidths={["100% - 66px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-high-fire-valve-add-button"}
          onClickFunction={handleShowAddHighFireValve}
          text={"Add High Fire Valve"}
          size={6}
        />
        {showAddHighFireValve && (
          <AddHighFireValve
            active={showAddHighFireValve}
            onCancel={handleShowAddHighFireValve}
            onAdd={handleAddHighFireValve}
            retainedData={retainedNewHighFireValve}
            existingHighFireValve={
              applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable
            }
          />
        )}
      </StyledAddButtonDiv>
      {showEditHighFireValve && (
        <EditHighFireValve
          active={showEditHighFireValve}
          highFireValveInfo={chosenEditHighFireValve!}
          onCancel={handleShowEditHighFireValve}
          onEdit={showEditHighFireValveConfirmationBox}
          retainedData={retainedEditHighFireValve}
          existingHighFireValve={
            applianceConfigTable[parseInt(chosenAppliance)].highfirevalvetable
          }
        />
      )}
    </StyledMainDiv>
  );
};

export default ADHighFireValve;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
