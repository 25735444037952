import React, { useState } from "react";
import styled from "styled-components";
import CommonInput from "../common/formInputs/CommonInput";
import CommonTextArea from "../common/formInputs/CommonTextArea";
import Checkbox from "../common/formInputs/Checkbox";
import { IEWPAutomationForm } from "../../types/tools";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
}

const ProjectDetails: React.FC<SectionProps> = ({ onChange, form }) => {
  const [applicationSummary, setApplicationSummary] = useState<string | null>(
    form.applicationsummary
  );
  const [applicationSummaryErrors, setApplicationSummaryErrors] =
    useState<string>("");
  const handleApplicationSummary = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setApplicationSummary(value);
    setApplicationSummaryErrors("");

    onChange(value, "applicationsummary");
  };

  const [projectNumber] = useState<string | null>(form.projectnumber);

  const [clientName, setClientName] = useState<string | null>(form.clientname);
  const [clientNameErrors, setClientNameErrors] = useState<string>("");
  const handleClientName = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setClientName(value);
    setClientNameErrors("");

    onChange(value, "clientname");
  };

  const [siteID, setSiteID] = useState<string | null>(form.siteid);
  const [siteIDErrors, setSiteIDErrors] = useState<string>("");
  const handleSiteID = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSiteID(value);
    setSiteIDErrors("");

    onChange(value, "siteid");
  };

  const [applianceName, setApplianceName] = useState<string | null>(
    form.appliancename
  );
  const [applianceNameErrors, setApplianceNameErrors] = useState<string>("");
  const handleApplianceName = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setApplianceName(value);
    setApplianceNameErrors("");

    onChange(value, "appliancename");
  };

  const [applianceTag, setApplianceTag] = useState<string | null>(
    form.appliancetag
  );
  const [applianceTagErrors, setApplianceTagErrors] = useState<string>("");
  const handleApplianceTag = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setApplianceTag(value);
    setApplianceTagErrors("");

    onChange(value, "appliancetag");
  };

  const [documentNumber, setDocumentNumber] = useState<string | null>(
    form.documentnumber
  );
  const [documentNumberErrors, setDocumentNumberErrors] = useState<string>("");
  const handleDocumentNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setDocumentNumber(value);
    setDocumentNumberErrors("");

    onChange(value, "documentnumber");
  };

  const [documentRevisionNumber, setDocumentRevisionNumber] = useState<
    string | null
  >(form.documentrevisionnumber);
  const [documentRevisionNumberErrors, setDocumentRevisionNumberErrors] =
    useState<string>("");
  const handleDocumentRevisionNumber = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;
    setDocumentRevisionNumber(value);
    setDocumentRevisionNumberErrors("");

    onChange(value, "documentrevisionnumber");
  };

  const [firmwareVersion, setFirmwareVersion] = useState<string | null>(
    form.firmwareversion
  );
  const [firmwareVersionErrors, setFirmwareVersionErrors] =
    useState<string>("");
  const handleFirmwareVersion = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setFirmwareVersion(value);
    setFirmwareVersionErrors("");

    onChange(value, "firmwareversion");
  };

  const [generateNarrative, setGenerateNarrative] = useState<boolean | null>(
    form.generatenarrative
  );
  const handleGenerateNarrative = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setGenerateNarrative(checked);

    onChange(checked, "generatenarrative");
  };

  const [generateWiringDiagram, setGenerateWiringDiagram] = useState<
    boolean | null
  >(form.generatewiringdiagram);
  const handleGenerateWiringDiagram = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setGenerateWiringDiagram(checked);

    onChange(checked, "generatewiringdiagram");
  };

  const [generateSDK, setGenerateSDK] = useState<boolean | null>(
    form.generatesdk
  );
  const handleGenerateSDK = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setGenerateSDK(checked);

    onChange(checked, "generatesdk");
  };

  const [generateFAT, setGenerateFAT] = useState<boolean | null>(
    form.generatefat
  );
  const handleGenerateFAT = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setGenerateFAT(checked);

    onChange(checked, "generatefat");
  };

  const [isPanel, setIsPanel] = useState<boolean | null>(form.is_panel);
  const handleIsPanel = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setIsPanel(checked);

    onChange(checked, "is_panel");
  };

  return (
    <StyledMainDiv>
      <CommonTextArea
        dataTestname={"project-details-email-body-content-common-textarea"}
        labelText="Application Summary"
        value={applicationSummary === null ? "" : applicationSummary}
        onChange={handleApplicationSummary}
        errors={applicationSummaryErrors}
      />
      <CommonInput
        dataTestname={"project-details-project-number-common-input"}
        type={"text"}
        labelText="Project Number"
        value={projectNumber === null ? "" : projectNumber}
        readOnly={true}
      />
      <CommonInput
        dataTestname={"project-details-client-name-common-input"}
        type={"text"}
        labelText="Client Name"
        value={clientName === null ? "" : clientName}
        onChange={handleClientName}
        errors={clientNameErrors}
      />
      <CommonInput
        dataTestname={"project-details-site-id-common-input"}
        type={"text"}
        labelText="Site ID"
        value={siteID === null ? "" : siteID}
        onChange={handleSiteID}
        errors={siteIDErrors}
      />
      <CommonInput
        dataTestname={"project-details-appliance-name-common-input"}
        type={"text"}
        labelText="Appliance Name"
        value={applianceName === null ? "" : applianceName}
        onChange={handleApplianceName}
        errors={applianceNameErrors}
      />
      <CommonInput
        dataTestname={"project-details-appliance-tag-common-input"}
        type={"text"}
        labelText="Appliance Tag"
        value={applianceTag === null ? "" : applianceTag}
        onChange={handleApplianceTag}
        errors={applianceTagErrors}
      />
      <CommonInput
        dataTestname={"project-details-document-number-common-input"}
        type={"text"}
        labelText="Document Number"
        value={documentNumber === null ? "" : documentNumber}
        onChange={handleDocumentNumber}
        errors={documentNumberErrors}
      />
      <CommonInput
        dataTestname={"project-details-document-revision-number-common-input"}
        type={"text"}
        labelText="Document Revision Number"
        value={documentRevisionNumber === null ? "" : documentRevisionNumber}
        onChange={handleDocumentRevisionNumber}
        errors={documentRevisionNumberErrors}
      />
      <CommonInput
        dataTestname={"project-details-firmware-version-common-input"}
        type={"text"}
        labelText="Firmware Version"
        value={firmwareVersion === null ? "" : firmwareVersion}
        onChange={handleFirmwareVersion}
        errors={firmwareVersionErrors}
      />
      <Checkbox
        dataTestname={"project-details-generate-narrative-checkbox"}
        isChecked={generateNarrative === null ? false : generateNarrative}
        handleChange={handleGenerateNarrative}
        label={"Generate Narrative?"}
        arrayLetter={"P"}
      />
      <Checkbox
        dataTestname={"project-details-generate-wiring-diagram-checkbox"}
        isChecked={
          generateWiringDiagram === null ? false : generateWiringDiagram
        }
        handleChange={handleGenerateWiringDiagram}
        label={"Generate Wiring Diagram?"}
        arrayLetter={"P"}
      />
      <Checkbox
        dataTestname={"project-details-generate-sdk-checkbox"}
        isChecked={generateSDK === null ? false : generateSDK}
        handleChange={handleGenerateSDK}
        label={"Generate SDK?"}
        arrayLetter={"P"}
      />
      <Checkbox
        dataTestname={"project-details-generate-fat-checkbox"}
        isChecked={generateFAT === null ? false : generateFAT}
        handleChange={handleGenerateFAT}
        label={"Generate FAT?"}
        arrayLetter={"P"}
      />
      <Checkbox
        dataTestname={"project-details-is-panel-checkbox"}
        isChecked={isPanel === null ? false : isPanel}
        handleChange={handleIsPanel}
        label={"Is Panel?"}
        arrayLetter={"P"}
      />
    </StyledMainDiv>
  );
};

export default ProjectDetails;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
