import styled from "styled-components";
import { useState, useEffect } from "react";
import NavLogo from "../../../assets/images/login/Primary_White.png";
import MobileNavLogo from "../../../assets/images/login/Flame-O_White.png";
import { useNavigate, useLocation } from "react-router";
import UserMenuItem from "../navigation/UserMenuItem";

interface HeaderProps {
  dataTestname: string;
  user: any;
}

const Header: React.FC<HeaderProps> = ({ dataTestname, user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const getInitials = (): string => {
    const nameArray = user?.name.split(" ");

    return nameArray.length > 1
      ? nameArray[0][0] + nameArray[1][0]
      : nameArray[0];
  };

  const handleHomeNavigate = () => {
    navigate("/");
  };

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelectedMenuItem(path);
  }, [location.pathname]);

  const handleMenuSelect = (e: React.MouseEvent<any>) => {
    const selectedItem: string = e.currentTarget.name;
    setSelectedMenuItem(selectedItem);
    navigate("/" + selectedItem);
  };

  return (
    <StyledHeaderDiv data-testid="main-header-div">
      <StyledInnerHeaderDiv>
        <StyledLogoContainer>
          <StyledLogo
            data-testid="logo"
            onClick={handleHomeNavigate}
            src={NavLogo}
            alt="profire navigation logo"
          />
        </StyledLogoContainer>
        <StyledNav>
          <StyledMobileLogo
            data-testid="mobilelogo"
            onClick={handleHomeNavigate}
            src={MobileNavLogo}
            alt="profire navigation logo"
          />
          <StyledNavItem
            data-testid="profiretools"
            onClick={handleMenuSelect}
            name="profiretools"
            selectedMenuItem={selectedMenuItem}
          >
            <p>TOOLS</p>
            <div></div>
          </StyledNavItem>
        </StyledNav>
        <StyledIconsContainer>
          <StyledUserContainer>
            <UserMenuItem
              dataTestname="header-user-icon"
              initials={getInitials()}
              user={user}
            />
          </StyledUserContainer>
        </StyledIconsContainer>
      </StyledInnerHeaderDiv>
    </StyledHeaderDiv>
  );
};

export default Header;

interface ItemNameProps {
  name: string;
  selectedMenuItem: string;
}

const StyledHeaderDiv = styled.div`
  background-color: #f6f6f6;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  background-color: #1f1f1f;
  display: flex;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
    border-bottom: 1px solid #707070;
  }
`;

const StyledInnerHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: Calc(100%);
  max-width: 1200px;
  height: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100%);
    padding-left: 0px;
  }
`;

const StyledLogoContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
  }
`;

const StyledLogo = styled.img`
  width: 155.97px;
  height: 30px;
  padding-left: 0px;
  display: none;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
    padding-left: 20px;
  }
`;

const StyledMobileLogo = styled.img`
  height: 28.62px;
  padding-left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: none;
  }
`;

const StyledIconsContainer = styled.div`
  height: 100%;
  display: flex;
  padding-right: 0px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-right: 20px;
    min-width: 155.97px;
  }
`;

const StyledUserContainer = styled.div`
  display: none;
  justify-content: flex-end;
  margin: auto;
  width: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: flex;
  }
`;

const StyledNav = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  :first-child {
    margin-left: 0px;
    margin-right: 27px;
  }
  :last-child {
    margin-left: 27px;
    margin-right: 0;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    flex-direction: row;
  }
`;

const StyledNavItem = styled.a<ItemNameProps>`
  margin: auto 27px;
  p {
    font-size: 14px;
    color: #c8c8c8;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: ${(props) =>
      props.name === props.selectedMenuItem ? "#fff" : "#c8c8c8"};
  }

  p:hover {
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }

  display: none;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    display: block;
  }
`;
