export const FormatHeader = (headerTitle: string) => {
  if (headerTitle === null || headerTitle === undefined) {
    return "";
  }
  let stringToReturn: string = "";

  headerTitle = headerTitle.replaceAll("_", " ");
  headerTitle = headerTitle.replaceAll(/([A-Z])/g, " $1").trim();
  stringToReturn = titleCase(headerTitle);
  return stringToReturn;
};

function titleCase(str: string) {
  var splitStr = str.split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
}

export const UnFormat = (field: string) => {
  if (field === null || field === undefined) {
    return "";
  }

  field = field.replaceAll(" ", "_");
  field = field.toLowerCase().trim();
  return field;
};
