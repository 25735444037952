import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  IEWPAutomationForm,
  ITemperatureAssignment,
  ITemperatureConfiguration,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddTemperatureAssignment from "./temperatureAssignment/AddTemperatureAssignment";
import EditTemperatureAssignment from "./temperatureAssignment/EditTemperatureAssignment";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const TemperatureAssignment: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [temperatureAssignments, setTemperatureAssignments] = useState<
    ITemperatureAssignment[]
  >(CopyObjectNoPointer(form.physicaltempmap));

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>(["tempcardname", "tc1a", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Name", "TC1A", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditTemperatureAssignment,
    showDeleteTemperatureAssignmentConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add TemperatureAssignment
  const [showAddTemperatureAssignment, setShowAddTemperatureAssignment] =
    useState<boolean>(false);
  const [
    addTemperatureAssignmentCBActive,
    setAddTemperatureAssignmentCBActive,
  ] = useState<boolean>(false);
  const [addTemperatureAssignmentCBText, setAddTemperatureAssignmentCBText] =
    useState<string>("");
  const [
    retainedNewTemperatureAssignment,
    setRetainedNewTemperatureAssignment,
  ] = useState<ITemperatureAssignment>();

  function handleShowAddTemperatureAssignment() {
    if (showAddTemperatureAssignment) {
      setRetainedNewTemperatureAssignment(undefined);
    }
    setShowAddTemperatureAssignment(!showAddTemperatureAssignment);
  }

  //This function runs on clicking Save in Add TemperatureAssignment
  function handleAddTemperatureAssignment(e: ITemperatureAssignment) {
    setShowAddTemperatureAssignment(false);
    setAddTemperatureAssignmentCBActive(true);

    temperatureAssignments.push(e);
    onChange(temperatureAssignments, "physicaltempmap");

    setAddTemperatureAssignmentCBText(`${e.tempcardname} successfully added`);
    setRetainedNewTemperatureAssignment(undefined);
    setTemperatureAssignmentAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Temperature Assignment
  function completeAddTemperatureAssignment() {
    setAddTemperatureAssignmentCBActive(false);
    setAddTemperatureAssignmentCBText("");

    if (retainedNewTemperatureAssignment !== undefined) {
      setShowAddTemperatureAssignment(true);
    }
  }

  function setTemperatureAssignmentAfterAdd() {
    setTemperatureAssignments(form.physicaltempmap);
  }

  //Edit TemperatureAssignment
  const [showEditTemperatureAssignment, setShowEditTemperatureAssignment] =
    useState<boolean>(false);
  const [
    editTemperatureAssignmentCBActive,
    setEditTemperatureAssignmentCBActive,
  ] = useState<boolean>(false);
  const [editTemperatureAssignmentCBText, setEditTemperatureAssignmentCBText] =
    useState<string>("");
  const [
    editTemperatureAssignmentCBFinalDisplay,
    setEditTemperatureAssignmentCBFinalDisplay,
  ] = useState<boolean>(false);
  //retainedEditTemperatureAssignment is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [
    retainedEditTemperatureAssignment,
    setRetainedEditTemperatureAssignment,
  ] = useState<ITemperatureAssignment | undefined>();
  const [chosenEditTemperatureAssignment, setChosenEditTemperatureAssignment] =
    useState<ITemperatureAssignment>();

  function handleShowEditTemperatureAssignment(e: any = undefined) {
    if (showEditTemperatureAssignment) {
      setShowEditTemperatureAssignment(false);
      setChosenEditTemperatureAssignment(undefined);
    } else {
      setShowEditTemperatureAssignment(!showEditTemperatureAssignment);
      setChosenEditTemperatureAssignment(e);
    }
  }

  //This function runs on clicking Save in Edit TemperatureAssignment
  function showEditTemperatureAssignmentConfirmationBox(
    e: ITemperatureAssignment
  ) {
    setShowEditTemperatureAssignment(false);
    setEditTemperatureAssignmentCBActive(true);
    setEditTemperatureAssignmentCBText(
      "Are you sure you want to save these changes?"
    );
    setRetainedEditTemperatureAssignment(e);
  }

  //This function runs on clicking Cancel in Edit TemperatureAssignment
  function hideEditTemperatureAssignmentConfirmationBox() {
    setShowEditTemperatureAssignment(false);
    setEditTemperatureAssignmentCBActive(false);
    setEditTemperatureAssignmentCBText("");
    setRetainedEditTemperatureAssignment(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit TemperatureAssignment
  function handleEditTemperatureAssignment() {
    setEditTemperatureAssignmentCBText("");

    setTemperatureAssignments(
      temperatureAssignments.splice(
        GetEqualityIndex(
          temperatureAssignments,
          chosenEditTemperatureAssignment
        ),
        1,
        retainedEditTemperatureAssignment!
      )
    );
    onChange(temperatureAssignments, "physicaltempmap");

    setEditTemperatureAssignmentCBText(
      `${retainedEditTemperatureAssignment!.tempcardname} successfully updated`
    );
    setRetainedEditTemperatureAssignment(undefined);
    setTemperatureAssignmentAfterAdd();
    save();

    setEditTemperatureAssignmentCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit TemperatureAssignment
  function completeEditTemperatureAssignment() {
    setEditTemperatureAssignmentCBActive(false);
    setEditTemperatureAssignmentCBText("");
    setEditTemperatureAssignmentCBFinalDisplay(false);
    setChosenEditTemperatureAssignment(undefined);

    if (retainedEditTemperatureAssignment !== undefined) {
      setShowEditTemperatureAssignment(true);
    }
  }

  //Delete TemperatureAssignment
  const [
    deleteTemperatureAssignmentCBActive,
    setDeleteTemperatureAssignmentCBActive,
  ] = useState<boolean>(false);
  const [
    deleteTemperatureAssignmentCBText,
    setDeleteTemperatureAssignmentCBText,
  ] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteTemperatureAssignmentCBFinalDisplay,
    setDeleteTemperatureAssignmentCBFinalDisplay,
  ] = useState<boolean>(false);
  const [
    chosenDeleteTemperatureAssignment,
    setChosenDeleteTemperatureAssignment,
  ] = useState<ITemperatureAssignment>();

  //This function runs on clicking Save in Delete TemperatureAssignment
  function showDeleteTemperatureAssignmentConfirmationBox(
    e: ITemperatureAssignment
  ) {
    setChosenDeleteTemperatureAssignment(e);
    setDeleteTemperatureAssignmentCBActive(true);
    setDeleteTemperatureAssignmentCBText(
      `Are you sure you want to delete ${e.tempcardname}?`
    );
  }

  //This function runs on clicking Cancel in Delete TemperatureAssignment
  function hideDeleteTemperatureAssignmentConfirmationBox(e: any) {
    setDeleteTemperatureAssignmentCBActive(false);
    setDeleteTemperatureAssignmentCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete TemperatureAssignment
  function handleDeleteTemperatureAssignment() {
    setDeleteTemperatureAssignmentCBText("");

    setTemperatureAssignments(
      temperatureAssignments.splice(
        GetEqualityIndex(
          temperatureAssignments,
          chosenDeleteTemperatureAssignment
        ),
        1
      )
    );
    onChange(temperatureAssignments, "physicaltempmap");

    setDeleteTemperatureAssignmentCBText(
      `${chosenDeleteTemperatureAssignment!.tempcardname} successfully deleted`
    );
    setTemperatureAssignmentAfterAdd();
    save();

    setDeleteTemperatureAssignmentCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete TemperatureAssignment
  function completeDeleteTemperatureAssignment(e: any) {
    setDeleteTemperatureAssignmentCBActive(false);
    setDeleteTemperatureAssignmentCBText("");
    setDeleteTemperatureAssignmentCBFinalDisplay(false);
    setChosenDeleteTemperatureAssignment(undefined);
  }

  const [temperatureConfigs] = useState<ITemperatureConfiguration[]>(
    CopyObjectNoPointer(form.logicaltemptable)
  );
  const aConfigurationDropdown = useRef<[string, string][]>([]);
  const bConfigurationDropdown = useRef<[string, string][]>([]);

  useEffect(() => {
    aConfigurationDropdown.current = [];
    aConfigurationDropdown.current.push(["N/A", "N/A"]);
    temperatureConfigs.forEach((config) => {
      aConfigurationDropdown.current.push([config.temptag!, config.temptag!]);
    });

    bConfigurationDropdown.current = [];
    bConfigurationDropdown.current.push(["N/A", "N/A"]);
    bConfigurationDropdown.current.push(["Dual", "Dual"]);
    temperatureConfigs.forEach((config) => {
      if (config.temptype === "Single") {
        bConfigurationDropdown.current.push([config.temptag!, config.temptag!]);
      }
    });
  }, [temperatureConfigs]);

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="temperature-configuration-add-document-info-confirmation-box"
        heading={"Information"}
        message={addTemperatureAssignmentCBText}
        active={addTemperatureAssignmentCBActive}
        onOk={completeAddTemperatureAssignment}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-edit-document-info-confirmation-box"
        heading={"Information"}
        message={editTemperatureAssignmentCBText}
        active={editTemperatureAssignmentCBActive}
        onOk={completeEditTemperatureAssignment}
        onYes={handleEditTemperatureAssignment}
        onCancel={hideEditTemperatureAssignmentConfirmationBox}
        displayCancel={true}
        finalDisplay={editTemperatureAssignmentCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-delete-document-info-confirmation-box"
        heading={"Information"}
        message={deleteTemperatureAssignmentCBText}
        active={deleteTemperatureAssignmentCBActive}
        onOk={completeDeleteTemperatureAssignment}
        onYes={handleDeleteTemperatureAssignment}
        onCancel={hideDeleteTemperatureAssignmentConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteTemperatureAssignmentCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="temperature-configuration-common-customizable-table"
        usageIdentifier="temperatureAssignments"
        data={form.physicaltempmap}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-document-info-button-div">
        <ProfireButton
          dataTestname="temperature-configuration-add-document-info-button"
          onClickFunction={handleShowAddTemperatureAssignment}
          text="Add Temperature Assignment"
          size={7}
        />
        {showAddTemperatureAssignment && (
          <AddTemperatureAssignment
            active={showAddTemperatureAssignment}
            onCancel={handleShowAddTemperatureAssignment}
            onAdd={handleAddTemperatureAssignment}
            retainedData={retainedNewTemperatureAssignment}
            aConfigurationDropdown={aConfigurationDropdown.current}
            bConfigurationDropdown={bConfigurationDropdown.current}
            temperatureConfigs={temperatureConfigs}
            existingTemperatureAssignment={form.physicaltempmap}
          />
        )}
      </StyledAddButtonDiv>
      {showEditTemperatureAssignment && (
        <EditTemperatureAssignment
          active={showEditTemperatureAssignment}
          temperatureAssignment={chosenEditTemperatureAssignment!}
          onCancel={handleShowEditTemperatureAssignment}
          onEdit={showEditTemperatureAssignmentConfirmationBox}
          retainedData={retainedEditTemperatureAssignment}
          aConfigurationDropdown={aConfigurationDropdown.current}
          bConfigurationDropdown={bConfigurationDropdown.current}
          temperatureConfigs={temperatureConfigs}
          existingTemperatureAssignment={temperatureAssignments}
        />
      )}
    </StyledMainDiv>
  );
};

export default TemperatureAssignment;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
