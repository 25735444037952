import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IApplianceController } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";
import CommonTextArea from "../../common/formInputs/CommonTextArea";
import CommonRadioButtonGroup from "../../common/formInputs/ProfireRadioButtonGroup";

interface EditControllerProps {
  active: boolean;
  controller: IApplianceController;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IApplianceController | undefined;
  existingController: IApplianceController[];
}

const EditController: React.FC<EditControllerProps> = ({
  active,
  controller,
  onCancel,
  onEdit,
  retainedData,
  existingController,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [controllerMAC, setControllerMAC] = useState<string>(
    retainedData === undefined
      ? controller.controllermac === undefined
        ? ""
        : controller.controllermac
      : retainedData.controllermac!
  );
  const [controllerMACErrors, setControllerMACErrors] = useState<string>("");
  const handleControllerMAC = (e: React.FormEvent<HTMLInputElement>) => {
    setControllerMAC(e.currentTarget.value);
    setControllerMACErrors("");
  };

  const [controllerDecimalModbusAddress, setControllerDecimalModbusAddress] =
    useState<string>(
      retainedData === undefined
        ? controller.controllerdecimalmodbusaddress === undefined
          ? ""
          : controller.controllerdecimalmodbusaddress
        : retainedData.controllerdecimalmodbusaddress!
    );
  const [
    controllerDecimalModbusAddressErrors,
    setControllerDecimalModbusAddressErrors,
  ] = useState<string>("");
  const handleControllerDecimalModbusAddress = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setControllerDecimalModbusAddress(e.currentTarget.value);
    setControllerDecimalModbusAddressErrors("");
  };

  const [controllerName, setControllerName] = useState<string>(
    retainedData === undefined
      ? controller.controllername === undefined
        ? ""
        : controller.controllername
      : retainedData.controllername!
  );
  const [controllerNameErrors, setControllerNameErrors] = useState<string>("");
  const handleControllerName = (e: React.FormEvent<HTMLInputElement>) => {
    setControllerName(e.currentTarget.value);
    setControllerNameErrors("");
  };

  const [controllerComments, setControllerComments] = useState<string>(
    retainedData === undefined
      ? controller.controllercomments === undefined
        ? ""
        : controller.controllercomments
      : retainedData.controllercomments!
  );
  const [controllerCommentsErrors, setControllerCommentsErrors] =
    useState<string>("");
  const handleControllerComments = (e: React.FormEvent<HTMLInputElement>) => {
    setControllerComments(e.currentTarget.value);
    setControllerCommentsErrors("");
  };

  const [wiredToHFVOutput, setWiredToHFVOutput] = useState<string | null>(
    retainedData === undefined
      ? controller.has_forced_draft_fan_wired_to_hfv_output === true
        ? "Has Forced Draft Fan Wired to HFV Output"
        : controller.has_purge_fan_wired_to_hfv_output === true
        ? "Has Purge Fan Wired to HFV Output"
        : controller.has_valve_wired_to_hfv_output === true
        ? "Has Valve Wired to HFV Output"
        : ""
      : retainedData.has_forced_draft_fan_wired_to_hfv_output === true
      ? "Has Forced Draft Fan Wired to HFV Output"
      : retainedData.has_purge_fan_wired_to_hfv_output === true
      ? "Has Purge Fan Wired to HFV Output"
      : retainedData.has_valve_wired_to_hfv_output === true
      ? "Has Valve Wired to HFV Output"
      : ""
  );
  const [wiredToHFVOutputErrors, setWiredToHFVOutputErrors] =
    useState<string>("");
  const handleWiredToHFVOutput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setWiredToHFVOutput(value);
    setWiredToHFVOutputErrors("");

    if (value === "Has Forced Draft Fan Wired to HFV Output") {
      setHasForcedDraftFanWiredToHFVOutput(true);
      setHasPurgeFanWiredToHFVOutput(false);
      setHasValveWiredToHFVOutput(false);
    } else if (value === "Has Purge Fan Wired to HFV Output") {
      setHasForcedDraftFanWiredToHFVOutput(false);
      setHasPurgeFanWiredToHFVOutput(true);
      setHasValveWiredToHFVOutput(false);
    } else if (value === "Has Valve Wired to HFV Output") {
      setHasForcedDraftFanWiredToHFVOutput(false);
      setHasPurgeFanWiredToHFVOutput(false);
      setHasValveWiredToHFVOutput(true);
    } else {
      setHasForcedDraftFanWiredToHFVOutput(false);
      setHasPurgeFanWiredToHFVOutput(false);
      setHasValveWiredToHFVOutput(false);
    }
  };

  const [hasValveWiredToHFVOutput, setHasValveWiredToHFVOutput] =
    useState<boolean>(
      retainedData === undefined
        ? controller.has_valve_wired_to_hfv_output === undefined
          ? false
          : controller.has_valve_wired_to_hfv_output
        : retainedData.has_valve_wired_to_hfv_output!
    );

  const [
    hasForcedDraftFanWiredToHFVOutput,
    setHasForcedDraftFanWiredToHFVOutput,
  ] = useState<boolean>(
    retainedData === undefined
      ? controller.has_forced_draft_fan_wired_to_hfv_output === undefined
        ? false
        : controller.has_forced_draft_fan_wired_to_hfv_output
      : retainedData.has_forced_draft_fan_wired_to_hfv_output!
  );

  const [hasPurgeFanWiredToHFVOutput, setHasPurgeFanWiredToHFVOutput] =
    useState<boolean>(
      retainedData === undefined
        ? controller.has_purge_fan_wired_to_hfv_output === undefined
          ? false
          : controller.has_purge_fan_wired_to_hfv_output
        : retainedData.has_purge_fan_wired_to_hfv_output!
    );

  const [mainFlameDetectionEnabled, setMainFlameDetectionEnabled] =
    useState<boolean>(
      retainedData === undefined
        ? controller.main_flame_detection_enabled === undefined
          ? false
          : controller.main_flame_detection_enabled
        : retainedData.main_flame_detection_enabled!
    );
  const handleMainFlameDetectionEnabled = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setMainFlameDetectionEnabled(checked);
  };

  const [mainPermissiveEnabled, setMainPermissiveEnabled] = useState<boolean>(
    retainedData === undefined
      ? controller.main_permissive_enabled === undefined
        ? false
        : controller.main_permissive_enabled
      : retainedData.main_permissive_enabled!
  );
  const handleMainPermissiveEnabled = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setMainPermissiveEnabled(checked);
  };

  const [applianceFiringRateEnabled, setApplianceFiringRateEnabled] =
    useState<boolean>(
      retainedData === undefined
        ? controller.appliance_firing_rate_enabled === undefined
          ? false
          : controller.appliance_firing_rate_enabled
        : retainedData.appliance_firing_rate_enabled!
    );
  const handleApplianceFiringRateEnabled = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setApplianceFiringRateEnabled(checked);
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setControllerMACErrors("");
    setControllerNameErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!controllerMAC) {
      setControllerMACErrors("Controller MAC is required.\n");
      handleExistingErrors(true);
    }

    if (!controllerName) {
      setControllerNameErrors("Controller Name is required.\n");
      handleExistingErrors(true);
    }

    if (
      controller.controllermac !== controllerMAC &&
      existingController.find(
        (controller) => controller.controllermac === controllerMAC
      ) !== undefined
    ) {
      setControllerMACErrors("Controller MAC is already in use.\n");
      handleExistingErrors(true);
    }

    if (
      controller.controllername !== controllerName &&
      existingController.find(
        (controller) => controller.controllername === controllerName
      ) !== undefined
    ) {
      setControllerNameErrors("Controller Name is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const updatedController: IApplianceController = {
        controllermac: controllerMAC,
        controllerdecimalmodbusaddress: controllerDecimalModbusAddress,
        controllername: controllerName,
        controllercomments: controllerComments,
        has_valve_wired_to_hfv_output: hasValveWiredToHFVOutput,
        has_forced_draft_fan_wired_to_hfv_output:
          hasForcedDraftFanWiredToHFVOutput,
        has_purge_fan_wired_to_hfv_output: hasPurgeFanWiredToHFVOutput,
        main_flame_detection_enabled: mainFlameDetectionEnabled,
        main_permissive_enabled: mainPermissiveEnabled,
        appliance_firing_rate_enabled: applianceFiringRateEnabled,
        pilotcardtable: controller.pilotcardtable,
        pilotvalvetable: controller.pilotvalvetable,
        mainvalvetable: controller.mainvalvetable,
        highfirevalvetable: controller.highfirevalvetable,
        tcvtable: controller.tcvtable,
      };

      onEdit(updatedController);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-controller-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Controller"
      maxHeight={"50%"}
    >
      <StyledEditControllerDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-controller-controller-number-common-input"
                type={"text"}
                labelText={"Controller MAC"}
                required={true}
                readOnly={false}
                value={controllerMAC}
                onChange={handleControllerMAC}
                maxLength={50}
                placeholder={""}
                errors={controllerMACErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-controller-controller-decimal-modbus-address-common-input"
                type={"text"}
                labelText={"Controller Decimal Modbus Address"}
                required={false}
                readOnly={false}
                value={controllerDecimalModbusAddress}
                onChange={handleControllerDecimalModbusAddress}
                maxLength={50}
                placeholder={""}
                errors={controllerDecimalModbusAddressErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-controller-controller-name-common-input"
                type={"text"}
                labelText={"Controller Name"}
                required={true}
                readOnly={false}
                value={controllerName}
                onChange={handleControllerName}
                maxLength={50}
                placeholder={""}
                errors={controllerNameErrors}
                autoFocus={false}
              />
              <CommonTextArea
                dataTestname={"edit-controller-comments-common-textarea"}
                labelText="Controller Comments"
                value={controllerComments === null ? "" : controllerComments}
                onChange={handleControllerComments}
                errors={controllerCommentsErrors}
              />
              <CommonRadioButtonGroup
                dataTestname={
                  "edit-controller-wired-to-hfv-output-radio-button-group"
                }
                legendText="Please Select Wired to HFV Output Setting"
                options={[
                  "Has Forced Draft Fan Wired to HFV Output",
                  "Has Purge Fan Wired to HFV Output",
                  "Has Valve Wired to HFV Output",
                ]}
                onChange={handleWiredToHFVOutput}
                identifier={"A"}
                selected={wiredToHFVOutput === null ? "" : wiredToHFVOutput}
                errors={wiredToHFVOutputErrors}
              />
              <Checkbox
                dataTestname={
                  "edit-controller-main-flame-detection-enabled-checkbox"
                }
                isChecked={
                  mainFlameDetectionEnabled === null
                    ? false
                    : mainFlameDetectionEnabled
                }
                handleChange={handleMainFlameDetectionEnabled}
                label={"Main Flame Detection Enabled?"}
                arrayLetter={"C"}
              />
              <Checkbox
                dataTestname={
                  "edit-controller-main-permissive-enabled-checkbox"
                }
                isChecked={
                  mainPermissiveEnabled === null ? false : mainPermissiveEnabled
                }
                handleChange={handleMainPermissiveEnabled}
                label={"Main Permissive Enabled?"}
                arrayLetter={"C"}
              />
              <Checkbox
                dataTestname={
                  "edit-controller-appliance-firing-rate-enabled-checkbox"
                }
                isChecked={
                  applianceFiringRateEnabled === null
                    ? false
                    : applianceFiringRateEnabled
                }
                handleChange={handleApplianceFiringRateEnabled}
                label={"Appliance Firing Rate Enabled?"}
                arrayLetter={"C"}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-controller-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-controller-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditControllerDiv>
    </ModalBox>
  );
};

export default EditController;

const StyledEditControllerDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
