import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm, IWebsiteDocumentInfo } from "../../types/tools";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ProfireButton from "../common/buttons/ProfireButton";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import AddDocumentInfo from "./websiteDocuments/AddDocumentInfo";
import EditDocumentInfo from "./websiteDocuments/EditDocumentInfo";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const WebsiteDocuments: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [documents, setDocuments] = useState<IWebsiteDocumentInfo[]>(
    CopyObjectNoPointer(form.referencedocumenttable)
  );

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>(["docnumber", "docname", "", ""]);

  const [overwriteHeaders] = useState<string[]>([
    "Doc. Number",
    "Doc. Name",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditDocumentInfo,
    showDeleteDocumentInfoConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add DocumentInfo
  const [showAddDocumentInfo, setShowAddDocumentInfo] =
    useState<boolean>(false);
  const [addDocumentInfoCBActive, setAddDocumentInfoCBActive] =
    useState<boolean>(false);
  const [addDocumentInfoCBText, setAddDocumentInfoCBText] =
    useState<string>("");
  const [retainedNewDocumentInfo, setRetainedNewDocumentInfo] =
    useState<IWebsiteDocumentInfo>();

  function handleShowAddDocumentInfo() {
    if (showAddDocumentInfo) {
      setRetainedNewDocumentInfo(undefined);
    }
    setShowAddDocumentInfo(!showAddDocumentInfo);
  }

  //This function runs on clicking Save in Add DocumentInfo
  function handleAddDocumentInfo(e: IWebsiteDocumentInfo) {
    setShowAddDocumentInfo(false);
    setAddDocumentInfoCBActive(true);

    documents.push(e);
    onChange(documents, "referencedocumenttable");

    setAddDocumentInfoCBText(`${e.docname} successfully added`);
    setRetainedNewDocumentInfo(undefined);
    setDocumentInfoAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddDocumentInfo() {
    setAddDocumentInfoCBActive(false);
    setAddDocumentInfoCBText("");

    if (retainedNewDocumentInfo !== undefined) {
      setShowAddDocumentInfo(true);
    }
  }

  function setDocumentInfoAfterAdd() {
    setDocuments(form.referencedocumenttable);
  }

  //Edit DocumentInfo
  const [showEditDocumentInfo, setShowEditDocumentInfo] =
    useState<boolean>(false);
  const [editDocumentInfoCBActive, setEditDocumentInfoCBActive] =
    useState<boolean>(false);
  const [editDocumentInfoCBText, setEditDocumentInfoCBText] =
    useState<string>("");
  const [editDocumentInfoCBFinalDisplay, setEditDocumentInfoCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditDocumentInfo is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditDocumentInfo, setRetainedEditDocumentInfo] = useState<
    IWebsiteDocumentInfo | undefined
  >();
  const [chosenEditDocumentInfo, setChosenEditDocumentInfo] =
    useState<IWebsiteDocumentInfo>();

  function handleShowEditDocumentInfo(e: any = undefined) {
    if (showEditDocumentInfo) {
      setShowEditDocumentInfo(false);
      setChosenEditDocumentInfo(undefined);
    } else {
      setShowEditDocumentInfo(!showEditDocumentInfo);
      setChosenEditDocumentInfo(e);
    }
  }

  //This function runs on clicking Save in Edit DocumentInfo
  function showEditDocumentInfoConfirmationBox(e: IWebsiteDocumentInfo) {
    setShowEditDocumentInfo(false);
    setEditDocumentInfoCBActive(true);
    setEditDocumentInfoCBText("Are you sure you want to save these changes?");
    setRetainedEditDocumentInfo(e);
  }

  //This function runs on clicking Cancel in Edit DocumentInfo
  function hideEditDocumentInfoConfirmationBox() {
    setShowEditDocumentInfo(false);
    setEditDocumentInfoCBActive(false);
    setEditDocumentInfoCBText("");
    setRetainedEditDocumentInfo(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit DocumentInfo
  function handleEditDocumentInfo() {
    setEditDocumentInfoCBText("");

    setDocuments(
      documents.splice(
        GetEqualityIndex(documents, chosenEditDocumentInfo),
        1,
        retainedEditDocumentInfo!
      )
    );
    onChange(documents, "referencedocumenttable");

    setEditDocumentInfoCBText(
      `${retainedEditDocumentInfo!.docname} successfully updated`
    );
    setRetainedEditDocumentInfo(undefined);
    setDocumentInfoAfterAdd();
    save();

    setEditDocumentInfoCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit DocumentInfo
  function completeEditDocumentInfo() {
    setEditDocumentInfoCBActive(false);
    setEditDocumentInfoCBText("");
    setEditDocumentInfoCBFinalDisplay(false);
    setChosenEditDocumentInfo(undefined);

    if (retainedEditDocumentInfo !== undefined) {
      setShowEditDocumentInfo(true);
    }
  }

  //Delete DocumentInfo
  const [deleteDocumentInfoCBActive, setDeleteDocumentInfoCBActive] =
    useState<boolean>(false);
  const [deleteDocumentInfoCBText, setDeleteDocumentInfoCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteDocumentInfoCBFinalDisplay,
    setDeleteDocumentInfoCBFinalDisplay,
  ] = useState<boolean>(false);
  const [chosenDeleteDocumentInfo, setChosenDeleteDocumentInfo] =
    useState<IWebsiteDocumentInfo>();

  //This function runs on clicking Save in Delete DocumentInfo
  function showDeleteDocumentInfoConfirmationBox(e: IWebsiteDocumentInfo) {
    setChosenDeleteDocumentInfo(e);
    setDeleteDocumentInfoCBActive(true);
    setDeleteDocumentInfoCBText(
      `Are you sure you want to delete ${e.docname}?`
    );
  }

  //This function runs on clicking Cancel in Delete DocumentInfo
  function hideDeleteDocumentInfoConfirmationBox(e: any) {
    setDeleteDocumentInfoCBActive(false);
    setDeleteDocumentInfoCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete DocumentInfo
  function handleDeleteDocumentInfo() {
    setDeleteDocumentInfoCBText("");

    setDocuments(
      documents.splice(GetEqualityIndex(documents, chosenDeleteDocumentInfo), 1)
    );
    onChange(documents, "referencedocumenttable");

    setDeleteDocumentInfoCBText(
      `${chosenDeleteDocumentInfo!.docname} successfully deleted`
    );
    setDocumentInfoAfterAdd();
    save();

    setDeleteDocumentInfoCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete DocumentInfo
  function completeDeleteDocumentInfo(e: any) {
    setDeleteDocumentInfoCBActive(false);
    setDeleteDocumentInfoCBText("");
    setDeleteDocumentInfoCBFinalDisplay(false);
    setChosenDeleteDocumentInfo(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="website-documents-add-document-info-confirmation-box"
        heading={"Information"}
        message={addDocumentInfoCBText}
        active={addDocumentInfoCBActive}
        onOk={completeAddDocumentInfo}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="website-documents-edit-document-info-confirmation-box"
        heading={"Information"}
        message={editDocumentInfoCBText}
        active={editDocumentInfoCBActive}
        onOk={completeEditDocumentInfo}
        onYes={handleEditDocumentInfo}
        onCancel={hideEditDocumentInfoConfirmationBox}
        displayCancel={true}
        finalDisplay={editDocumentInfoCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="website-documents-delete-document-info-confirmation-box"
        heading={"Information"}
        message={deleteDocumentInfoCBText}
        active={deleteDocumentInfoCBActive}
        onOk={completeDeleteDocumentInfo}
        onYes={handleDeleteDocumentInfo}
        onCancel={hideDeleteDocumentInfoConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteDocumentInfoCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="engineering-documents-common-customizable-table"
        usageIdentifier="engineering-documents"
        data={form.referencedocumenttable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-document-info-button-div">
        <ProfireButton
          dataTestname="engineering-documents-add-document-info-button"
          onClickFunction={handleShowAddDocumentInfo}
          text="Add Document Info"
          size={6}
        />
        {showAddDocumentInfo && (
          <AddDocumentInfo
            active={showAddDocumentInfo}
            onCancel={handleShowAddDocumentInfo}
            onAdd={handleAddDocumentInfo}
            retainedData={retainedNewDocumentInfo}
            existingDocumentInfo={form.referencedocumenttable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditDocumentInfo && (
        <EditDocumentInfo
          active={showEditDocumentInfo}
          documentInfo={chosenEditDocumentInfo!}
          onCancel={handleShowEditDocumentInfo}
          onEdit={showEditDocumentInfoConfirmationBox}
          retainedData={retainedEditDocumentInfo}
          existingDocumentInfo={documents}
        />
      )}
    </StyledMainDiv>
  );
};

export default WebsiteDocuments;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;
`;
