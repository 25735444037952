import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IApplianceController,
  IEWPAutomationForm,
  IPilotCard,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import Dropdown from "../common/formInputs/Dropdown";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import EditPilotCard from "./adPilotCard/EditPilotCard";
import AddPilotCard from "./adPilotCard/AddPilotCard";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ADPilotCard: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [applianceConfigTable, setApplianceConfigTable] = useState<
    IApplianceController[]
  >(CopyObjectNoPointer(form.applianceconfigtable));

  let appliances: [string, string][];
  appliances = [];

  applianceConfigTable.forEach((appliance, index) => {
    appliances.push([appliance.controllername, index.toString()]);
  });

  //Section states
  const [chosenAppliance, setChosenAppliance] = useState<string>(
    appliances.length > 0 ? appliances[0][1] : ""
  );

  function updateChosenAppliance(newAppliance: string) {
    setChosenAppliance(newAppliance);
  }

  useEffect(() => {
    setApplianceConfigTable(form.applianceconfigtable);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>([
    "pilotcardname",
    "pilotcardcomments",
    "",
    "",
  ]);

  const [overwriteHeaders] = useState<string[]>(["Name", "Comments", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditPilotCard,
    showDeletePilotCardConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add PilotCard
  const [showAddPilotCard, setShowAddPilotCard] = useState<boolean>(false);
  const [addPilotCardCBActive, setAddPilotCardCBActive] =
    useState<boolean>(false);
  const [addPilotCardCBText, setAddPilotCardCBText] = useState<string>("");
  const [retainedNewPilotCard, setRetainedNewPilotCard] =
    useState<IPilotCard>();

  function handleShowAddPilotCard() {
    if (showAddPilotCard) {
      setRetainedNewPilotCard(undefined);
    }
    setShowAddPilotCard(!showAddPilotCard);
  }

  //This function runs on clicking Save in Add PilotCard
  function handleAddPilotCard(e: IPilotCard) {
    setShowAddPilotCard(false);
    setAddPilotCardCBActive(true);

    let pcUpdate: IPilotCard[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable,
    ];
    pcUpdate.push(e);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].pilotcardtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setAddPilotCardCBText(`${e.pilotcardname} successfully added`);
    setRetainedNewPilotCard(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddPilotCard() {
    setAddPilotCardCBActive(false);
    setAddPilotCardCBText("");

    if (retainedNewPilotCard !== undefined) {
      setShowAddPilotCard(true);
    }
  }

  //Edit PilotCard
  const [showEditPilotCard, setShowEditPilotCard] = useState<boolean>(false);
  const [editPilotCardCBActive, setEditPilotCardCBActive] =
    useState<boolean>(false);
  const [editPilotCardCBText, setEditPilotCardCBText] = useState<string>("");
  const [editPilotCardCBFinalDisplay, setEditPilotCardCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditPilotCard is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditPilotCard, setRetainedEditPilotCard] = useState<
    IPilotCard | undefined
  >();
  const [chosenEditPilotCard, setChosenEditPilotCard] = useState<IPilotCard>();

  function handleShowEditPilotCard(e: any = undefined) {
    if (showEditPilotCard) {
      setShowEditPilotCard(false);
      setChosenEditPilotCard(undefined);
    } else {
      setShowEditPilotCard(!showEditPilotCard);
      setChosenEditPilotCard(e);
    }
  }

  //This function runs on clicking Save in Edit PilotCard
  function showEditPilotCardConfirmationBox(e: IPilotCard) {
    setShowEditPilotCard(false);
    setEditPilotCardCBActive(true);
    setEditPilotCardCBText("Are you sure you want to save these changes?");
    setRetainedEditPilotCard(e);
  }

  //This function runs on clicking Cancel in Edit PilotCard
  function hideEditPilotCardConfirmationBox() {
    setShowEditPilotCard(false);
    setEditPilotCardCBActive(false);
    setEditPilotCardCBText("");
    setRetainedEditPilotCard(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit PilotCard
  function handleEditPilotCard() {
    setEditPilotCardCBText("");

    let pcUpdate: IPilotCard[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable,
    ];
    pcUpdate.splice(
      GetEqualityIndex(pcUpdate, chosenEditPilotCard),
      1,
      retainedEditPilotCard!
    );
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].pilotcardtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setEditPilotCardCBText(
      `${retainedEditPilotCard!.pilotcardname} successfully updated`
    );
    setRetainedEditPilotCard(undefined);
    save();

    setEditPilotCardCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit PilotCard
  function completeEditPilotCard() {
    setEditPilotCardCBActive(false);
    setEditPilotCardCBText("");
    setEditPilotCardCBFinalDisplay(false);
    setChosenEditPilotCard(undefined);

    if (retainedEditPilotCard !== undefined) {
      setShowEditPilotCard(true);
    }
  }

  //Delete PilotCard
  const [deletePilotCardCBActive, setDeletePilotCardCBActive] =
    useState<boolean>(false);
  const [deletePilotCardCBText, setDeletePilotCardCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deletePilotCardCBFinalDisplay, setDeletePilotCardCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeletePilotCard, setChosenDeletePilotCard] =
    useState<IPilotCard>();

  //This function runs on clicking Save in Delete PilotCard
  function showDeletePilotCardConfirmationBox(e: IPilotCard) {
    setChosenDeletePilotCard(e);
    setDeletePilotCardCBActive(true);
    setDeletePilotCardCBText(
      `Are you sure you want to delete ${e.pilotcardname}?`
    );
  }

  //This function runs on clicking Cancel in Delete PilotCard
  function hideDeletePilotCardConfirmationBox(e: any) {
    setDeletePilotCardCBActive(false);
    setDeletePilotCardCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete PilotCard
  function handleDeletePilotCard() {
    setDeletePilotCardCBText("");

    let pcUpdate: IPilotCard[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable,
    ];
    pcUpdate.splice(GetEqualityIndex(pcUpdate, chosenDeletePilotCard), 1);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].pilotcardtable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setDeletePilotCardCBText(
      `${chosenDeletePilotCard!.pilotcardname} successfully deleted`
    );
    save();

    setDeletePilotCardCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete PilotCard
  function completeDeletePilotCard(e: any) {
    setDeletePilotCardCBActive(false);
    setDeletePilotCardCBText("");
    setDeletePilotCardCBFinalDisplay(false);
    setChosenDeletePilotCard(undefined);
  }

  return appliances.length === 0 ? (
    <StyledMessageDiv data-testname={"add-controller-error-message"}>
      Please add a controller in "Appliance Details" to access the Appliance
      Details sub-pages
    </StyledMessageDiv>
  ) : (
    <StyledMainDiv>
      <Dropdown
        dataTestname={"ad-pilot-card-appliance-dropdown"}
        columns={appliances}
        labelText={"Appliance"}
        onchange={updateChosenAppliance}
        selected={chosenAppliance}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-card-add-pilot-card-confirmation-box"
        heading={"Information"}
        message={addPilotCardCBText}
        active={addPilotCardCBActive}
        onOk={completeAddPilotCard}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-card-edit-pilot-card-confirmation-box"
        heading={"Information"}
        message={editPilotCardCBText}
        active={editPilotCardCBActive}
        onOk={completeEditPilotCard}
        onYes={handleEditPilotCard}
        onCancel={hideEditPilotCardConfirmationBox}
        displayCancel={true}
        finalDisplay={editPilotCardCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-card-delete-pilot-card-confirmation-box"
        heading={"Information"}
        message={deletePilotCardCBText}
        active={deletePilotCardCBActive}
        onOk={completeDeletePilotCard}
        onYes={handleDeletePilotCard}
        onCancel={hideDeletePilotCardConfirmationBox}
        displayCancel={true}
        finalDisplay={deletePilotCardCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-pilot-card-common-customizable-table"
        usageIdentifier="appliance-details-pilot-card"
        data={applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-pilot-card-add-button"}
          onClickFunction={handleShowAddPilotCard}
          text={"Add Pilot Card"}
          size={4}
        />
        {showAddPilotCard && (
          <AddPilotCard
            active={showAddPilotCard}
            onCancel={handleShowAddPilotCard}
            onAdd={handleAddPilotCard}
            retainedData={retainedNewPilotCard}
            existingPilotCard={
              applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable
            }
          />
        )}
      </StyledAddButtonDiv>
      {showEditPilotCard && (
        <EditPilotCard
          active={showEditPilotCard}
          pilotCardInfo={chosenEditPilotCard!}
          onCancel={handleShowEditPilotCard}
          onEdit={showEditPilotCardConfirmationBox}
          retainedData={retainedEditPilotCard}
          existingPilotCard={
            applianceConfigTable[parseInt(chosenAppliance)].pilotcardtable
          }
        />
      )}
    </StyledMainDiv>
  );
};

export default ADPilotCard;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
