import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm, IIOExpansionInputs } from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddIOExpansionInputs from "./ioExpansionInputs/AddIOExpansionInputs";
import EditIOExpansionInputs from "./ioExpansionInputs/EditIOExpansionInputs";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const IOExpansionInputs: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [temperatureConfigurations, setIOExpansionInputss] = useState<
    IIOExpansionInputs[]
  >(CopyObjectNoPointer(form.logicaliotable));

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>(["iotag", "iodesc", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Tag", "Description", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditIOExpansionInputs,
    showDeleteIOExpansionInputsConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add IOExpansionInputs
  const [showAddIOExpansionInputs, setShowAddIOExpansionInputs] =
    useState<boolean>(false);
  const [addIOExpansionInputsCBActive, setAddIOExpansionInputsCBActive] =
    useState<boolean>(false);
  const [addIOExpansionInputsCBText, setAddIOExpansionInputsCBText] =
    useState<string>("");
  const [retainedNewIOExpansionInputs, setRetainedNewIOExpansionInputs] =
    useState<IIOExpansionInputs>();

  function handleShowAddIOExpansionInputs() {
    if (showAddIOExpansionInputs) {
      setRetainedNewIOExpansionInputs(undefined);
    }
    setShowAddIOExpansionInputs(!showAddIOExpansionInputs);
  }

  //This function runs on clicking Save in Add IOExpansionInputs
  function handleAddIOExpansionInputs(e: IIOExpansionInputs) {
    setShowAddIOExpansionInputs(false);
    setAddIOExpansionInputsCBActive(true);

    temperatureConfigurations.push(e);
    onChange(temperatureConfigurations, "logicaliotable");

    setAddIOExpansionInputsCBText(`${e.iotag} successfully added`);
    setRetainedNewIOExpansionInputs(undefined);
    setIOExpansionInputsAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Temperature Configuration
  function completeAddIOExpansionInputs() {
    setAddIOExpansionInputsCBActive(false);
    setAddIOExpansionInputsCBText("");

    if (retainedNewIOExpansionInputs !== undefined) {
      setShowAddIOExpansionInputs(true);
    }
  }

  function setIOExpansionInputsAfterAdd() {
    setIOExpansionInputss(form.logicaliotable);
  }

  //Edit IOExpansionInputs
  const [showEditIOExpansionInputs, setShowEditIOExpansionInputs] =
    useState<boolean>(false);
  const [editIOExpansionInputsCBActive, setEditIOExpansionInputsCBActive] =
    useState<boolean>(false);
  const [editIOExpansionInputsCBText, setEditIOExpansionInputsCBText] =
    useState<string>("");
  const [
    editIOExpansionInputsCBFinalDisplay,
    setEditIOExpansionInputsCBFinalDisplay,
  ] = useState<boolean>(false);
  //retainedEditIOExpansionInputs is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditIOExpansionInputs, setRetainedEditIOExpansionInputs] =
    useState<IIOExpansionInputs | undefined>();
  const [chosenEditIOExpansionInputs, setChosenEditIOExpansionInputs] =
    useState<IIOExpansionInputs>();

  function handleShowEditIOExpansionInputs(e: any = undefined) {
    if (showEditIOExpansionInputs) {
      setShowEditIOExpansionInputs(false);
      setChosenEditIOExpansionInputs(undefined);
    } else {
      setShowEditIOExpansionInputs(!showEditIOExpansionInputs);
      setChosenEditIOExpansionInputs(e);
    }
  }

  //This function runs on clicking Save in Edit IOExpansionInputs
  function showEditIOExpansionInputsConfirmationBox(e: IIOExpansionInputs) {
    setShowEditIOExpansionInputs(false);
    setEditIOExpansionInputsCBActive(true);
    setEditIOExpansionInputsCBText(
      "Are you sure you want to save these changes?"
    );
    setRetainedEditIOExpansionInputs(e);
  }

  //This function runs on clicking Cancel in Edit IOExpansionInputs
  function hideEditIOExpansionInputsConfirmationBox() {
    setShowEditIOExpansionInputs(false);
    setEditIOExpansionInputsCBActive(false);
    setEditIOExpansionInputsCBText("");
    setRetainedEditIOExpansionInputs(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit IOExpansionInputs
  function handleEditIOExpansionInputs() {
    setEditIOExpansionInputsCBText("");

    setIOExpansionInputss(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenEditIOExpansionInputs
        ),
        1,
        retainedEditIOExpansionInputs!
      )
    );
    onChange(temperatureConfigurations, "logicaliotable");

    setEditIOExpansionInputsCBText(
      `${retainedEditIOExpansionInputs!.iotag} successfully updated`
    );
    setRetainedEditIOExpansionInputs(undefined);
    setIOExpansionInputsAfterAdd();
    save();

    setEditIOExpansionInputsCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit IOExpansionInputs
  function completeEditIOExpansionInputs() {
    setEditIOExpansionInputsCBActive(false);
    setEditIOExpansionInputsCBText("");
    setEditIOExpansionInputsCBFinalDisplay(false);
    setChosenEditIOExpansionInputs(undefined);

    if (retainedEditIOExpansionInputs !== undefined) {
      setShowEditIOExpansionInputs(true);
    }
  }

  //Delete IOExpansionInputs
  const [deleteIOExpansionInputsCBActive, setDeleteIOExpansionInputsCBActive] =
    useState<boolean>(false);
  const [deleteIOExpansionInputsCBText, setDeleteIOExpansionInputsCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteIOExpansionInputsCBFinalDisplay,
    setDeleteIOExpansionInputsCBFinalDisplay,
  ] = useState<boolean>(false);
  const [chosenDeleteIOExpansionInputs, setChosenDeleteIOExpansionInputs] =
    useState<IIOExpansionInputs>();

  //This function runs on clicking Save in Delete IOExpansionInputs
  function showDeleteIOExpansionInputsConfirmationBox(e: IIOExpansionInputs) {
    setChosenDeleteIOExpansionInputs(e);
    setDeleteIOExpansionInputsCBActive(true);
    setDeleteIOExpansionInputsCBText(
      `Are you sure you want to delete ${e.iotag}?`
    );
  }

  //This function runs on clicking Cancel in Delete IOExpansionInputs
  function hideDeleteIOExpansionInputsConfirmationBox(e: any) {
    setDeleteIOExpansionInputsCBActive(false);
    setDeleteIOExpansionInputsCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete IOExpansionInputs
  function handleDeleteIOExpansionInputs() {
    setDeleteIOExpansionInputsCBText("");

    setIOExpansionInputss(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenDeleteIOExpansionInputs
        ),
        1
      )
    );
    onChange(temperatureConfigurations, "logicaliotable");

    setDeleteIOExpansionInputsCBText(
      `${chosenDeleteIOExpansionInputs!.iotag} successfully deleted`
    );
    setIOExpansionInputsAfterAdd();
    save();

    setDeleteIOExpansionInputsCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete IOExpansionInputs
  function completeDeleteIOExpansionInputs(e: any) {
    setDeleteIOExpansionInputsCBActive(false);
    setDeleteIOExpansionInputsCBText("");
    setDeleteIOExpansionInputsCBFinalDisplay(false);
    setChosenDeleteIOExpansionInputs(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="temperature-configuration-add-document-info-confirmation-box"
        heading={"Information"}
        message={addIOExpansionInputsCBText}
        active={addIOExpansionInputsCBActive}
        onOk={completeAddIOExpansionInputs}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-edit-document-info-confirmation-box"
        heading={"Information"}
        message={editIOExpansionInputsCBText}
        active={editIOExpansionInputsCBActive}
        onOk={completeEditIOExpansionInputs}
        onYes={handleEditIOExpansionInputs}
        onCancel={hideEditIOExpansionInputsConfirmationBox}
        displayCancel={true}
        finalDisplay={editIOExpansionInputsCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-delete-document-info-confirmation-box"
        heading={"Information"}
        message={deleteIOExpansionInputsCBText}
        active={deleteIOExpansionInputsCBActive}
        onOk={completeDeleteIOExpansionInputs}
        onYes={handleDeleteIOExpansionInputs}
        onCancel={hideDeleteIOExpansionInputsConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteIOExpansionInputsCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="temperature-configuration-common-customizable-table"
        usageIdentifier="temperatureConfigurations"
        data={form.logicaliotable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-document-info-button-div">
        <ProfireButton
          dataTestname="temperature-configuration-add-document-info-button"
          onClickFunction={handleShowAddIOExpansionInputs}
          text="Add IO Expansion Inputs"
          size={7}
        />
        {showAddIOExpansionInputs && (
          <AddIOExpansionInputs
            active={showAddIOExpansionInputs}
            onCancel={handleShowAddIOExpansionInputs}
            onAdd={handleAddIOExpansionInputs}
            retainedData={retainedNewIOExpansionInputs}
            existingIOExpansionInputs={form.logicaliotable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditIOExpansionInputs && (
        <EditIOExpansionInputs
          active={showEditIOExpansionInputs}
          ioExpansionInputs={chosenEditIOExpansionInputs!}
          onCancel={handleShowEditIOExpansionInputs}
          onEdit={showEditIOExpansionInputsConfirmationBox}
          retainedData={retainedEditIOExpansionInputs}
          existingIOExpansionInputs={temperatureConfigurations}
        />
      )}
    </StyledMainDiv>
  );
};

export default IOExpansionInputs;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
