import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IApplianceController,
  IEWPAutomationForm,
  IPilotValve,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import Dropdown from "../common/formInputs/Dropdown";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import EditPilotValve from "./adPilotValve/EditPilotValve";
import AddPilotValve from "./adPilotValve/AddPilotValve";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ADPilotValve: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [applianceConfigTable, setApplianceConfigTable] = useState<
    IApplianceController[]
  >(CopyObjectNoPointer(form.applianceconfigtable));

  let appliances: [string, string][];
  appliances = [];

  applianceConfigTable.forEach((appliance, index) => {
    appliances.push([appliance.controllername, index.toString()]);
  });

  //Section states
  const [chosenAppliance, setChosenAppliance] = useState<string>(
    appliances.length > 0 ? appliances[0][1] : ""
  );

  function updateChosenAppliance(newAppliance: string) {
    setChosenAppliance(newAppliance);
  }

  useEffect(() => {
    setApplianceConfigTable(form.applianceconfigtable);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>([
    "pilotvalvetag",
    "is_bleed_valve",
    "",
    "",
  ]);

  const [overwriteHeaders] = useState<string[]>([
    "Name",
    "Is Bleed Valve",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditPilotValve,
    showDeletePilotValveConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add PilotValve
  const [showAddPilotValve, setShowAddPilotValve] = useState<boolean>(false);
  const [addPilotValveCBActive, setAddPilotValveCBActive] =
    useState<boolean>(false);
  const [addPilotValveCBText, setAddPilotValveCBText] = useState<string>("");
  const [retainedNewPilotValve, setRetainedNewPilotValve] =
    useState<IPilotValve>();

  function handleShowAddPilotValve() {
    if (showAddPilotValve) {
      setRetainedNewPilotValve(undefined);
    }
    setShowAddPilotValve(!showAddPilotValve);
  }

  //This function runs on clicking Save in Add PilotValve
  function handleAddPilotValve(e: IPilotValve) {
    setShowAddPilotValve(false);
    setAddPilotValveCBActive(true);

    let pvUpdate: IPilotValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable,
    ];
    pvUpdate.push(e);
    let acUpdate: IApplianceController[] = [...applianceConfigTable];
    acUpdate[parseInt(chosenAppliance)].pilotvalvetable = pvUpdate;
    onChange(acUpdate, "applianceconfigtable");

    setAddPilotValveCBText(`${e.pilotvalvetag} successfully added`);
    setRetainedNewPilotValve(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddPilotValve() {
    setAddPilotValveCBActive(false);
    setAddPilotValveCBText("");

    if (retainedNewPilotValve !== undefined) {
      setShowAddPilotValve(true);
    }
  }

  //Edit PilotValve
  const [showEditPilotValve, setShowEditPilotValve] = useState<boolean>(false);
  const [editPilotValveCBActive, setEditPilotValveCBActive] =
    useState<boolean>(false);
  const [editPilotValveCBText, setEditPilotValveCBText] = useState<string>("");
  const [editPilotValveCBFinalDisplay, setEditPilotValveCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditPilotValve is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditPilotValve, setRetainedEditPilotValve] = useState<
    IPilotValve | undefined
  >();
  const [chosenEditPilotValve, setChosenEditPilotValve] =
    useState<IPilotValve>();

  function handleShowEditPilotValve(e: any = undefined) {
    if (showEditPilotValve) {
      setShowEditPilotValve(false);
      setChosenEditPilotValve(undefined);
    } else {
      setShowEditPilotValve(!showEditPilotValve);
      setChosenEditPilotValve(e);
    }
  }

  //This function runs on clicking Save in Edit PilotValve
  function showEditPilotValveConfirmationBox(e: IPilotValve) {
    setShowEditPilotValve(false);
    setEditPilotValveCBActive(true);
    setEditPilotValveCBText("Are you sure you want to save these changes?");
    setRetainedEditPilotValve(e);
  }

  //This function runs on clicking Cancel in Edit PilotValve
  function hideEditPilotValveConfirmationBox() {
    setShowEditPilotValve(false);
    setEditPilotValveCBActive(false);
    setEditPilotValveCBText("");
    setRetainedEditPilotValve(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit PilotValve
  function handleEditPilotValve() {
    setEditPilotValveCBText("");

    let pvUpdate: IPilotValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable,
    ];
    pvUpdate.splice(
      GetEqualityIndex(pvUpdate, chosenEditPilotValve),
      1,
      retainedEditPilotValve!
    );
    let acUpdate: IApplianceController[] = [...applianceConfigTable];
    acUpdate[parseInt(chosenAppliance)].pilotvalvetable = pvUpdate;
    onChange(acUpdate, "applianceconfigtable");

    setEditPilotValveCBText(
      `${retainedEditPilotValve!.pilotvalvetag} successfully updated`
    );
    setRetainedEditPilotValve(undefined);
    save();

    setEditPilotValveCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit PilotValve
  function completeEditPilotValve() {
    setEditPilotValveCBActive(false);
    setEditPilotValveCBText("");
    setEditPilotValveCBFinalDisplay(false);
    setChosenEditPilotValve(undefined);

    if (retainedEditPilotValve !== undefined) {
      setShowEditPilotValve(true);
    }
  }

  //Delete PilotValve
  const [deletePilotValveCBActive, setDeletePilotValveCBActive] =
    useState<boolean>(false);
  const [deletePilotValveCBText, setDeletePilotValveCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deletePilotValveCBFinalDisplay, setDeletePilotValveCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeletePilotValve, setChosenDeletePilotValve] =
    useState<IPilotValve>();

  //This function runs on clicking Save in Delete PilotValve
  function showDeletePilotValveConfirmationBox(e: IPilotValve) {
    setChosenDeletePilotValve(e);
    setDeletePilotValveCBActive(true);
    setDeletePilotValveCBText(
      `Are you sure you want to delete ${e.pilotvalvetag}?`
    );
  }

  //This function runs on clicking Cancel in Delete PilotValve
  function hideDeletePilotValveConfirmationBox(e: any) {
    setDeletePilotValveCBActive(false);
    setDeletePilotValveCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete PilotValve
  function handleDeletePilotValve() {
    setDeletePilotValveCBText("");

    let pvUpdate: IPilotValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable,
    ];
    pvUpdate.splice(GetEqualityIndex(pvUpdate, chosenDeletePilotValve), 1);
    let acUpdate: IApplianceController[] = [...applianceConfigTable];
    acUpdate[parseInt(chosenAppliance)].pilotvalvetable = pvUpdate;
    onChange(acUpdate, "applianceconfigtable");

    setDeletePilotValveCBText(
      `${chosenDeletePilotValve!.pilotvalvetag} successfully deleted`
    );
    save();

    setDeletePilotValveCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete PilotValve
  function completeDeletePilotValve(e: any) {
    setDeletePilotValveCBActive(false);
    setDeletePilotValveCBText("");
    setDeletePilotValveCBFinalDisplay(false);
    setChosenDeletePilotValve(undefined);
  }

  return appliances.length === 0 ? (
    <StyledMessageDiv data-testname={"add-controller-error-message"}>
      Please add a controller in "Appliance Details" to access the Appliance
      Details sub-pages
    </StyledMessageDiv>
  ) : (
    <StyledMainDiv>
      <Dropdown
        dataTestname={"appliance-details-pilot-valve-appliance-dropdown"}
        columns={appliances}
        labelText={"Appliance"}
        onchange={updateChosenAppliance}
        selected={chosenAppliance}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-valve-add-pilot-valve-confirmation-box"
        heading={"Information"}
        message={addPilotValveCBText}
        active={addPilotValveCBActive}
        onOk={completeAddPilotValve}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-valve-edit-pilot-valve-confirmation-box"
        heading={"Information"}
        message={editPilotValveCBText}
        active={editPilotValveCBActive}
        onOk={completeEditPilotValve}
        onYes={handleEditPilotValve}
        onCancel={hideEditPilotValveConfirmationBox}
        displayCancel={true}
        finalDisplay={editPilotValveCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-pilot-valve-delete-pilot-valve-confirmation-box"
        heading={"Information"}
        message={deletePilotValveCBText}
        active={deletePilotValveCBActive}
        onOk={completeDeletePilotValve}
        onYes={handleDeletePilotValve}
        onCancel={hideDeletePilotValveConfirmationBox}
        displayCancel={true}
        finalDisplay={deletePilotValveCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-pilot-valve-common-customizable-table"
        usageIdentifier="appliance-details-pilot-card"
        data={applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-pilot-valve-add-button"}
          onClickFunction={handleShowAddPilotValve}
          text={"Add Pilot Valve"}
          size={4}
        />
        {showAddPilotValve && (
          <AddPilotValve
            active={showAddPilotValve}
            onCancel={handleShowAddPilotValve}
            onAdd={handleAddPilotValve}
            retainedData={retainedNewPilotValve}
            existingPilotValve={
              applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable
            }
          />
        )}
      </StyledAddButtonDiv>
      {showEditPilotValve && (
        <EditPilotValve
          active={showEditPilotValve}
          pilotValveInfo={chosenEditPilotValve!}
          onCancel={handleShowEditPilotValve}
          onEdit={showEditPilotValveConfirmationBox}
          retainedData={retainedEditPilotValve}
          existingPilotValve={
            applianceConfigTable[parseInt(chosenAppliance)].pilotvalvetable
          }
        />
      )}
    </StyledMainDiv>
  );
};

export default ADPilotValve;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
