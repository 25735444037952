import axios from "axios";
import { IEWPAutomationForm } from "../../../types/tools";

export const getEWPForms = async (user_id: string) => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/all`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};

export const getEWPFormByID = async (user_id: string, form_id: number) => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/data/control_narrative/latest/${form_id}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};

export const addNewEWPForm = async (user_id: string, newEWPForm: IEWPAutomationForm) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/create`,
      newEWPForm,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
        }
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const updateEWPForm = async (user_id: string, form_id: number, updatedEWPForm: IEWPAutomationForm) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/update/${form_id}`,
      updatedEWPForm,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
        }
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const toggleActiveEWPForm = async (user_id: string, form_id: number) => {
  try {
    const result = await axios.put(
      `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/toggle_status/${form_id}`, undefined,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
        }
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const deleteEWPForm = async (user_id: string, form_id: number) => {
  try {
    const result = await axios.delete(
      `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/delete/${form_id}?user_id=${user_id}`,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
        }
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const submitGenerateEWPForm = async (user_id: string, form_id: number) => {
  try {
    const result = await axios.post(
      `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/submit_generateEWP/${form_id}`, undefined,
      {
        headers: {
          "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
        }
      }
    );
    return result;
  } catch (error: any) {
    return error.response;
  }
};

export const validateEWPFormByID = async (user_id: string, form_id: number) => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/validate_form_data/${form_id}`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};

export const getActiveEWPForms = async (user_id: string) => {
  try {
    const result = await axios.get(
        `${process.env.REACT_APP_EWP_BACKEND_URL}/forms/${user_id}/active/all`,
        {
          headers: {
            "x-api-key": `${process.env.REACT_APP_EWP_API_KEY}`,
          }
        }
      )
      return result;
  } catch (error: any) {
    return error.response;
  }
};