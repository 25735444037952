import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm } from "../../types/tools";
import CommonInput from "../common/formInputs/CommonInput";
import CommonRadioButtonGroup from "../common/formInputs/ProfireRadioButtonGroup";
import { safeSetInt } from "../../utilities/conversionFunctions";
import { validateNumberString } from "../../utilities/validationFunctions";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
}

const PilotConfiguration: React.FC<SectionProps> = ({ onChange, form }) => {
  const [pilotRelightMode, setPilotRelightMode] = useState<string | null>(
    form.pilot_relight_mode_set_to_during_flame_fail
      ? "Set to During Flame Fail"
      : form.pilot_relight_mode_set_to_during_timeout
      ? "Set to During Timeout"
      : "Disabled"
  );
  const [pilotRelightModeErrors, setPilotRelightModeErrors] =
    useState<string>("");
  const handlePilotRelightMode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPilotRelightMode(value);
    setPilotRelightModeErrors("");

    if (value === "Set to During Flame Fail") {
      onChange(true, "pilot_relight_mode_set_to_during_flame_fail");
      onChange(false, "pilot_relight_mode_set_to_during_timeout");
    } else if (value === "Set to During Timeout") {
      onChange(false, "pilot_relight_mode_set_to_during_flame_fail");
      onChange(true, "pilot_relight_mode_set_to_during_timeout");
    } else {
      onChange(false, "pilot_relight_mode_set_to_during_flame_fail");
      onChange(false, "pilot_relight_mode_set_to_during_timeout");
    }
  };

  const [pilotFlameFail, setPilotFlameFail] = useState<string | null>(
    form.pilot_flame_fail_response_time_setting
  );
  const [pilotFlameFailErrors, setPilotFlameFailErrors] = useState<string>("");
  const handlePilotFlameFail = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setPilotFlameFailErrors("Only whole numbers are accepted.");
    } else {
      setPilotFlameFail(value === "" ? null : value);
      setPilotFlameFailErrors("");

      onChange(value, "pilot_flame_fail_response_time_setting");
    }
  };

  const [mainFlameFail, setMainFlameFail] = useState<string | null>(
    form.main_flame_fail_response_time_setting
  );
  const [mainFlameFailErrors, setMainFlameFailErrors] = useState<string>("");
  const handleMainFlameFail = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setMainFlameFailErrors("Only whole numbers are accepted.");
    } else {
      setMainFlameFail(value === "" ? null : value);
      setMainFlameFailErrors("");

      onChange(value, "main_flame_fail_response_time_setting");
    }
  };

  const [pilotRelightTimeout, setPilotRelightTimeout] = useState<string | null>(
    form.pilot_relight_timeout_setting
  );
  const [pilotRelightTimeoutErrors, setPilotRelightTimeoutErrors] =
    useState<string>("");
  const handlePilotRelightTimeout = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setPilotRelightTimeoutErrors("Only whole numbers are accepted.");
    } else {
      setPilotRelightTimeout(value === "" ? null : value);
      setPilotRelightTimeoutErrors("");

      onChange(value, "pilot_relight_timeout_setting");
    }
  };

  const [relightAttempts, setRelightAttempts] = useState<number | null>(
    form.relight_attempts_setting
  );
  const [relightAttemptsErrors, setRelightAttemptsErrors] =
    useState<string>("");
  const handleRelightAttempts = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setRelightAttemptsErrors("Only whole numbers are accepted.");
    } else {
      setRelightAttempts(safeSetInt(value));
      setRelightAttemptsErrors("");

      onChange(safeSetInt(value), "relight_attempts_setting");
    }
  };

  const [minimumPilotsRunning, setMinimumPilotsRunning] = useState<number>(
    form.minimum_pilots_running_setting
  );
  const [minimumPilotsRunningErrors, setMinimumPilotsRunningErrors] =
    useState<string>("");
  const handleMinimumPilotsRunning = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setMinimumPilotsRunningErrors("Only whole numbers are accepted.");
    } else {
      setMinimumPilotsRunning(safeSetInt(value));
      setMinimumPilotsRunningErrors("");

      onChange(safeSetInt(value), "minimum_pilots_running_setting");
    }
  };

  return (
    <StyledMainDiv>
      <CommonRadioButtonGroup
        dataTestname={"pilot-configuration-pilot-relight-radio-button-group"}
        legendText="Please Select Pilot Relight Mode Setting"
        options={[
          "Set to During Flame Fail",
          "Set to During Timeout",
          "Disabled",
        ]}
        onChange={handlePilotRelightMode}
        identifier={"P"}
        selected={pilotRelightMode === null ? "" : pilotRelightMode}
        errors={pilotRelightModeErrors}
      />
      <CommonInput
        dataTestname={"pilot-configuration-pilot-flame-common-input"}
        type={"text"}
        labelText="Pilot Flame Fail Response Time Setting (in seconds)"
        value={pilotFlameFail === null ? "" : pilotFlameFail.toString()}
        onChange={handlePilotFlameFail}
        errors={pilotFlameFailErrors}
      />
      <CommonInput
        dataTestname={"pilot-configuration-main-flame-common-input"}
        type={"text"}
        labelText="Main Flame Fail Response Time Setting (in seconds)"
        value={mainFlameFail === null ? "" : mainFlameFail.toString()}
        onChange={handleMainFlameFail}
        errors={mainFlameFailErrors}
      />
      <CommonInput
        dataTestname={"pilot-configuration-pilot-relight-timeout-common-input"}
        type={"text"}
        labelText="Pilot Relight Timeout Setting (in seconds)"
        value={
          pilotRelightTimeout === null ? "" : pilotRelightTimeout.toString()
        }
        onChange={handlePilotRelightTimeout}
        errors={pilotRelightTimeoutErrors}
      />
      <CommonInput
        dataTestname={"pilot-configuration-relight-attempts-common-input"}
        type={"text"}
        labelText="Relight Attempts Setting"
        value={relightAttempts === null ? "" : relightAttempts.toString()}
        onChange={handleRelightAttempts}
        errors={relightAttemptsErrors}
      />
      <CommonInput
        dataTestname={"pilot-configuration-minimum-pilots-common-input"}
        type={"text"}
        labelText="Minimum Pilots Running Setting"
        value={
          minimumPilotsRunning === null ? "" : minimumPilotsRunning.toString()
        }
        onChange={handleMinimumPilotsRunning}
        errors={minimumPilotsRunningErrors}
      />
    </StyledMainDiv>
  );
};

export default PilotConfiguration;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
