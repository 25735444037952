import React from "react";
import styled from "styled-components";

// Checkbox Instructions
// 1. Each Checkbox only handles a single piece of data. If you have an array of data that you need checkboxes for, map through the array and use the Checkbox component inside of it
// 2. Carefully read below to understand what each of the props do:
//  - isChecked: Determines whether the Checkbox has the Checkmark in it or not. This will be where you put your boolean property.
//  - handleChange: The function to pass in that will handle the toggling of data related to the Checkbox.
//  - label: This is a basic label that goes to the right of the Checkbox and can also be clicked to toggle the Checkbox.
//  - arrayLetter: This is a single letter to help make 2 or more arrays with the same property names "unique".
//    For example, the Live and Historical data on the Control Unit page both share essentially the same data, but from different sources.
//    If we do not add arrayLetter of "L" to Live and "H" to Historic, then we run into an issue where the Historic array of checkboxes will always count as clicking the Live Checkboxes
//    This also being said, you will need to remove the last letter of the id in the handleChange function to compensate for arrayLetter being appended.
// 3. Please do not touch the styling in this file. If you *do* need to change styling, please talk to TJ.
// 4. Currently, there isn't an option to set left/right text without affecting other pages. If this is necessary, please talk to TJ.
// 5. If there are any issues, please talk to TJ.

interface CheckboxProps {
  dataTestname: string;
  isChecked: boolean;
  handleChange: Function;
  label: string;
  arrayLetter: string;
  alignment?: string;
  readOnly?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  dataTestname,
  isChecked,
  handleChange,
  label,
  arrayLetter,
  alignment,
  readOnly = false,
}) => {
  return (
    <StyledCheckboxDiv
      data-testname={dataTestname}
      className={
        alignment === "right"
          ? readOnly
            ? "checkbox-container-right-aligned-disabled"
            : "checkbox-container-right-aligned"
          : readOnly
          ? "checkbox-container-disabled"
          : "checkbox-container"
      }
    >
      <StyledLabel data-testid="checkbox-label" htmlFor={label + arrayLetter}>
        {label}
        <StyledCheckbox
          data-testid="checkbox-input"
          type="checkbox"
          id={label + arrayLetter}
          checked={isChecked}
          onChange={(e) => handleChange(e, arrayLetter)}
        />
        <span
          className={
            alignment === "right"
              ? readOnly
                ? "checkmark-right-aligned-disabled"
                : "checkmark-right-aligned"
              : readOnly
              ? "checkmark-disabled"
              : "checkmark"
          }
        ></span>
      </StyledLabel>
    </StyledCheckboxDiv>
  );
};
export default Checkbox;

const StyledCheckboxDiv = styled.div`
  float: left;
  text-align: left;
  display: flex;
  flex-direction: row;
  width: Calc(50% - 41px);

  :after {
    clear: left;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(50% - 41px);
  }

  @media (min-width: ${(props) => props.theme.largeMinBreakpoint}) {
    width: Calc(50% - 70px);
  }
`;

const StyledCheckbox = styled.input`
  cursor: pointer !important;
`;

const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  padding-right: 10px;
`;
