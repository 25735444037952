export const safeSetInt = (stringToConvert: string) => {
  if (stringToConvert === null) {
    return 0;
  }

  let intToReturn: number = 0;

  intToReturn = Number.isNaN(parseInt(stringToConvert)) ? 0 : parseInt(stringToConvert)

  return intToReturn;
};
