import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IIOExpansionInputs } from "../../../types/tools";
import Dropdown from "../../common/formInputs/Dropdown";
import {
  ioModeDropdown,
  ioSignalDropdown,
  ioTypeDropdown,
} from "../../../utilities/staticObjects";

interface EditIOExpansionInputsProps {
  active: boolean;
  ioExpansionInputs: IIOExpansionInputs;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IIOExpansionInputs | undefined;
  existingIOExpansionInputs: IIOExpansionInputs[];
}

const EditIOExpansionInputs: React.FC<EditIOExpansionInputsProps> = ({
  active,
  ioExpansionInputs,
  onCancel,
  onEdit,
  retainedData,
  existingIOExpansionInputs,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [ioTag, setIOTag] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iotag === undefined
        ? ""
        : ioExpansionInputs.iotag!
      : retainedData.iotag!
  );
  const [ioTagErrors, setIOTagErrors] = useState<string>("");
  const handleIOTag = (e: React.FormEvent<HTMLInputElement>) => {
    setIOTag(e.currentTarget.value);
    setIOTagErrors("");
  };

  const [ioDescription, setIODescription] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iodesc === undefined
        ? ""
        : ioExpansionInputs.iodesc!
      : retainedData.iodesc!
  );
  const [ioDescriptionErrors, setIODescriptionErrors] = useState<string>("");
  const handleIODescription = (e: React.FormEvent<HTMLInputElement>) => {
    setIODescription(e.currentTarget.value);
    setIODescriptionErrors("");
  };

  const [ioType, setIOType] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iotype === undefined
        ? ioTypeDropdown[0][1]
        : ioExpansionInputs.iotype!
      : retainedData.iotype!
  );
  const handleIOType = (newChosen: string) => {
    setIOType(newChosen);
  };

  const [ioSignal, setIOSignal] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iosignal === undefined
        ? ioSignalDropdown[0][1]
        : ioExpansionInputs.iosignal!
      : retainedData.iosignal!
  );
  const handleIOSignal = (newChosen: string) => {
    setIOSignal(newChosen);
  };

  const [ioMode, setIOMode] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iomode === undefined
        ? ioModeDropdown[0][1]
        : ioExpansionInputs.iomode!
      : retainedData.iomode!
  );
  const handleIOMode = (newChosen: string) => {
    setIOMode(newChosen);
  };

  const [ioUnits, setIOUnits] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iounits === undefined
        ? ""
        : ioExpansionInputs.iounits!
      : retainedData.iounits!
  );
  const [ioUnitsErrors, setIOUnitsErrors] = useState<string>("");
  const handleIOUnits = (e: React.FormEvent<HTMLInputElement>) => {
    setIOUnits(e.currentTarget.value);
    setIOUnitsErrors("");
  };

  const [ioLowSP, setIOLowSP] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iolowsp === undefined
        ? ""
        : ioExpansionInputs.iolowsp!
      : retainedData.iolowsp!
  );
  const [ioLowSPErrors, setIOLowSPErrors] = useState<string>("");
  const handleIOLowSP = (e: React.FormEvent<HTMLInputElement>) => {
    setIOLowSP(e.currentTarget.value);
    setIOLowSPErrors("");
  };

  const [ioHighSP, setIOHighSP] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iohighsp === undefined
        ? ""
        : ioExpansionInputs.iohighsp!
      : retainedData.iohighsp!
  );
  const [ioHighSPErrors, setIOHighSPErrors] = useState<string>("");
  const handleIOHighSP = (e: React.FormEvent<HTMLInputElement>) => {
    setIOHighSP(e.currentTarget.value);
    setIOHighSPErrors("");
  };

  const [ioComment, setIOComment] = useState<string>(
    retainedData === undefined
      ? ioExpansionInputs.iocomment === undefined
        ? ""
        : ioExpansionInputs.iocomment!
      : retainedData.iocomment!
  );
  const [ioCommentErrors, setIOCommentErrors] = useState<string>("");
  const handleIOComment = (e: React.FormEvent<HTMLInputElement>) => {
    setIOComment(e.currentTarget.value);
    setIOCommentErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setIOTagErrors("");
    setIODescriptionErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!ioTag) {
      setIOTagErrors("IO Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      ioExpansionInputs.iotag !== ioTag &&
      existingIOExpansionInputs.find((io) => io.iotag === ioTag) !== undefined
    ) {
      setIOTagErrors("IO Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const updatedIOExpansionInputs: IIOExpansionInputs = {
        iotag: ioTag,
        iodesc: ioDescription,
        iotype: ioType,
        iosignal: ioSignal,
        iomode: ioMode,
        iounits: ioUnits,
        iolowsp: ioLowSP,
        iohighsp: ioHighSP,
        iocomment: ioComment,
      };

      onEdit(updatedIOExpansionInputs);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-io-expansion-inputs-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit IO Expansion Inputs"
      maxHeight={"50%"}
    >
      <StyledEditIOExpansionInputsDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-tag-common-input"
                type={"text"}
                labelText={"IO Tag"}
                required={true}
                readOnly={false}
                value={ioTag}
                onChange={handleIOTag}
                maxLength={50}
                placeholder={""}
                errors={ioTagErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-description-common-input"
                type={"text"}
                labelText={"IO Description"}
                required={false}
                readOnly={false}
                value={ioDescription}
                onChange={handleIODescription}
                maxLength={50}
                placeholder={""}
                errors={ioDescriptionErrors}
                autoFocus={false}
              />
              <Dropdown
                dataTestname={"add-io-expansion-inputs-io-type-dropdown"}
                columns={ioTypeDropdown}
                labelText={"IO Type"}
                onchange={handleIOType}
                selected={ioType}
              />
              <Dropdown
                dataTestname={"add-io-expansion-inputs-io-signal-dropdown"}
                columns={ioSignalDropdown}
                labelText={"IO Signal"}
                onchange={handleIOSignal}
                selected={ioSignal}
              />
              <Dropdown
                dataTestname={"add-io-expansion-inputs-io-mode-dropdown"}
                columns={ioModeDropdown}
                labelText={"IO Mode"}
                onchange={handleIOMode}
                selected={ioMode}
              />
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-units-common-input"
                type={"text"}
                labelText={"IO Units"}
                required={false}
                readOnly={false}
                value={ioUnits}
                onChange={handleIOUnits}
                maxLength={50}
                placeholder={""}
                errors={ioUnitsErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-low-sp-common-input"
                type={"text"}
                labelText={"IO Low SP"}
                required={false}
                readOnly={false}
                value={ioLowSP}
                onChange={handleIOLowSP}
                maxLength={50}
                placeholder={""}
                errors={ioLowSPErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-high-sp-common-input"
                type={"text"}
                labelText={"IO High SP"}
                required={false}
                readOnly={false}
                value={ioHighSP}
                onChange={handleIOHighSP}
                maxLength={50}
                placeholder={""}
                errors={ioHighSPErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-io-expansion-inputs-io-comment-common-input"
                type={"text"}
                labelText={"IO Comment"}
                required={false}
                readOnly={false}
                value={ioComment}
                onChange={handleIOComment}
                maxLength={50}
                placeholder={""}
                errors={ioCommentErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-io-expansion-inputs-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-io-expansion-inputs-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditIOExpansionInputsDiv>
    </ModalBox>
  );
};

export default EditIOExpansionInputs;

const StyledEditIOExpansionInputsDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
