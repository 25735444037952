import React, { useState } from "react";
import styled from "styled-components";
import {
  IEWPAutomationForm,
  ITemperatureConfiguration,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddTemperatureConfiguration from "./temperatureConfiguration/AddTemperatureConfiguration";
import EditTemperatureConfiguration from "./temperatureConfiguration/EditTemperatureConfiguration";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const TemperatureConfiguration: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [temperatureConfigurations, setTemperatureConfigurations] = useState<
    ITemperatureConfiguration[]
  >(CopyObjectNoPointer(form.logicaltemptable));

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>(["temptag", "tempdesc", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Tag", "Description", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditTemperatureConfiguration,
    showDeleteTemperatureConfigurationConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add TemperatureConfiguration
  const [showAddTemperatureConfiguration, setShowAddTemperatureConfiguration] =
    useState<boolean>(false);
  const [
    addTemperatureConfigurationCBActive,
    setAddTemperatureConfigurationCBActive,
  ] = useState<boolean>(false);
  const [
    addTemperatureConfigurationCBText,
    setAddTemperatureConfigurationCBText,
  ] = useState<string>("");
  const [
    retainedNewTemperatureConfiguration,
    setRetainedNewTemperatureConfiguration,
  ] = useState<ITemperatureConfiguration>();

  function handleShowAddTemperatureConfiguration() {
    if (showAddTemperatureConfiguration) {
      setRetainedNewTemperatureConfiguration(undefined);
    }
    setShowAddTemperatureConfiguration(!showAddTemperatureConfiguration);
  }

  //This function runs on clicking Save in Add TemperatureConfiguration
  function handleAddTemperatureConfiguration(e: ITemperatureConfiguration) {
    setShowAddTemperatureConfiguration(false);
    setAddTemperatureConfigurationCBActive(true);

    temperatureConfigurations.push(e);
    onChange(temperatureConfigurations, "logicaltemptable");

    setAddTemperatureConfigurationCBText(`${e.temptag} successfully added`);
    setRetainedNewTemperatureConfiguration(undefined);
    setTemperatureConfigurationAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Temperature Configuration
  function completeAddTemperatureConfiguration() {
    setAddTemperatureConfigurationCBActive(false);
    setAddTemperatureConfigurationCBText("");

    if (retainedNewTemperatureConfiguration !== undefined) {
      setShowAddTemperatureConfiguration(true);
    }
  }

  function setTemperatureConfigurationAfterAdd() {
    setTemperatureConfigurations(form.logicaltemptable);
  }

  //Edit TemperatureConfiguration
  const [
    showEditTemperatureConfiguration,
    setShowEditTemperatureConfiguration,
  ] = useState<boolean>(false);
  const [
    editTemperatureConfigurationCBActive,
    setEditTemperatureConfigurationCBActive,
  ] = useState<boolean>(false);
  const [
    editTemperatureConfigurationCBText,
    setEditTemperatureConfigurationCBText,
  ] = useState<string>("");
  const [
    editTemperatureConfigurationCBFinalDisplay,
    setEditTemperatureConfigurationCBFinalDisplay,
  ] = useState<boolean>(false);
  //retainedEditTemperatureConfiguration is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [
    retainedEditTemperatureConfiguration,
    setRetainedEditTemperatureConfiguration,
  ] = useState<ITemperatureConfiguration | undefined>();
  const [
    chosenEditTemperatureConfiguration,
    setChosenEditTemperatureConfiguration,
  ] = useState<ITemperatureConfiguration>();

  function handleShowEditTemperatureConfiguration(e: any = undefined) {
    if (showEditTemperatureConfiguration) {
      setShowEditTemperatureConfiguration(false);
      setChosenEditTemperatureConfiguration(undefined);
    } else {
      setShowEditTemperatureConfiguration(!showEditTemperatureConfiguration);
      setChosenEditTemperatureConfiguration(e);
    }
  }

  //This function runs on clicking Save in Edit TemperatureConfiguration
  function showEditTemperatureConfigurationConfirmationBox(
    e: ITemperatureConfiguration
  ) {
    setShowEditTemperatureConfiguration(false);
    setEditTemperatureConfigurationCBActive(true);
    setEditTemperatureConfigurationCBText(
      "Are you sure you want to save these changes?"
    );
    setRetainedEditTemperatureConfiguration(e);
  }

  //This function runs on clicking Cancel in Edit TemperatureConfiguration
  function hideEditTemperatureConfigurationConfirmationBox() {
    setShowEditTemperatureConfiguration(false);
    setEditTemperatureConfigurationCBActive(false);
    setEditTemperatureConfigurationCBText("");
    setRetainedEditTemperatureConfiguration(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit TemperatureConfiguration
  function handleEditTemperatureConfiguration() {
    setEditTemperatureConfigurationCBText("");

    setTemperatureConfigurations(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenEditTemperatureConfiguration
        ),
        1,
        retainedEditTemperatureConfiguration!
      )
    );
    onChange(temperatureConfigurations, "logicaltemptable");

    setEditTemperatureConfigurationCBText(
      `${retainedEditTemperatureConfiguration!.temptag} successfully updated`
    );
    setRetainedEditTemperatureConfiguration(undefined);
    setTemperatureConfigurationAfterAdd();
    save();

    setEditTemperatureConfigurationCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit TemperatureConfiguration
  function completeEditTemperatureConfiguration() {
    setEditTemperatureConfigurationCBActive(false);
    setEditTemperatureConfigurationCBText("");
    setEditTemperatureConfigurationCBFinalDisplay(false);
    setChosenEditTemperatureConfiguration(undefined);

    if (retainedEditTemperatureConfiguration !== undefined) {
      setShowEditTemperatureConfiguration(true);
    }
  }

  //Delete TemperatureConfiguration
  const [
    deleteTemperatureConfigurationCBActive,
    setDeleteTemperatureConfigurationCBActive,
  ] = useState<boolean>(false);
  const [
    deleteTemperatureConfigurationCBText,
    setDeleteTemperatureConfigurationCBText,
  ] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteTemperatureConfigurationCBFinalDisplay,
    setDeleteTemperatureConfigurationCBFinalDisplay,
  ] = useState<boolean>(false);
  const [
    chosenDeleteTemperatureConfiguration,
    setChosenDeleteTemperatureConfiguration,
  ] = useState<ITemperatureConfiguration>();

  //This function runs on clicking Save in Delete TemperatureConfiguration
  function showDeleteTemperatureConfigurationConfirmationBox(
    e: ITemperatureConfiguration
  ) {
    setChosenDeleteTemperatureConfiguration(e);
    setDeleteTemperatureConfigurationCBActive(true);
    setDeleteTemperatureConfigurationCBText(
      `Are you sure you want to delete ${e.temptag}?`
    );
  }

  //This function runs on clicking Cancel in Delete TemperatureConfiguration
  function hideDeleteTemperatureConfigurationConfirmationBox(e: any) {
    setDeleteTemperatureConfigurationCBActive(false);
    setDeleteTemperatureConfigurationCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete TemperatureConfiguration
  function handleDeleteTemperatureConfiguration() {
    setDeleteTemperatureConfigurationCBText("");

    setTemperatureConfigurations(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenDeleteTemperatureConfiguration
        ),
        1
      )
    );
    onChange(temperatureConfigurations, "logicaltemptable");

    setDeleteTemperatureConfigurationCBText(
      `${chosenDeleteTemperatureConfiguration!.temptag} successfully deleted`
    );
    setTemperatureConfigurationAfterAdd();
    save();

    setDeleteTemperatureConfigurationCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete TemperatureConfiguration
  function completeDeleteTemperatureConfiguration(e: any) {
    setDeleteTemperatureConfigurationCBActive(false);
    setDeleteTemperatureConfigurationCBText("");
    setDeleteTemperatureConfigurationCBFinalDisplay(false);
    setChosenDeleteTemperatureConfiguration(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="temperature-configuration-add-document-info-confirmation-box"
        heading={"Information"}
        message={addTemperatureConfigurationCBText}
        active={addTemperatureConfigurationCBActive}
        onOk={completeAddTemperatureConfiguration}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-edit-document-info-confirmation-box"
        heading={"Information"}
        message={editTemperatureConfigurationCBText}
        active={editTemperatureConfigurationCBActive}
        onOk={completeEditTemperatureConfiguration}
        onYes={handleEditTemperatureConfiguration}
        onCancel={hideEditTemperatureConfigurationConfirmationBox}
        displayCancel={true}
        finalDisplay={editTemperatureConfigurationCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-delete-document-info-confirmation-box"
        heading={"Information"}
        message={deleteTemperatureConfigurationCBText}
        active={deleteTemperatureConfigurationCBActive}
        onOk={completeDeleteTemperatureConfiguration}
        onYes={handleDeleteTemperatureConfiguration}
        onCancel={hideDeleteTemperatureConfigurationConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteTemperatureConfigurationCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="temperature-configuration-common-customizable-table"
        usageIdentifier="temperatureConfigurations"
        data={form.logicaltemptable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-document-info-button-div">
        <ProfireButton
          dataTestname="temperature-configuration-add-document-info-button"
          onClickFunction={handleShowAddTemperatureConfiguration}
          text="Add Temperature Configuration"
          size={7}
        />
        {showAddTemperatureConfiguration && (
          <AddTemperatureConfiguration
            active={showAddTemperatureConfiguration}
            onCancel={handleShowAddTemperatureConfiguration}
            onAdd={handleAddTemperatureConfiguration}
            retainedData={retainedNewTemperatureConfiguration}
            existingTemperatureConfiguration={form.logicaltemptable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditTemperatureConfiguration && (
        <EditTemperatureConfiguration
          active={showEditTemperatureConfiguration}
          temperatureConfiguration={chosenEditTemperatureConfiguration!}
          onCancel={handleShowEditTemperatureConfiguration}
          onEdit={showEditTemperatureConfigurationConfirmationBox}
          retainedData={retainedEditTemperatureConfiguration}
          existingTemperatureConfiguration={temperatureConfigurations}
        />
      )}
    </StyledMainDiv>
  );
};

export default TemperatureConfiguration;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
