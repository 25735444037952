import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IHighFireValve } from "../../../types/tools";

interface EditHighFireValveProps {
  active: boolean;
  highFireValveInfo: IHighFireValve;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IHighFireValve | undefined;
  existingHighFireValve: IHighFireValve[];
}

const EditHighFireValve: React.FC<EditHighFireValveProps> = ({
  active,
  highFireValveInfo,
  onCancel,
  onEdit,
  retainedData,
  existingHighFireValve,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [tag, setTag] = useState<string>(
    retainedData === undefined
      ? highFireValveInfo.hfvvalvetag === undefined
        ? ""
        : highFireValveInfo.hfvvalvetag!
      : retainedData.hfvvalvetag!
  );
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    setTag(e.currentTarget.value);
    setTagErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      highFireValveInfo.hfvvalvetag !== tag &&
      existingHighFireValve.find((valve) => valve.hfvvalvetag === tag) !==
        undefined
    ) {
      setTagErrors("Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newHighFireValve: IHighFireValve = {
        hfvvalvetag: tag,
      };

      onEdit(newHighFireValve);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-high-fire-valve-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Document Info"
      maxHeight={"50%"}
    >
      <StyledEditHighFireValveDiv>
        <StyledHighFireDiv data-testid="high-fire-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-high-fire-valve-high-fire-valve-tag"
                type={"text"}
                labelText={"High Fire Valve Tag"}
                required={true}
                readOnly={false}
                value={tag}
                onChange={handleTag}
                maxLength={50}
                placeholder={""}
                errors={tagErrors}
                autoFocus={true}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-high-fire-valve-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-high-fire-valve-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledHighFireDiv>
      </StyledEditHighFireValveDiv>
    </ModalBox>
  );
};

export default EditHighFireValve;

const StyledEditHighFireValveDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledHighFireDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
