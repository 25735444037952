import { Configuration, PublicClientApplication } from "@azure/msal-browser";

export const getToken = async (): Promise<string> => {
  const cfg: Configuration = {
    auth: {
      clientId: "api://19a2f823-f75a-4ee4-ab2f-b49db5520d55",
      authority:
        "https://login.microsoftonline.com/96ddf04f-9760-469e-aadd-244f6a047ed6",
      redirectUri: process.env.REACT_APP_PORTAL_URL,
      postLogoutRedirectUri: process.env.REACT_APP_PORTAL_URL,
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: false,
    },
  };

  const pca = new PublicClientApplication(cfg);
  const accounts = pca.getAllAccounts();
  const account = accounts[0];

  const resp = await pca.acquireTokenSilent({
    scopes: ["api://02358e42-c872-4091-9736-9f613ac55f5b/access_as_user"],
    account,
  });

  return resp.accessToken;
};
