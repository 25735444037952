import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IWebsiteDocumentInfo } from "../../../types/tools";

interface AddDocumentInfoProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IWebsiteDocumentInfo | undefined;
  existingDocumentInfo: IWebsiteDocumentInfo[];
}

const AddDocumentInfo: React.FC<AddDocumentInfoProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingDocumentInfo,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [documentNumber, setDocumentNumber] = useState<string>(
    retainedData === undefined ? "" : retainedData.docnumber!
  );
  const [documentNumberErrors, setDocumentNumberErrors] = useState<string>("");
  const handleDocumentNumber = (e: React.FormEvent<HTMLInputElement>) => {
    setDocumentNumber(e.currentTarget.value);
    setDocumentNumberErrors("");
  };

  const [documentName, setDocumentName] = useState<string>(
    retainedData === undefined ? "" : retainedData.docname!
  );
  const [documentNameErrors, setDocumentNameErrors] = useState<string>("");
  const handleDocumentName = (e: React.FormEvent<HTMLInputElement>) => {
    setDocumentName(e.currentTarget.value);
    setDocumentNameErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setDocumentNumberErrors("");
    setDocumentNameErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!documentNumber) {
      setDocumentNumberErrors("Document Number is required.\n");
      handleExistingErrors(true);
    }

    if (!documentName) {
      setDocumentNameErrors("Document Name is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingDocumentInfo.find((doc) => doc.docnumber === documentNumber) !==
      undefined
    ) {
      setDocumentNumberErrors("Document Number is already in use.\n");
      handleExistingErrors(true);
    }

    if (
      existingDocumentInfo.find((doc) => doc.docname === documentName) !==
      undefined
    ) {
      setDocumentNameErrors("Document Name is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newDocumentInfo: IWebsiteDocumentInfo = {
        docnumber: documentNumber,
        docname: documentName,
      };

      onAdd(newDocumentInfo);
    }
  }
  return (
    <ModalBox
      dataTestname="add-document-info-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Document Info"
      maxHeight={"50%"}
    >
      <StyledAddDocumentInfoDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-document-info-document-number-common-input"
                type={"text"}
                labelText={"Document Number"}
                required={true}
                readOnly={false}
                value={documentNumber}
                onChange={handleDocumentNumber}
                maxLength={50}
                placeholder={""}
                errors={documentNumberErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="add-document-info-document-name-common-input"
                type={"text"}
                labelText={"Document Name"}
                required={true}
                readOnly={false}
                value={documentName}
                onChange={handleDocumentName}
                maxLength={50}
                placeholder={""}
                errors={documentNameErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-document-info-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-document-info-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddDocumentInfoDiv>
    </ModalBox>
  );
};

export default AddDocumentInfo;

const StyledAddDocumentInfoDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
