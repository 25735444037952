import React from "react";
import styled from "styled-components";

interface CommonInputProps {
  dataTestname: string;
  type: string;
  value: string;
  step?: string;
  onChange?: Function;
  labelText?: string;
  subLabelText?: string;
  errors?: string;
  required?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  pattern?: string;
  minLength?: number;
  maxLength?: number;
  min?: number;
  max?: number;
  hideErrorsDiv?: boolean;
  height?: string;
  textColor?: string;
  border?: string;
  borderRadius?: string;
  tabIndex?: number;
  autoFocus?: boolean;
}

const CommonInput: React.FC<CommonInputProps> = ({
  dataTestname,
  type,
  value,
  step,
  onChange,
  labelText,
  subLabelText,
  errors,
  required,
  readOnly,
  placeholder,
  pattern,
  minLength,
  maxLength,
  min,
  max,
  hideErrorsDiv,
  height,
  textColor,
  border,
  borderRadius,
  tabIndex,
  autoFocus = false,
}) => {
  return (
    <StyledInputDiv data-testname={dataTestname}>
      {labelText ? (
        required ? (
          <>
            <StyledInputLabel
              data-testid="input-label"
              subLabelExists={subLabelText === undefined ? false : true}
            >
              {labelText}
              <StyledAsteriskSpan>&nbsp;*</StyledAsteriskSpan>
            </StyledInputLabel>
            <StyledInputSubLabel>{subLabelText}</StyledInputSubLabel>
          </>
        ) : (
          <>
            <StyledInputLabel
              data-testid="input-label"
              subLabelExists={subLabelText === undefined ? false : true}
            >
              {labelText}
            </StyledInputLabel>
            <StyledInputSubLabel>{subLabelText}</StyledInputSubLabel>
          </>
        )
      ) : (
        <></>
      )}
      <StyledInput
        data-testid="input-area"
        type={type}
        placeholder={placeholder ? placeholder : ""}
        readOnly={readOnly ? readOnly : false}
        value={value}
        step={step}
        onChange={onChange && ((e) => onChange!(e))}
        pattern={pattern}
        minLength={minLength}
        maxLength={maxLength}
        min={min}
        max={max}
        height={height}
        textColor={textColor!}
        border={border!}
        borderRadius={borderRadius!}
        tabIndex={tabIndex ? tabIndex : undefined}
        autoFocus={autoFocus}
      />
      {!hideErrorsDiv ? (
        <StyledErrorMessage
          data-testid="input-errors"
          errorExists={errors !== undefined && errors !== ""}
        >
          {errors}
        </StyledErrorMessage>
      ) : (
        <></>
      )}
    </StyledInputDiv>
  );
};

export default CommonInput;

const StyledInputDiv = styled.div`
  width: 100%;
  text-align: left;

  input[type="date"] {
    display: block;
    -webkit-appearance: none;
  }
`;

interface InputProps {
  readOnly: boolean;
  textColor: string;
  border: string;
  borderRadius: string;
}

interface LabelProps {
  subLabelExists: boolean;
}
const StyledInputLabel = styled.label<LabelProps>`
  width: 100%;
  display: flex;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  margin-bottom: ${(props) => (props.subLabelExists ? "0px" : "6px")};
`;

const StyledInputSubLabel = styled.label`
  display: block;
  text-align: left;
  font-size: ${(props) => props.theme.subLabelTextSize};
  color: ${(props) => props.theme.subLabelTextColor};
  margin-bottom: 3px;
`;

const StyledAsteriskSpan = styled.span`
  color: #9c1b30;
`;

const StyledInput = styled.input<InputProps>`
  width: Calc(100% - 20px);
  border: 1px #e7e9e9 solid;
  border-radius: 3px;
  padding-left: 12px;
  padding-right: 6px;
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  height: ${(props) => (props.height ? props.height : "30px")};
  color: ${(props) => (props.textColor ? props.textColor : "black")};
  border: ${(props) => (props.border ? props.border : "1px solid #e7e9e9")};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "0px"};
  padding-top: 0px;
  padding-bottom: 0px;

  background-color: ${(props) => (props.readOnly ? "#e2e2e2" : "")};
  :focus,
  :active {
    box-shadow: none;
    outline: none;
    border: 1px solid #9c1b30;
  }
  ::-webkit-clear-button {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 23px);
    padding-left: 15px;
    height: ${(props) => (props.height ? props.height : "40px")};
  }
`;

interface ErrorProps {
  errorExists: boolean;
}

const StyledErrorMessage = styled.div<ErrorProps>`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};
  padding-bottom: 10px;

  width: 100%;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding-bottom: ${(props) => (props.errorExists ? "10px" : "20px")};
  }
`;
