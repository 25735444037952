import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IMainValve } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";
import CommonRadioButtonGroup from "../../common/formInputs/ProfireRadioButtonGroup";

interface AddMainValveProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IMainValve | undefined;
  existingMainValve: IMainValve[];
}

const AddMainValve: React.FC<AddMainValveProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingMainValve,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [tag, setTag] = useState<string>(
    retainedData === undefined ? "" : retainedData.mainvalvetag!
  );
  const [tagErrors, setTagErrors] = useState<string>("");
  const handleTag = (e: React.FormEvent<HTMLInputElement>) => {
    setTag(e.currentTarget.value);
    setTagErrors("");
  };

  const [incineratorGasValveSetting, setIncineratorGasValveSetting] = useState<
    string | null
  >(
    retainedData === undefined
      ? ""
      : retainedData.is_incinerator_waste_gas_valve === true
      ? "Is Incinerator Waste Gas Valve"
      : retainedData.is_incinerator_assist_gas_valve === true
      ? "Is Incinerator Assist Gas Valve"
      : ""
  );
  const [
    incineratorGasValveSettingErrors,
    setIncineratorGasValveSettingErrors,
  ] = useState<string>("");
  const handleIncineratorGasValve = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = e.target;
    setIncineratorGasValveSetting(value);
    setIncineratorGasValveSettingErrors("");

    if (value === "Is Incinerator Waste Gas Valve") {
      setIncineratorWaste(true);
      setIncineratorAssist(false);
    } else if (value === "Is Incinerator Assist Gas Valve") {
      setIncineratorWaste(false);
      setIncineratorAssist(true);
    } else {
      setIncineratorWaste(false);
      setIncineratorAssist(false);
    }
  };

  const [incineratorWaste, setIncineratorWaste] = useState<boolean>(
    retainedData === undefined
      ? false
      : retainedData.is_incinerator_waste_gas_valve!
  );

  const [incineratorAssist, setIncineratorAssist] = useState<boolean>(
    retainedData === undefined
      ? false
      : retainedData.is_incinerator_assist_gas_valve!
  );

  const [bleed, setBleed] = useState<boolean>(
    retainedData === undefined ? false : retainedData.is_bleed_valve!
  );
  const handleBleed = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setBleed(checked);
  };

  const [proofOfClosure, setProofOfClosure] = useState<boolean>(
    retainedData === undefined ? false : retainedData.proof_of_closure!
  );
  const handleProofOfClosure = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setProofOfClosure(checked);
  };

  const [pocTag, setPOCTag] = useState<string>(
    retainedData === undefined ? "" : retainedData.poctag!
  );
  const [pocTagErrors, setPOCTagErrors] = useState<string>("");
  const handlePOCTag = (e: React.FormEvent<HTMLInputElement>) => {
    setPOCTag(e.currentTarget.value);
    setPOCTagErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setTagErrors("");
    setPOCTagErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!tag) {
      setTagErrors("Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      existingMainValve.find((valve) => valve.mainvalvetag === tag) !==
      undefined
    ) {
      setTagErrors("Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const newMainValve: IMainValve = {
        mainvalvetag: tag,
        is_incinerator_waste_gas_valve: incineratorWaste,
        is_incinerator_assist_gas_valve: incineratorAssist,
        is_bleed_valve: bleed,
        proof_of_closure: proofOfClosure,
        poctag: pocTag,
      };

      onAdd(newMainValve);
    }
  }
  return (
    <ModalBox
      dataTestname="add-main-valve-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add Main Valve"
      maxHeight={"50%"}
    >
      <StyledAddMainValveDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="add-main-valve-main-valve-tag-common-input"
                type={"text"}
                labelText={"Main Valve Tag"}
                required={true}
                readOnly={false}
                value={tag}
                onChange={handleTag}
                maxLength={50}
                placeholder={""}
                errors={tagErrors}
                autoFocus={true}
              />
              <CommonRadioButtonGroup
                dataTestname={
                  "add-main-valve-incinerator-setting-radio-button-group"
                }
                legendText="Please Select Incinerator Setting"
                options={[
                  "Is Incinerator Waste Gas Valve",
                  "Is Incinerator Assist Gas Valve",
                ]}
                onChange={handleIncineratorGasValve}
                identifier={"A"}
                selected={
                  incineratorGasValveSetting === null
                    ? ""
                    : incineratorGasValveSetting
                }
                errors={incineratorGasValveSettingErrors}
              />
              <Checkbox
                dataTestname={"add-main-valve-main-valve-bleed-valve-checkbox"}
                isChecked={bleed === null ? false : bleed}
                handleChange={handleBleed}
                label={"Is Bleed Valve?"}
                arrayLetter={"A"}
              />
              <Checkbox
                dataTestname={
                  "add-main-valve-main-valve-proof-of-closure-checkbox"
                }
                isChecked={proofOfClosure === null ? false : proofOfClosure}
                handleChange={handleProofOfClosure}
                label={"Proof Of Closure?"}
                arrayLetter={"A"}
              />
              <CommonInput
                dataTestname="add-main-valve-main-valve-poc-tag-common-input"
                type={"text"}
                labelText={"POC Tag"}
                required={false}
                readOnly={false}
                value={pocTag}
                onChange={handlePOCTag}
                maxLength={50}
                placeholder={""}
                errors={pocTagErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="add-main-valve-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="add-main-valve-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddMainValveDiv>
    </ModalBox>
  );
};

export default AddMainValve;

const StyledAddMainValveDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
