export const validateTextString = (stringToValidate: string) => {
  const validText = new RegExp(`^[0-9a-zA-Z][0-9 a-zA-Z|_:,.#-/']{0,}$`);

  return validText.test(stringToValidate);
};

export const validateEmailString = (emailToValidate: string) => {
  const validEmailText = new RegExp(
    // eslint-disable-next-line no-useless-escape
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
  );

  return validEmailText.test(emailToValidate);
};

export const validatePhoneString = (phoneToValidate: string) => {
  const validPhoneText = new RegExp("^[0-9]{10}$");

  return validPhoneText.test(phoneToValidate);
};

export const validateDateString = (dateToValidate: string | Date) => {
  const validDateText = new RegExp(
    `^[0-9]{1,4}[-][0-9]{1,2}[-][0-9]{1,2}[T][0-9]{1,2}[:][0-9]{1,2}[:][0-9]{1,2}[Z]$`
  );
  const validFullDateText = new RegExp(
    `^[a-zA-Z]{3} [a-zA-Z]{3} [0-9]{1,2} [0-9]{1,4} [0-9]{1,2}[:][0-9]{1,2}[:][0-9]{1,2}`
  );
  const validDateTextWithDecimal = new RegExp(
    `^[0-9]{1,4}[-][0-9]{1,2}[-][0-9]{1,2}[T][0-9]{1,2}[:][0-9]{1,2}[:][0-9]{1,2}[.][0-9]{0,}$`
  );

  if (dateToValidate === null || dateToValidate === undefined) {
    return false;
  }
  if (dateToValidate.toString().includes(".")) {
    return validDateTextWithDecimal.test("" + dateToValidate);
  }

  if (!validDateText.test("" + dateToValidate)) {
    return validFullDateText.test("" + dateToValidate);
  }

  return validDateText.test("" + dateToValidate);
};

export const validateNumberString = (numberToValidate: string) => {
  const validNumber = new RegExp(`^[0-9]{0,}$`);
  return validNumber.test(numberToValidate);
};

//Note: This checks for whole numbers that can be negative and also can be just 0
export const validateWholeNumberString = (numberToValidate: string) => {
  const validNumber = new RegExp(`^(0|-?[1-9][0-9]*)$`);
  return validNumber.test(numberToValidate);
};

