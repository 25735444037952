import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IPilotBurner } from "../../../types/tools";

interface EditPilotBurnerProps {
  active: boolean;
  pilotBurner: IPilotBurner;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IPilotBurner | undefined;
  existingPilotBurner: IPilotBurner[];
}

const EditPilotBurner: React.FC<EditPilotBurnerProps> = ({
  active,
  pilotBurner,
  onCancel,
  onEdit,
  retainedData,
  existingPilotBurner,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [pilotBurnerElement, setPilotBurnerElement] = useState<string>(
    retainedData === undefined
      ? pilotBurner.pilotburnerelement === undefined
        ? ""
        : pilotBurner.pilotburnerelement!
      : retainedData.pilotburnerelement!
  );
  const [pilotBurnerElementErrors, setPilotBurnerElementErrors] =
    useState<string>("");
  const handlePilotBurnerElement = (e: React.FormEvent<HTMLInputElement>) => {
    setPilotBurnerElement(e.currentTarget.value);
    setPilotBurnerElementErrors("");
  };

  const [pilotIgniterElement, setPilotIgniterElement] = useState<string>(
    retainedData === undefined
      ? pilotBurner.pilotigniterelement === undefined
        ? ""
        : pilotBurner.pilotigniterelement!
      : retainedData.pilotigniterelement!
  );
  const [pilotIgniterElementErrors, setPilotIgniterElementErrors] =
    useState<string>("");
  const handlePilotIgniterElement = (e: React.FormEvent<HTMLInputElement>) => {
    setPilotIgniterElement(e.currentTarget.value);
    setPilotIgniterElementErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    if (!pilotBurnerElement) {
      handleExistingErrors(true);
      setPilotBurnerElementErrors("Burner Element is required");
    }

    if (
      pilotBurner.pilotburnerelement !== pilotBurnerElement &&
      existingPilotBurner.find(
        (burner) => burner.pilotburnerelement === pilotBurnerElement
      ) !== undefined
    ) {
      setPilotBurnerElementErrors("Burner Element is already in use.\n");
      handleExistingErrors(true);
    }

    // if no errors
    if (!existingErrors.current) {
      const updatedPilotBurner: IPilotBurner = {
        pilotburnerelement: pilotBurnerElement,
        pilotigniterelement: pilotIgniterElement,
      };

      onEdit(updatedPilotBurner);
    }

    handleExistingErrors(false);
  }
  return (
    <ModalBox
      dataTestname="edit-pilot-burner-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit Pilot Burner"
      maxHeight={"50%"}
    >
      <StyledEditPilotBurnerDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledSectionDiv>
            <CommonInput
              dataTestname="edit-pilot-burner-burner-element-common-input"
              type={"text"}
              labelText={"Burner Element"}
              required={true}
              readOnly={false}
              value={pilotBurnerElement}
              onChange={handlePilotBurnerElement}
              maxLength={50}
              placeholder={""}
              errors={pilotBurnerElementErrors}
              autoFocus={true}
            />
            <CommonInput
              dataTestname="edit-pilot-burner-igniter-element-common-input"
              type={"text"}
              labelText={"Igniter Element"}
              required={true}
              readOnly={false}
              value={pilotIgniterElement}
              onChange={handlePilotIgniterElement}
              maxLength={50}
              placeholder={""}
              errors={pilotIgniterElementErrors}
              autoFocus={false}
            />
            {existingErrors.current && (
              <StyledErrorMessage data-testid="submit-errors">
                {"Please fix above errors and resubmit."}
              </StyledErrorMessage>
            )}
            <StyledButtonRow>
              <StyledCancelButtonHolder>
                <ProfireButton
                  dataTestname="edit-pilot-burner-cancel-button"
                  text="Cancel"
                  onClickFunction={handleCancel}
                />
              </StyledCancelButtonHolder>
              <StyledSaveButtonHolder>
                <ProfireButton
                  dataTestname="edit-pilot-burner-save-button"
                  text="Save"
                  onClickFunction={onSubmit}
                  defaultButton={true}
                />
              </StyledSaveButtonHolder>
            </StyledButtonRow>
          </StyledSectionDiv>
        </StyledMainDiv>
      </StyledEditPilotBurnerDiv>
    </ModalBox>
  );
};

export default EditPilotBurner;

const StyledEditPilotBurnerDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
