import styled from "styled-components";
import BottomLogo from "../assets/images/login/Primary_White.png";
import { useMsal } from "@azure/msal-react";
import { loginRequest, loginRedirectRequest } from "../utilities/authConfig";
import { useEffect, useState } from "react";

interface LoginProps {
  setIsProfireUser: Function;
  setUser: Function;
}

const Login: React.FC<LoginProps> = ({ setIsProfireUser, setUser }) => {
  const { instance } = useMsal();

  const [width, setWidth] = useState<number>(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  const isMobile = width <= 768;

  const handleLogin = async () => {
    if (isMobile) {
      await instance
        .loginRedirect(loginRedirectRequest)
        .then((res) => {
          localStorage.setItem(
            "userId",
            instance.getActiveAccount()?.localAccountId!
          );
          if (
            instance.getActiveAccount()?.username.includes("@profireenergy.com")
          ) {
            setIsProfireUser(true);
            setUser(instance.getActiveAccount());
          } else {
            setIsProfireUser(false);
            setUser(null);
            window.location.href = "/error";
          }
        })
        .catch((e) => {
          setUser(null);
          setIsProfireUser(false);
        });
    } else {
      await instance
        .loginPopup(loginRequest)
        .then((res) => {
          localStorage.setItem("userId", res.account?.localAccountId!);
          if (res.account?.username.includes("@profireenergy.com")) {
            setIsProfireUser(true);
            setUser(res.account);
          } else {
            setIsProfireUser(false);
            setUser(null);
            window.location.href = "/error";
          }
        })
        .catch((e) => {
          setUser(null);
          setIsProfireUser(false);
        });
    }
  };

  return (
    <BackgroundContainer>
      <SignInContainer>
        <SignInDiv>
          <TitleWrapper>
            <StyledTempLogo>
              <i>
                <StyledPFSpan>PF</StyledPFSpan>
              </i>
              <i>&nbsp;&nbsp;Web Tools</i>
            </StyledTempLogo>
          </TitleWrapper>
          <ButtonWrapper>
            <SignInButton onClick={handleLogin} data-testid="login-button">
              SIGN IN
            </SignInButton>
          </ButtonWrapper>
        </SignInDiv>
        <ProfireLogoContainer>
          <ProfireLogoDiv>
            <BottomLogoImg data-testid="landing-img" />
          </ProfireLogoDiv>
        </ProfireLogoContainer>
      </SignInContainer>
    </BackgroundContainer>
  );
};

export default Login;

const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: #1a1a1a;
  min-height: 100vh;
  @media (max-width: ${(props) => props.theme.tabletMaxBreakpoint}) {
    background: none;
    background-color: #000100;
  }
`;

const SignInContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
`;

const StyledTempLogo = styled.h1`
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 51px;
  font-weight: 500;
  color: white;
  letter-spacing: -3px;
  margin: 0;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    font-size: 95px;
  }
`;

const StyledPFSpan = styled.span`
  font-weight: 900;
  display: flex;
  letter-spacing: -8px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SignInButton = styled.button`
  background-color: #9c1b30;
  font-size: ${(props) => props.theme.headingSecondarySize};
  font-weight: ${(props) => props.theme.headingSecondaryWeight};
  color: #fff;
  height: 34px;
  border: none;
  margin: 0;
  width: 279px;
  border-radius: 4px;
  justify-content: left;
  align-items: center;
  overflow: hidden;
  :hover {
    cursor: pointer;
    background-color: #b21f37;
  }
  outline: none;
  margin-top: 14px;
`;

const ProfireLogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const ProfireLogoDiv = styled.div`
  width: 142px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SignInDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: Calc(100% - 172px - 52px);
`;

const BottomLogoImg = styled.img`
  content: url(${BottomLogo});
  width: 142px;
`;
