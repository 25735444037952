import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Dropdown from "../components/common/formInputs/Dropdown";
import ProjectDetails from "../components/ewp/ProjectDetails";
import ApplianceDetails from "../components/ewp/ApplianceDetails";
import ApplicationDetails from "../components/ewp/ApplicationDetails";
import ProcessControlDetails from "../components/ewp/ProcessControlDetails";
import CardConfigurationDetails from "../components/ewp/CardConfigurationDetails";
import PilotConfiguration from "../components/ewp/PilotConfiguration";
import EngineeringDocuments from "../components/ewp/EngineeringDocuments";
import WebsiteDocuments from "../components/ewp/WebsiteDocuments";
import TemperatureConfiguration from "../components/ewp/TemperatureConfiguration";
import TemperatureAssignment from "../components/ewp/TemperatureAssignment";
import IOExpansionInputs from "../components/ewp/IOExpansionInputs";
import ProfireButton from "../components/common/buttons/ProfireButton";
import DefaultContainer from "../components/common/containers/DefaultContainer";
import { IEWPAutomationForm, IGenerateErrors, temp } from "../types/tools";
import {
  CopyObjectNoPointer,
  PropertyWrite,
} from "../utilities/propertyAccess";
import ADPilotCard from "../components/ewp/ADPilotCard";
import ADPilotValve from "../components/ewp/ADPilotValve";
import ADMainValve from "../components/ewp/ADMainValve";
import ADHighFireValve from "../components/ewp/ADHighFireValve";
import ADTCV from "../components/ewp/ADTCV";
import BMSInstrumentation from "../components/ewp/BMSInstrumentation";
import IOExpansionAssignment from "../components/ewp/IOExpansionAssignment";
import { useParams } from "react-router-dom";
import { getEWPFormByID, updateEWPForm } from "../utilities/apiCalls/ewp/forms";
import { ewpBackendErrorCodes } from "../utilities/staticObjects";
import Loading from "../components/common/modals/Loading";
import Breadcrumbs from "../components/common/navigation/Breadcrumbs";
import { IBreadcrumbItem } from "../types/breadcrumb";
import { AccountInfo } from "@azure/msal-common";
import Generate from "../components/ewp/Generate";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import { DecodeBackendResponse } from "../utilities/apiCalls/ewp/ewpValidationDecoder";

interface SingleEWPFormProps {
  user: AccountInfo;
}

const SingleEWPForm: React.FC<SingleEWPFormProps> = ({ user }) => {
  const params = useParams();

  const [section] = useState<[string, string][]>([
    ["Project Details", "Project Details"],
    ["Application Details", "Application Details"],
    ["Process Control Details", "Process Control Details"],
    ["Card Configuration Details", "Card Configuration Details"],
    ["Pilot Configuration", "Pilot Configuration"],
    ["Engineering Documents", "Engineering Documents"],
    ["Website Documents", "Website Documents"],
    ["Appliance Details", "Appliance Details"],
    ["Appliance Details: Pilot Card", "Appliance Details: Pilot Card"],
    ["Appliance Details: Pilot Valve", "Appliance Details: Pilot Valve"],
    ["Appliance Details: Main Valve", "Appliance Details: Main Valve"],
    [
      "Appliance Details: High Fire Valve",
      "Appliance Details: High Fire Valve",
    ],
    ["Appliance Details: TCV", "Appliance Details: TCV"],
    ["Temperature Configuration", "Temperature Configuration"],
    ["Temperature Assignment", "Temperature Assignment"],
    ["IO Expansion Inputs", "IO Expansion Inputs"],
    ["IO Expansion Assignment", "IO Expansion Assignment"],
    ["BMS Instrumentation", "BMS Instrumentation"],
    ["Generate", "Generate"],
  ]);

  const [displaySave] = useState<string[]>([
    "Project Details",
    "Application Details",
    "Process Control Details",
    "Card Configuration Details",
    "Pilot Configuration",
  ]);

  const crumb: IBreadcrumbItem[] = [
    {
      id: 0,
      title: "Active EWP Forms",
      url: `/profiretools/ewp`,
      prefix: "Tools",
    },
  ];

  //Section states
  const [chosenSection, setChosenSection] = useState<string>(section[0][1]);

  function updateChosenSection(newSection: string) {
    setChosenSection(newSection);
  }

  const [updateState, setUpdateState] = useState<IEWPAutomationForm>(
    CopyObjectNoPointer(temp)
  );
  const [formIDFound, setFormIDFound] = useState<boolean | null>(null);
  //changing fields/data will activate this function, updating the local state
  const handleUpdate = (newValue: any, field: string) => {
    let data: IEWPAutomationForm = updateState;

    PropertyWrite(data, field, newValue);

    setUpdateState(CopyObjectNoPointer(data));
  };

  const [saving, setSaving] = useState<boolean>(false);
  const [saveErrorsCBText, setSaveErrorsCBText] = useState<string>("");
  const [saveErrorsCBActive, setSaveErrorsCBActive] = useState<boolean>(false);

  const completeSaveErrors = () => {
    setSaveErrorsCBText("");
    setSaveErrorsCBActive(false);
  };

  //This is used to keep track of existing errors when attempting to generate
  const [generateErrors, setGenerateErrors] = useState<IGenerateErrors[]>([]);

  //changing fields/data will activate this function, updating the local state
  const handleSave = () => {
    setSaving(true);

    updateEWPForm(
      user.localAccountId!,
      parseInt(params.formId!),
      updateState
    ).then((res: any) => {
      if (res.status === 422) {
        let errors: IGenerateErrors[] = DecodeBackendResponse(res.data);
        let errorString: string = "";
        errors.forEach((error) => {
          errorString += `\n${error.field} ${error.error}`;
        });
        setSaveErrorsCBActive(true);
        setSaveErrorsCBText(
          `Encountered the following ${
            errors.length > 1 ? "errors" : "error"
          }:${errorString}`
        );
        setSaving(false);
      } else if (ewpBackendErrorCodes.includes(res.status)) {
        setSaveErrorsCBActive(true);
        setSaveErrorsCBText(
          `Encountered the following error:\n${res.data.detail}`
        );
        setSaving(false);
      } else {
        getForm();
        setSaving(false);
      }
    });
  };

  const getForm = useCallback(() => {
    getEWPFormByID(user.localAccountId!, parseInt(params.formId!)).then(
      (res: any) => {
        if (ewpBackendErrorCodes.includes(res.status)) {
          setFormIDFound(false);
        } else {
          setUpdateState(res.data);
          setFormIDFound(true);
        }
      }
    );
  }, [user, params.formId]);

  useEffect(() => {
    getForm();
  }, [getForm]);

  if (formIDFound === null) {
    return <Loading dataTestname={"single-ewp-loading"} />;
  } else if (formIDFound === false) {
    return (
      <StyledEWPFormDiv>
        <StyledMainDiv>
          <StyledMessageDiv>
            Form not found for the provided ID
          </StyledMessageDiv>
        </StyledMainDiv>
      </StyledEWPFormDiv>
    );
  } else
    return (
      <StyledEWPFormDiv>
        <StyledMainDiv>
          <ConfirmationBox
            dataTestname="single-ewp-form-errors-confirmation-box"
            heading={"Error"}
            message={saveErrorsCBText}
            active={saveErrorsCBActive}
            onOk={completeSaveErrors}
            displayCancel={false}
          />
          <Breadcrumbs
            dataTestname="single-site-breadcrumbs"
            breadcrumbs={crumb}
          />
          <DefaultContainer
            dataTestname={""}
            title={`Project Number: ${updateState.projectnumber}`}
            darkBackground={true}
          >
            <Dropdown
              dataTestname={"ewp-section-dropdown"}
              columns={section}
              labelText={"Section"}
              onchange={updateChosenSection}
              selected={chosenSection}
            />
            {chosenSection === "Project Details" ? (
              <ProjectDetails onChange={handleUpdate} form={updateState} />
            ) : chosenSection === "Application Details" ? (
              <ApplicationDetails onChange={handleUpdate} form={updateState} />
            ) : chosenSection === "Process Control Details" ? (
              <ProcessControlDetails
                onChange={handleUpdate}
                form={updateState}
              />
            ) : chosenSection === "Card Configuration Details" ? (
              <CardConfigurationDetails
                onChange={handleUpdate}
                form={updateState}
              />
            ) : chosenSection === "Pilot Configuration" ? (
              <PilotConfiguration onChange={handleUpdate} form={updateState} />
            ) : chosenSection === "Engineering Documents" ? (
              <EngineeringDocuments
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Website Documents" ? (
              <WebsiteDocuments
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details" ? (
              <ApplianceDetails
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details: Pilot Card" ? (
              <ADPilotCard
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details: Pilot Valve" ? (
              <ADPilotValve
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details: Main Valve" ? (
              <ADMainValve
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details: High Fire Valve" ? (
              <ADHighFireValve
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Appliance Details: TCV" ? (
              <ADTCV
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Temperature Configuration" ? (
              <TemperatureConfiguration
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Temperature Assignment" ? (
              <TemperatureAssignment
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "IO Expansion Inputs" ? (
              <IOExpansionInputs
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "IO Expansion Assignment" ? (
              <IOExpansionAssignment
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "BMS Instrumentation" ? (
              <BMSInstrumentation
                onChange={handleUpdate}
                form={updateState}
                save={handleSave}
                saving={saving}
              />
            ) : chosenSection === "Generate" ? (
              <Generate
                onChange={handleUpdate}
                form={updateState}
                user={user}
                generateErrors={generateErrors}
                setGenerateErrors={setGenerateErrors}
                save={handleSave}
                saving={saving}
              />
            ) : (
              <StyledMessageDiv>
                This option hasn't been implemented yet. If you believe this is
                in error, please contact TJ Blakely via email at
                tblakely@profireenergy.com
              </StyledMessageDiv>
            )}

            {displaySave.includes(chosenSection) ? (
              <StyledSaveButtonDiv>
                <ProfireButton
                  dataTestname={"ewp-save-button"}
                  text={"Save"}
                  onClickFunction={handleSave}
                  loading={saving}
                />
              </StyledSaveButtonDiv>
            ) : (
              <></>
            )}
          </DefaultContainer>
        </StyledMainDiv>
      </StyledEWPFormDiv>
    );
};

export default SingleEWPForm;

const StyledEWPFormDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 60px 16px 20px 16px;
  height: Calc(100% - 113px);
  background-color: white;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 60px 20px 20px 20px;
    width: Calc(100% - 40px);
    background-color: ${(props) => props.theme.bgColor};
  }
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSaveButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
