export const styles = {
  // ===== Colors ===== //
  bgColor: "#f6f6f6",
  containerBgColor: "#ffffff",
  disabledBG: "#ddd",
  disabledFont: "#777",
  profireRed: "#9c1b30",
  successGreen: "#00B207",

  // ===== Margins & Paddings ===== //
  generalVerticalMargin: "20px",
  generalHorizontalMargin: "30px",

  // ===== Fonts, Font Sizes, and Font Weight ===== //
  headingMainSize: "18px",
  headingMainWeight: "600",

  headingSecondarySize: "16px",
  headingSecondaryWeight: "600",

  headingMapSize: "13px",
  headingMapWeight: "700",

  contentMainSize: "14px",
  contentMainWeight: "normal",

  contentMapSize: "12px",
  contentMapWeight: "normal",

  contentErrorSize: "14px",
  contentErrorWeight: "normal",

  breadcrumbSize: "12px",
  breadcrumbWeight: "600",

  confirmationBoxMessageSize: "14px",
  confirmationBoxMessageWeight: "400",

  confirmationBoxButtonTextSize: "14px",
  confirmationBoxButtonTextWeight: "600",

  userOptionsSmallText: "12px",
  userOptionsMediumText: "14px",

  subLabelTextSize: "12px",
  subLabelTextColor: "#aaa",

  // ===== Breakpoints & Responsiveness ===== //
  tabletMaxBreakpoint: "768px",
  desktopMinBreakpoint: "769px",
};
