import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm } from "../../types/tools";
import CommonRadioButtonGroup from "../common/formInputs/ProfireRadioButtonGroup";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
}

const ApplicationDetails: React.FC<SectionProps> = ({ onChange, form }) => {
  const [applicationType, setApplicationType] = useState<string | null>(
    form.is_farc_application
      ? "FARC Application"
      : form.is_incinerator_application
      ? "Incinerator Application"
      : form.is_on_off_control_application
      ? "On Off Control Application"
      : ""
  );
  const [applicationTypeErrors, setApplicationTypeErrors] =
    useState<string>("");
  const handleApplicationType = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setApplicationType(value);
    setApplicationTypeErrors("");

    if (value === "FARC Application") {
      onChange(true, "is_farc_application");
      onChange(false, "is_incinerator_application");
      onChange(false, "is_on_off_control_application");
    } else if (value === "Incinerator Application") {
      onChange(false, "is_farc_application");
      onChange(true, "is_incinerator_application");
      onChange(false, "is_on_off_control_application");
    } else if (value === "On Off Control Application") {
      onChange(false, "is_farc_application");
      onChange(false, "is_incinerator_application");
      onChange(true, "is_on_off_control_application");
    } else {
      onChange(false, "is_farc_application");
      onChange(false, "is_incinerator_application");
      onChange(false, "is_on_off_control_application");
    }
  };

  return (
    <StyledMainDiv>
      <CommonRadioButtonGroup
        dataTestname={"application-details-radio-button-group"}
        legendText="Please Select Application Type"
        options={[
          "FARC Application",
          "Incinerator Application",
          "On Off Control Application",
        ]}
        onChange={handleApplicationType}
        identifier={"A"}
        selected={applicationType === null ? "" : applicationType}
        errors={applicationTypeErrors}
      />
    </StyledMainDiv>
  );
};

export default ApplicationDetails;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
