import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IBMSInstrumentation } from "../../../types/tools";

interface EditBMSInstrumentationProps {
  active: boolean;
  bmsInstrumentation: IBMSInstrumentation;
  onCancel?: () => void;
  onEdit: Function;
  retainedData: IBMSInstrumentation | undefined;
  existingBMSInstrumentation: IBMSInstrumentation[];
}

const EditBMSInstrumentation: React.FC<EditBMSInstrumentationProps> = ({
  active,
  bmsInstrumentation,
  onCancel,
  onEdit,
  retainedData,
  existingBMSInstrumentation,
}) => {
  const existingErrors = useRef<boolean>(false);

  const [input, setInput] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.input === undefined
        ? ""
        : bmsInstrumentation.input!
      : retainedData.input!
  );
  const [inputErrors, setInputErrors] = useState<string>("");
  const handleInput = (e: React.FormEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value);
    setInputErrors("");
  };

  const [ioTag, setIOTag] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.iotag === undefined
        ? ""
        : bmsInstrumentation.iotag!
      : retainedData.iotag!
  );
  const [ioTagErrors, setIOTagErrors] = useState<string>("");
  const handleIOTag = (e: React.FormEvent<HTMLInputElement>) => {
    setIOTag(e.currentTarget.value);
    setIOTagErrors("");
  };

  const [ioDescription, setIODescription] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.iodescription === undefined
        ? ""
        : bmsInstrumentation.iodescription!
      : retainedData.iodescription!
  );
  const [ioDescriptionErrors, setIODescriptionErrors] = useState<string>("");
  const handleIODescription = (e: React.FormEvent<HTMLInputElement>) => {
    setIODescription(e.currentTarget.value);
    setIODescriptionErrors("");
  };

  const [ioType, setIOType] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.iotype === undefined
        ? ""
        : bmsInstrumentation.iotype!
      : retainedData.iotype!
  );
  const [ioTypeErrors, setIOTypeErrors] = useState<string>("");
  const handleIOType = (e: React.FormEvent<HTMLInputElement>) => {
    setIOType(e.currentTarget.value);
    setIOTypeErrors("");
  };

  const [ioControlMode, setIOControlMode] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.iocontrolmode === undefined
        ? ""
        : bmsInstrumentation.iocontrolmode!
      : retainedData.iocontrolmode!
  );
  const [ioControlModeErrors, setIOControlModeErrors] = useState<string>("");
  const handleIOControlMode = (e: React.FormEvent<HTMLInputElement>) => {
    setIOControlMode(e.currentTarget.value);
    setIOControlModeErrors("");
  };

  const [ioComment, setIOComment] = useState<string>(
    retainedData === undefined
      ? bmsInstrumentation.iocomment === undefined
        ? ""
        : bmsInstrumentation.iocomment!
      : retainedData.iocomment!
  );
  const [ioCommentErrors, setIOCommentErrors] = useState<string>("");
  const handleIOComment = (e: React.FormEvent<HTMLInputElement>) => {
    setIOComment(e.currentTarget.value);
    setIOCommentErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  function clearErrors() {
    setIOTagErrors("");
    setIODescriptionErrors("");

    handleExistingErrors(false);
  }

  function ErrorHandler() {
    clearErrors();

    if (!input) {
      setInputErrors("Input is required.\n");
      handleExistingErrors(true);
    }

    if (!ioTag) {
      setIOTagErrors("IO Tag is required.\n");
      handleExistingErrors(true);
    }

    if (
      bmsInstrumentation.input !== input &&
      existingBMSInstrumentation.find((bms) => bms.input === input) !==
        undefined
    ) {
      setInputErrors("Input is already in use.\n");
      handleExistingErrors(true);
    }

    if (
      bmsInstrumentation.iotag !== ioTag &&
      existingBMSInstrumentation.find((bms) => bms.iotag === ioTag) !==
        undefined
    ) {
      setIOTagErrors("IO Tag is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    // if no errors
    if (!existingErrors.current) {
      const updatedBMSInstrumentation: IBMSInstrumentation = {
        input: input,
        iotag: ioTag,
        iodescription: ioDescription,
        iotype: ioType,
        iocontrolmode: ioControlMode,
        iocomment: ioComment,
      };

      onEdit(updatedBMSInstrumentation);
    }
  }
  return (
    <ModalBox
      dataTestname="edit-io-configuration-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Edit BMS Instrumentation"
      maxHeight={"50%"}
    >
      <StyledEditBMSInstrumentationDiv>
        <StyledMainDiv data-testid="main-div">
          <StyledForm onSubmit={onSubmit}>
            <StyledSectionDiv>
              <CommonInput
                dataTestname="edit-bms-instrumentation-input-common-input"
                type={"text"}
                labelText={"Input"}
                required={true}
                readOnly={false}
                value={input}
                onChange={handleInput}
                maxLength={50}
                placeholder={""}
                errors={inputErrors}
                autoFocus={true}
              />
              <CommonInput
                dataTestname="edit-bms-instrumentation-io-tag-common-input"
                type={"text"}
                labelText={"IO Tag"}
                required={true}
                readOnly={false}
                value={ioTag}
                onChange={handleIOTag}
                maxLength={50}
                placeholder={""}
                errors={ioTagErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-bms-instrumentation-io-description-common-input"
                type={"text"}
                labelText={"IO Description"}
                required={false}
                readOnly={false}
                value={ioDescription}
                onChange={handleIODescription}
                maxLength={50}
                placeholder={""}
                errors={ioDescriptionErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-bms-instrumentation-io-type-common-input"
                type={"text"}
                labelText={"IO Type"}
                required={false}
                readOnly={false}
                value={ioType}
                onChange={handleIOType}
                maxLength={50}
                placeholder={""}
                errors={ioTypeErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-bms-instrumentation-io-control-mode-common-input"
                type={"text"}
                labelText={"IO Control Mode"}
                required={false}
                readOnly={false}
                value={ioControlMode}
                onChange={handleIOControlMode}
                maxLength={50}
                placeholder={""}
                errors={ioControlModeErrors}
                autoFocus={false}
              />
              <CommonInput
                dataTestname="edit-bms-instrumentation-io-comment-common-input"
                type={"text"}
                labelText={"IO Comment"}
                required={false}
                readOnly={false}
                value={ioComment}
                onChange={handleIOComment}
                maxLength={50}
                placeholder={""}
                errors={ioCommentErrors}
                autoFocus={false}
              />
              {existingErrors.current && (
                <StyledErrorMessage data-testid="submit-errors">
                  {"Please fix above errors and resubmit."}
                </StyledErrorMessage>
              )}
              <StyledButtonRow>
                <StyledCancelButtonHolder>
                  <ProfireButton
                    dataTestname="edit-bms-instrumentation-cancel-button"
                    text="Cancel"
                    onClickFunction={handleCancel}
                  />
                </StyledCancelButtonHolder>
                <StyledSaveButtonHolder>
                  <ProfireButton
                    dataTestname="edit-bms-instrumentation-save-button"
                    text="Save"
                    onClickFunction={ErrorHandler}
                    defaultButton={true}
                  />
                </StyledSaveButtonHolder>
              </StyledButtonRow>
            </StyledSectionDiv>
          </StyledForm>
        </StyledMainDiv>
      </StyledEditBMSInstrumentationDiv>
    </ModalBox>
  );
};

export default EditBMSInstrumentation;

const StyledEditBMSInstrumentationDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledForm = styled.form``;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;
