import { handleDateDisplay } from "./timeFunctions";
import { validateDateString } from "./validationFunctions";

export const PropertyAccess = (datum: any, header: string) => {
  let current = datum;
  let accessArray = header.split(".");
  accessArray.forEach((property) => {
    current = current[property];
  });
  return current;
};

export const HeaderAccess = (header: string) => {
  let accessArray = header.split(".");

  return accessArray[accessArray.length - 1];
};

export const FormatProperty = (datum: string) => {
  return datum === null || datum === undefined
    ? "N/A"
    : validateDateString(datum)
    ? handleDateDisplay(new Date(datum.toString()))
    : datum;
};

export const PropertyWrite = (datum: any, header: string, newValue: any) => {
  let current = datum;
  let accessArray = header.split(".");
  accessArray.forEach((property) => {
    if (property === accessArray[accessArray.length - 1]) {
      current[property] = newValue
    } else {
      current = current[property];
    }
  });
  return current;
};

export const CopyObjectNoPointer = (objectToCopy: any) => {
  let returnObject = JSON.parse(
    JSON.stringify(objectToCopy)
  )

  return returnObject;
};

export const GetEqualityIndex = (objectArray: any[], compareTo: any) => {
  let foundAtIndex: number = -1;
  
  objectArray.forEach((object, index) => {
    if (foundAtIndex === -1) {
      if (CheckSingleObjectEquality(object, compareTo)) {
        foundAtIndex = index;
      }
    }
  });

  return foundAtIndex;
}

export const CheckSingleObjectEquality = (object: any, compareTo: any) => {
  const headers = Object.keys(object);
  let isEqual: boolean = true;

  headers.forEach(header => {
    if (object[header] === compareTo[header]) {
      //if equal, we don't need to do anything. continue iterating.
    } else {
      isEqual = false;
    }
  });

  return isEqual;
}