import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  IApplianceController,
  IEWPAutomationForm,
  IMainValve,
} from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import Dropdown from "../common/formInputs/Dropdown";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import EditMainValve from "./adMainValve/EditMainValve";
import AddMainValve from "./adMainValve/AddMainValve";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const ADMainValve: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [applianceConfigTable, setApplianceConfigTable] = useState<
    IApplianceController[]
  >(CopyObjectNoPointer(form.applianceconfigtable));

  let appliances: [string, string][];
  appliances = [];

  applianceConfigTable.forEach((appliance, index) => {
    appliances.push([appliance.controllername, index.toString()]);
  });

  //Section states
  const [chosenAppliance, setChosenAppliance] = useState<string>(
    appliances.length > 0 ? appliances[0][1] : ""
  );

  function updateChosenAppliance(newAppliance: string) {
    setChosenAppliance(newAppliance);
  }

  useEffect(() => {
    setApplianceConfigTable(form.applianceconfigtable);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>(["mainvalvetag", "poctag", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Tag", "POC Tag", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditMainValve,
    showDeleteMainValveConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add MainValve
  const [showAddMainValve, setShowAddMainValve] = useState<boolean>(false);
  const [addMainValveCBActive, setAddMainValveCBActive] =
    useState<boolean>(false);
  const [addMainValveCBText, setAddMainValveCBText] = useState<string>("");
  const [retainedNewMainValve, setRetainedNewMainValve] =
    useState<IMainValve>();

  function handleShowAddMainValve() {
    if (showAddMainValve) {
      setRetainedNewMainValve(undefined);
    }
    setShowAddMainValve(!showAddMainValve);
  }

  //This function runs on clicking Save in Add MainValve
  function handleAddMainValve(e: IMainValve) {
    setShowAddMainValve(false);
    setAddMainValveCBActive(true);

    let pcUpdate: IMainValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable,
    ];
    pcUpdate.push(e);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].mainvalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setAddMainValveCBText(`${e.mainvalvetag} successfully added`);
    setRetainedNewMainValve(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddMainValve() {
    setAddMainValveCBActive(false);
    setAddMainValveCBText("");

    if (retainedNewMainValve !== undefined) {
      setShowAddMainValve(true);
    }
  }

  //Edit MainValve
  const [showEditMainValve, setShowEditMainValve] = useState<boolean>(false);
  const [editMainValveCBActive, setEditMainValveCBActive] =
    useState<boolean>(false);
  const [editMainValveCBText, setEditMainValveCBText] = useState<string>("");
  const [editMainValveCBFinalDisplay, setEditMainValveCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditMainValve is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditMainValve, setRetainedEditMainValve] = useState<
    IMainValve | undefined
  >();
  const [chosenEditMainValve, setChosenEditMainValve] = useState<IMainValve>();

  function handleShowEditMainValve(e: any = undefined) {
    if (showEditMainValve) {
      setShowEditMainValve(false);
      setChosenEditMainValve(undefined);
    } else {
      setShowEditMainValve(!showEditMainValve);
      setChosenEditMainValve(e);
    }
  }

  //This function runs on clicking Save in Edit MainValve
  function showEditMainValveConfirmationBox(e: IMainValve) {
    setShowEditMainValve(false);
    setEditMainValveCBActive(true);
    setEditMainValveCBText("Are you sure you want to save these changes?");
    setRetainedEditMainValve(e);
  }

  //This function runs on clicking Cancel in Edit MainValve
  function hideEditMainValveConfirmationBox() {
    setShowEditMainValve(false);
    setEditMainValveCBActive(false);
    setEditMainValveCBText("");
    setRetainedEditMainValve(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit MainValve
  function handleEditMainValve() {
    setEditMainValveCBText("");

    let pcUpdate: IMainValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable,
    ];
    pcUpdate.splice(
      GetEqualityIndex(pcUpdate, chosenEditMainValve),
      1,
      retainedEditMainValve!
    );
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].mainvalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setEditMainValveCBText(
      `${retainedEditMainValve!.mainvalvetag} successfully updated`
    );
    setRetainedEditMainValve(undefined);
    save();

    setEditMainValveCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit MainValve
  function completeEditMainValve() {
    setEditMainValveCBActive(false);
    setEditMainValveCBText("");
    setEditMainValveCBFinalDisplay(false);
    setChosenEditMainValve(undefined);

    if (retainedEditMainValve !== undefined) {
      setShowEditMainValve(true);
    }
  }

  //Delete MainValve
  const [deleteMainValveCBActive, setDeleteMainValveCBActive] =
    useState<boolean>(false);
  const [deleteMainValveCBText, setDeleteMainValveCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteMainValveCBFinalDisplay, setDeleteMainValveCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteMainValve, setChosenDeleteMainValve] =
    useState<IMainValve>();

  //This function runs on clicking Save in Delete MainValve
  function showDeleteMainValveConfirmationBox(e: IMainValve) {
    setChosenDeleteMainValve(e);
    setDeleteMainValveCBActive(true);
    setDeleteMainValveCBText(
      `Are you sure you want to delete ${e.mainvalvetag}?`
    );
  }

  //This function runs on clicking Cancel in Delete MainValve
  function hideDeleteMainValveConfirmationBox(e: any) {
    setDeleteMainValveCBActive(false);
    setDeleteMainValveCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete MainValve
  function handleDeleteMainValve() {
    setDeleteMainValveCBText("");

    let pcUpdate: IMainValve[] = [
      ...applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable,
    ];
    pcUpdate.splice(GetEqualityIndex(pcUpdate, chosenDeleteMainValve), 1);
    let adUpdate: IApplianceController[] = [...applianceConfigTable];
    adUpdate[parseInt(chosenAppliance)].mainvalvetable = pcUpdate;
    onChange(adUpdate, "applianceconfigtable");

    setDeleteMainValveCBText(
      `${chosenDeleteMainValve!.mainvalvetag} successfully deleted`
    );
    save();

    setDeleteMainValveCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete MainValve
  function completeDeleteMainValve(e: any) {
    setDeleteMainValveCBActive(false);
    setDeleteMainValveCBText("");
    setDeleteMainValveCBFinalDisplay(false);
    setChosenDeleteMainValve(undefined);
  }

  return appliances.length === 0 ? (
    <StyledMessageDiv data-testname={"add-controller-error-message"}>
      Please add a controller in "Appliance Details" to access the Appliance
      Details sub-pages
    </StyledMessageDiv>
  ) : (
    <StyledMainDiv>
      <Dropdown
        dataTestname={"appliance-details-main-valve-appliance-dropdown"}
        columns={appliances}
        labelText={"Appliance"}
        onchange={updateChosenAppliance}
        selected={chosenAppliance}
      />
      <ConfirmationBox
        dataTestname="appliance-details-main-valve-add-main-valve-confirmation-box"
        heading={"Information"}
        message={addMainValveCBText}
        active={addMainValveCBActive}
        onOk={completeAddMainValve}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="appliance-details-main-valve-edit-main-valve-confirmation-box"
        heading={"Information"}
        message={editMainValveCBText}
        active={editMainValveCBActive}
        onOk={completeEditMainValve}
        onYes={handleEditMainValve}
        onCancel={hideEditMainValveConfirmationBox}
        displayCancel={true}
        finalDisplay={editMainValveCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="appliance-details-main-valve-delete-main-valve-confirmation-box"
        heading={"Information"}
        message={deleteMainValveCBText}
        active={deleteMainValveCBActive}
        onOk={completeDeleteMainValve}
        onYes={handleDeleteMainValve}
        onCancel={hideDeleteMainValveConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteMainValveCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="appliance-details-main-valve-common-customizable-table"
        usageIdentifier="appliance-details-main-valve"
        data={applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"appliance-details-main-valve-add-button"}
          onClickFunction={handleShowAddMainValve}
          text={"Add Main Valve"}
          size={5}
        />
        {showAddMainValve && (
          <AddMainValve
            active={showAddMainValve}
            onCancel={handleShowAddMainValve}
            onAdd={handleAddMainValve}
            retainedData={retainedNewMainValve}
            existingMainValve={
              applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable
            }
          />
        )}
      </StyledAddButtonDiv>
      {showEditMainValve && (
        <EditMainValve
          active={showEditMainValve}
          mainValveInfo={chosenEditMainValve!}
          onCancel={handleShowEditMainValve}
          onEdit={showEditMainValveConfirmationBox}
          retainedData={retainedEditMainValve}
          existingMainValve={
            applianceConfigTable[parseInt(chosenAppliance)].mainvalvetable
          }
        />
      )}
    </StyledMainDiv>
  );
};

export default ADMainValve;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
