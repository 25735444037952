import React, { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import MyToolsIcon from "../../../assets/images/navigation/profire-tools-icon.svg";
import LogoutIcon from "../../../assets/images/header/logout-arrow.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import ChosenNavigationIcon from "../../../assets/images/navigation/red-status-circle.svg";

interface NavbarProps {
  dataTestname: string;
  setAppLoading: Function;
}

const Navbar: React.FC<NavbarProps> = ({ dataTestname, setAppLoading }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const menuRef = useRef<any>(null);
  const burgerRef = useRef<any>(null);
  const [menuDisplayed, setMenuDisplayed] = useState<boolean>(false);

  //display the mobile menu and dim the background while the menu opens
  const toggleMenu = useCallback(
    (newActive: boolean) => {
      setMenuDisplayed(newActive);
    },
    [setMenuDisplayed]
  );

  const handleResize = () => {
    if (window.innerWidth >= 768) {
      toggleMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  });

  const handleOutsideOfBoxClick = useCallback(
    (e: any) => {
      if (
        menuRef.current &&
        !menuRef.current?.contains(e.target) &&
        !burgerRef.current?.contains(e.target)
      ) {
        toggleMenu(false);
      }
    },
    [toggleMenu, burgerRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideOfBoxClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideOfBoxClick);
    };
  }, [handleOutsideOfBoxClick]);

  const [selectedMenuItem, setSelectedMenuItem] = useState<string>("");

  //If you add subpages to a category, you will need to add logic here to make sure the ribbon on the navbar appears properly
  //right before the last line ": path", insert ": path === "your subpage path" ? "the navbar element to display ribbon"
  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelectedMenuItem(
      path === "users"
        ? "company"
        : path === "processunits" ||
          path === "monitorunits" ||
          path === "controlunits"
        ? "sites"
        : path
    );
  }, [location.pathname]);

  const handleMenuSelect = (e: React.MouseEvent<any>) => {
    const selectedItem: string = e.currentTarget.name;
    setSelectedMenuItem(selectedItem);
    navigate("/" + selectedItem);
  };

  const { instance, accounts } = useMsal();

  const handleLogout = async () => {
    await setAppLoading(true);
    await localStorage.clear();
    await instance.logoutPopup({
      account: accounts[0],
      mainWindowRedirectUri: "/",
      postLogoutRedirectUri: null,
    });
  };

  return (
    <StyledNavContainer
      data-testname={dataTestname}
      open={menuDisplayed}
      menuDisplayed={menuDisplayed}
    >
      <StyledBurger
        open={menuDisplayed}
        onClick={(e: React.MouseEvent<any>) => toggleMenu(!menuDisplayed)}
        menuDisplayed={menuDisplayed}
        ref={burgerRef}
      >
        <div />
        <div />
        <div />
      </StyledBurger>
      <StyledNav
        className={
          menuDisplayed ? "menu ease-in-navbar" : "menu ease-out-navbar"
        }
        open={menuDisplayed}
        menuDisplayed={menuDisplayed}
        ref={menuRef}
      >
        <StyledMenuList
          onClick={(e: React.MouseEvent<any>) => toggleMenu(false)}
        >
          <StyledMenuItem
            data-testid="profiretools"
            onClick={handleMenuSelect}
            name="profiretools"
            selectedMenuItem={selectedMenuItem}
          >
            <img src={MyToolsIcon} alt="my tools icon" />
            <p>Tools</p>
            <StyledDotDiv
              selectedMenuItem={selectedMenuItem}
              name="profiretools"
            >
              <StyledDot src={ChosenNavigationIcon} alt="status good icon" />
            </StyledDotDiv>
          </StyledMenuItem>
          <StyledMenuItem
            data-testid="logout"
            onClick={handleLogout}
            name="logout"
            selectedMenuItem={selectedMenuItem}
          >
            <img src={LogoutIcon} alt="logout icon" />
            <p>Logout</p>
            <StyledDotDiv selectedMenuItem={selectedMenuItem} name="logout">
              <StyledDot src={ChosenNavigationIcon} alt="status good icon" />
            </StyledDotDiv>
          </StyledMenuItem>
        </StyledMenuList>
        <div data-testid="location" style={{ display: "none" }}>
          {location.pathname}
        </div>
      </StyledNav>
    </StyledNavContainer>
  );
};

export default Navbar;

interface MenuNameProps {
  name: string;
  selectedMenuItem: string;
}

interface MobileProps {
  open: boolean;
  menuDisplayed: boolean;
}

const StyledNavContainer = styled.div<MobileProps>`
  background-color: ${(props) => (props.open ? "#333" : "#ccc")};
`;

const StyledNav = styled.div<MobileProps>`
  background-color: #1f1f1f;
  height: Calc(100% - 60px);
  width: 100%;
  position: fixed;
  z-index: 19;
  bottom: ${(props) => (props.menuDisplayed ? "0" : "unset")};
  top: ${(props) => (props.menuDisplayed ? "unset" : "0")};
  display: block;
`;

const StyledMenuItem = styled.a<MenuNameProps>`
  width: 100%;
  height: 53px;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
  background-color: ${(props) =>
    props.name === props.selectedMenuItem ? "#3d3d3d" : "#1f1f1f"};
  border-radius: 0px 50px 50px 0px;

  &:hover {
    background-color: #343434;
    cursor: pointer;
  }

  > img {
    width: 22px;
    height: 26px;
    margin-left: 32px;
  }

  p {
    width: 100%;
    text-align: left;
    margin-left: 25px;
    color: ${(props) =>
      props.name === props.selectedMenuItem ? "#fafafa" : "#888a8d"};

    font-size: ${(props) => props.theme.contentMainSize};
    font-weight: ${(props) => props.theme.headingSecondaryWeight};
  }
`;

const StyledMenuList = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledBurger = styled.div<MobileProps>`
  width: 1rem;
  height: 1rem;
  position: fixed;
  top: 22px;
  left: 15px;
  z-index: 21;
  display: none;
  cursor: pointer;

  @media (max-width: ${(props) => props.theme.tabletMaxBreakpoint}) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }

  div {
    width: 16px;
    height: 1px;
    background-color: ${(props) => (props.open ? "#ccc" : "#ccc")};
    border-radius: 2px;
    transform-origin: 1px;
    transition: all 0.3s linear;
  }
`;

const StyledDotDiv = styled.div<MenuNameProps>`
  display: ${(props) =>
    props.selectedMenuItem === props.name ? "flex" : "none"};
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

const StyledDot = styled.img`
  margin-right: 15px;
  width: 10px;
`;
