import React from "react";
import styled from "styled-components";

interface RadioButtonGroupProps {
  dataTestname: string;
  options: string[];
  onChange: Function;
  legendText?: string;
  errors?: string;
  readOnly?: boolean;
  hideErrorsDiv?: boolean;
  autoFocus?: boolean;
  identifier: string;
  selected: string;
}

const ProfireRadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  dataTestname,
  onChange,
  legendText,
  errors,
  readOnly,
  hideErrorsDiv,
  autoFocus = false,
  options,
  identifier,
  selected,
}) => {
  return (
    <StyledRadioButtonGroup data-testname={dataTestname}>
      {legendText ? (
        <>
          <StyledLegend data-testid="input-legend">{legendText}</StyledLegend>
        </>
      ) : (
        <></>
      )}
      {options.map((option) => (
        <StyledRadioAndLabelDiv className="radio-item" key={option}>
          <StyledRadioButton
            data-testname={dataTestname}
            data-testid="radio-button"
            readOnly={readOnly ? readOnly : false}
            value={option}
            onChange={(e) => onChange(e)}
            autoFocus={autoFocus}
            type="radio"
            name={legendText}
            id={identifier + option}
            checked={selected === option}
          />
          <StyledLabel htmlFor={identifier + option}>{option}</StyledLabel>
        </StyledRadioAndLabelDiv>
      ))}
      {!hideErrorsDiv ? (
        <StyledErrorMessage data-testid="input-errors">
          {errors}
        </StyledErrorMessage>
      ) : (
        <></>
      )}
    </StyledRadioButtonGroup>
  );
};

export default ProfireRadioButtonGroup;

const StyledRadioButtonGroup = styled.fieldset`
  width: Calc(100% - 32px);
  text-align: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

interface RadioButtonProps {
  readOnly: boolean;
}
const StyledLegend = styled.label`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  text-align: left;
  margin: 0;
`;

const StyledRadioAndLabelDiv = styled.div``;

const StyledRadioButton = styled.input<RadioButtonProps>`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  text-align: left;
  margin: 0;
  background-color: ${(props) => (props.readOnly ? "#e2e2e2" : "")};
`;

const StyledLabel = styled.label`
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
  padding-bottom: 10px;

  width: 100%;
`;
