import React, { useRef, useState } from "react";
import styled from "styled-components";
import CommonInput from "../../common/formInputs/CommonInput";
import ProfireButton from "../../common/buttons/ProfireButton";
import ModalBox from "../../common/modals/ModalBox";
import { IIOExpansionAssignment } from "../../../types/tools";
import Checkbox from "../../common/formInputs/Checkbox";
import Dropdown from "../../common/formInputs/Dropdown";
import {
  fourTwentyOutputDropdown,
  normallyOpenClosedDryContactModeDropdown,
} from "../../../utilities/staticObjects";

interface AddIOExpansionAssignmentProps {
  active: boolean;
  onCancel?: () => void;
  onAdd: Function;
  retainedData: IIOExpansionAssignment | undefined;
  existingIOExpansionAssignment: IIOExpansionAssignment[];
}

const AddIOExpansionAssignment: React.FC<AddIOExpansionAssignmentProps> = ({
  active,
  onCancel,
  onAdd,
  retainedData,
  existingIOExpansionAssignment,
}) => {
  const existingErrors = useRef<boolean>(false);
  const generalErrors = useRef<number>(0);
  const input1Errors = useRef<number>(0);
  const input2Errors = useRef<number>(0);
  const input3Errors = useRef<number>(0);
  const input4Errors = useRef<number>(0);
  const ioFourTwentyOutErrors = useRef<number>(0);
  const farcWizardChannelErrors = useRef<number>(0);
  const ioNormallyOpenClosedErrors = useRef<number>(0);

  let settings: [string, string][] = [
    ["General", "General"],
    ["Input 1", "Input 1"],
    ["Input 2", "Input 2"],
    ["Input 3", "Input 3"],
    ["Input 4", "Input 4"],
    ["IO Four Twenty Out", "IO Four Twenty Out"],
    ["FARC Wizard Channel", "FARC Wizard Channel"],
    ["IO Normally Open/Closed", "IO Normally Open/Closed"],
  ];

  //Section states
  const [chosenSetting, setChosenSetting] = useState<string>(settings[0][1]);

  function updateChosenSetting(newSetting: string) {
    setChosenSetting(newSetting);
  }

  const [name, setName] = useState<string>(
    retainedData === undefined ? "" : retainedData.name!
  );
  const [nameErrors, setNameErrors] = useState<string>("");
  const handleName = (e: React.FormEvent<HTMLInputElement>) => {
    setName(e.currentTarget.value);
    setNameErrors("");
  };

  const [mac, setMAC] = useState<string>(
    retainedData === undefined ? "" : retainedData.mac!
  );
  const [macErrors, setMACErrors] = useState<string>("");
  const handleMAC = (e: React.FormEvent<HTMLInputElement>) => {
    setMAC(e.currentTarget.value);
    setMACErrors("");
  };

  const [input1Tag, setInput1Tag] = useState<string>(
    retainedData === undefined ? "" : retainedData.input1_tag!
  );
  const [input1TagErrors, setInput1TagErrors] = useState<string>("");
  const handleInput1Tag = (e: React.FormEvent<HTMLInputElement>) => {
    setInput1Tag(e.currentTarget.value);
    setInput1TagErrors("");
  };

  const [input1Span, setInput1Span] = useState<string>(
    retainedData === undefined ? "" : retainedData.input1_span!
  );
  const [input1SpanErrors, setInput1SpanErrors] = useState<string>("");
  const handleInput1Span = (e: React.FormEvent<HTMLInputElement>) => {
    setInput1Span(e.currentTarget.value);
    setInput1SpanErrors("");
  };

  const [input1Out, setInput1Out] = useState<string>(
    retainedData === undefined ? "" : retainedData.input1_out!
  );
  const [input1OutErrors, setInput1OutErrors] = useState<string>("");
  const handleInput1Out = (e: React.FormEvent<HTMLInputElement>) => {
    setInput1Out(e.currentTarget.value);
    setInput1OutErrors("");
  };

  const [input1Dip, setInput1Dip] = useState<string>(
    retainedData === undefined ? "" : retainedData.input1_dip!
  );
  const [input1DipErrors, setInput1DipErrors] = useState<string>("");
  const handleInput1Dip = (e: React.FormEvent<HTMLInputElement>) => {
    setInput1Dip(e.currentTarget.value);
    setInput1DipErrors("");
  };

  const [input2Tag, setInput2Tag] = useState<string>(
    retainedData === undefined ? "" : retainedData.input2_tag!
  );
  const [input2TagErrors, setInput2TagErrors] = useState<string>("");
  const handleInput2Tag = (e: React.FormEvent<HTMLInputElement>) => {
    setInput2Tag(e.currentTarget.value);
    setInput2TagErrors("");
  };

  const [input2Span, setInput2Span] = useState<string>(
    retainedData === undefined ? "" : retainedData.input2_span!
  );
  const [input2SpanErrors, setInput2SpanErrors] = useState<string>("");
  const handleInput2Span = (e: React.FormEvent<HTMLInputElement>) => {
    setInput2Span(e.currentTarget.value);
    setInput2SpanErrors("");
  };

  const [input2Out, setInput2Out] = useState<string>(
    retainedData === undefined ? "" : retainedData.input2_out!
  );
  const [input2OutErrors, setInput2OutErrors] = useState<string>("");
  const handleInput2Out = (e: React.FormEvent<HTMLInputElement>) => {
    setInput2Out(e.currentTarget.value);
    setInput2OutErrors("");
  };

  const [input2Dip, setInput2Dip] = useState<string>(
    retainedData === undefined ? "" : retainedData.input2_dip!
  );
  const [input2DipErrors, setInput2DipErrors] = useState<string>("");
  const handleInput2Dip = (e: React.FormEvent<HTMLInputElement>) => {
    setInput2Dip(e.currentTarget.value);
    setInput2DipErrors("");
  };

  const [input3Tag, setInput3Tag] = useState<string>(
    retainedData === undefined ? "" : retainedData.input3_tag!
  );
  const [input3TagErrors, setInput3TagErrors] = useState<string>("");
  const handleInput3Tag = (e: React.FormEvent<HTMLInputElement>) => {
    setInput3Tag(e.currentTarget.value);
    setInput3TagErrors("");
  };

  const [input3Span, setInput3Span] = useState<string>(
    retainedData === undefined ? "" : retainedData.input3_span!
  );
  const [input3SpanErrors, setInput3SpanErrors] = useState<string>("");
  const handleInput3Span = (e: React.FormEvent<HTMLInputElement>) => {
    setInput3Span(e.currentTarget.value);
    setInput3SpanErrors("");
  };

  const [input3Out, setInput3Out] = useState<string>(
    retainedData === undefined ? "" : retainedData.input3_out!
  );
  const [input3OutErrors, setInput3OutErrors] = useState<string>("");
  const handleInput3Out = (e: React.FormEvent<HTMLInputElement>) => {
    setInput3Out(e.currentTarget.value);
    setInput3OutErrors("");
  };

  const [input3Dip, setInput3Dip] = useState<string>(
    retainedData === undefined ? "" : retainedData.input3_dip!
  );
  const [input3DipErrors, setInput3DipErrors] = useState<string>("");
  const handleInput3Dip = (e: React.FormEvent<HTMLInputElement>) => {
    setInput3Dip(e.currentTarget.value);
    setInput3DipErrors("");
  };

  const [input4Tag, setInput4Tag] = useState<string>(
    retainedData === undefined ? "" : retainedData.input4_tag!
  );
  const [input4TagErrors, setInput4TagErrors] = useState<string>("");
  const handleInput4Tag = (e: React.FormEvent<HTMLInputElement>) => {
    setInput4Tag(e.currentTarget.value);
    setInput4TagErrors("");
  };

  const [input4Span, setInput4Span] = useState<string>(
    retainedData === undefined ? "" : retainedData.input4_span!
  );
  const [input4SpanErrors, setInput4SpanErrors] = useState<string>("");
  const handleInput4Span = (e: React.FormEvent<HTMLInputElement>) => {
    setInput4Span(e.currentTarget.value);
    setInput4SpanErrors("");
  };

  const [input4Out, setInput4Out] = useState<string>(
    retainedData === undefined ? "" : retainedData.input4_out!
  );
  const [input4OutErrors, setInput4OutErrors] = useState<string>("");
  const handleInput4Out = (e: React.FormEvent<HTMLInputElement>) => {
    setInput4Out(e.currentTarget.value);
    setInput4OutErrors("");
  };

  const [input4Dip, setInput4Dip] = useState<string>(
    retainedData === undefined ? "" : retainedData.input4_dip!
  );
  const [input4DipErrors, setInput4DipErrors] = useState<string>("");
  const handleInput4Dip = (e: React.FormEvent<HTMLInputElement>) => {
    setInput4Dip(e.currentTarget.value);
    setInput4DipErrors("");
  };

  const [ioFourTwentyOutName, setIOFourTwentyOutName] = useState<string>(
    retainedData === undefined ? "" : retainedData.iofourtwentyout_name!
  );
  const [ioFourTwentyOutNameErrors, setIOFourTwentyOutNameErrors] =
    useState<string>("");
  const handleIOFourTwentyOutName = (e: React.FormEvent<HTMLInputElement>) => {
    setIOFourTwentyOutName(e.currentTarget.value);
    setIOFourTwentyOutNameErrors("");
  };

  const [ioFourTwentyOutMode, setIOFourTwentyOutMode] = useState<string>(
    retainedData === undefined
      ? fourTwentyOutputDropdown[0][1]
      : retainedData.iofourtwentyout_mode!
  );
  const handleIOFourTwentyOutMode = (newChosen: string) => {
    setIOFourTwentyOutMode(newChosen);
  };

  const [ioFourTwentyOutControlInput, setIOFourTwentyOutControlInput] =
    useState<string>(
      retainedData === undefined
        ? ""
        : retainedData.iofourtwentyout_controlinput!
    );
  const [
    ioFourTwentyOutControlInputErrors,
    setIOFourTwentyOutControlInputErrors,
  ] = useState<string>("");
  const handleIOFourTwentyOutControlInput = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setIOFourTwentyOutControlInput(e.currentTarget.value);
    setIOFourTwentyOutControlInputErrors("");
  };

  const [ioExpansionOutputIsFARCOutput, setIOExpansionOutputIsFARCOutput] =
    useState<boolean>(
      retainedData === undefined
        ? false
        : retainedData.io_expansion_output_is_farc_output!
    );
  const handleIOExpansionOutputIsFARCOutput = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setIOExpansionOutputIsFARCOutput(checked);
  };

  const [farcWizardChannelSettingName, setFARCWizardChannelSettingName] =
    useState<string>(
      retainedData === undefined
        ? ""
        : retainedData.farcwizardchannelsettingname!
    );
  const [
    farcWizardChannelSettingNameErrors,
    setFARCWizardChannelSettingNameErrors,
  ] = useState<string>("");
  const handleFARCWizardChannelSettingName = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setFARCWizardChannelSettingName(e.currentTarget.value);
    setFARCWizardChannelSettingNameErrors("");
  };

  const [
    farcWizardSettingDirectionIsSetToDirect,
    setFARCWizardSettingDirectionIsSetToDirect,
  ] = useState<boolean>(
    retainedData === undefined
      ? false
      : retainedData.farcwizard_setting_direction_is_set_to_direct!
  );
  const handleFARCWizardSettingDirectionIsSetToDirect = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setFARCWizardSettingDirectionIsSetToDirect(checked);
  };

  const [
    farcWizardSettingPostPurgeModeIsSetToPurge,
    setFARCWizardSettingPostPurgeModeIsSetToPurge,
  ] = useState<boolean>(
    retainedData === undefined
      ? false
      : retainedData.farcwizard_setting_post_purge_mode_is_set_to_purge!
  );
  const handleFARCWizardSettingPostPurgeModeIsSetToPurge = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { checked } = e.currentTarget;
    setFARCWizardSettingPostPurgeModeIsSetToPurge(checked);
  };

  const [
    farcWizardChannelSettingPurgePosition,
    setFARCWizardChannelSettingPurgePosition,
  ] = useState<string>(
    retainedData === undefined
      ? ""
      : retainedData.farcwizardchannelsettingpurgeposition!
  );
  const [
    farcWizardChannelSettingPurgePositionErrors,
    setFARCWizardChannelSettingPurgePositionErrors,
  ] = useState<string>("");
  const handleFARCWizardChannelSettingPurgePosition = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setFARCWizardChannelSettingPurgePosition(e.currentTarget.value);
    setFARCWizardChannelSettingPurgePositionErrors("");
  };

  const [
    farcWizardChannelSettingPilotPosition,
    setFARCWizardChannelSettingPilotPosition,
  ] = useState<string>(
    retainedData === undefined
      ? ""
      : retainedData.farcwizardchannelsettingpilotposition!
  );
  const [
    farcWizardChannelSettingPilotPositionErrors,
    setFARCWizardChannelSettingPilotPositionErrors,
  ] = useState<string>("");
  const handleFARCWizardChannelSettingPilotPosition = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setFARCWizardChannelSettingPilotPosition(e.currentTarget.value);
    setFARCWizardChannelSettingPilotPositionErrors("");
  };

  const [
    farcWizardChannelSettingOffPosition,
    setFARCWizardChannelSettingOffPosition,
  ] = useState<string>(
    retainedData === undefined
      ? ""
      : retainedData.farcwizardchannelsettingoffposition!
  );
  const [
    farcWizardChannelSettingOffPositionErrors,
    setFARCWizardChannelSettingOffPositionErrors,
  ] = useState<string>("");
  const handleFARCWizardChannelSettingOffPosition = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setFARCWizardChannelSettingOffPosition(e.currentTarget.value);
    setFARCWizardChannelSettingOffPositionErrors("");
  };

  const [ioNormallyClosedDryContactMode, setIONormallyClosedDryContactMode] =
    useState<string>(
      retainedData === undefined
        ? normallyOpenClosedDryContactModeDropdown[0][1]
        : retainedData.ionormallyclosed_mode!
    );
  const handleIONormallyClosedDryContactMode = (newChosen: string) => {
    setIONormallyClosedDryContactMode(newChosen);
  };

  const [ioNormallyClosedControlInput, setIONormallyClosedControlInput] =
    useState<string>(
      retainedData === undefined
        ? ""
        : retainedData.ionormallyclosed_controlinput!
    );
  const [
    ioNormallyClosedControlInputErrors,
    setIONormallyClosedControlInputErrors,
  ] = useState<string>("");
  const handleIONormallyClosedControlInput = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setIONormallyClosedControlInput(e.currentTarget.value);
    setIONormallyClosedControlInputErrors("");
  };

  const [ioNormallyOpenDryContactMode, setIONormallyOpenDryContactMode] =
    useState<string>(
      retainedData === undefined
        ? normallyOpenClosedDryContactModeDropdown[0][1]
        : retainedData.ionormallyopen_mode!
    );
  const handleIONormallyOpenDryContactMode = (newChosen: string) => {
    setIONormallyOpenDryContactMode(newChosen);
  };

  const [ioNormallyOpenControlInput, setIONormallyOpenControlInput] =
    useState<string>(
      retainedData === undefined
        ? ""
        : retainedData.ionormallyopen_controlinput!
    );
  const [
    ioNormallyOpenControlInputErrors,
    setIONormallyOpenControlInputErrors,
  ] = useState<string>("");
  const handleIONormallyOpenControlInput = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setIONormallyOpenControlInput(e.currentTarget.value);
    setIONormallyOpenControlInputErrors("");
  };

  function handleCancel() {
    existingErrors.current = false;
    onCancel!();
  }

  function handleExistingErrors(errors: boolean) {
    existingErrors.current = errors;
  }

  const [errorCheckFinished, setErrorCheckFinished] = useState<boolean>(true);

  function clearErrors() {
    setErrorCheckFinished(false);

    handleExistingErrors(false);
    generalErrors.current = 0;
    input1Errors.current = 0;
    input2Errors.current = 0;
    input3Errors.current = 0;
    input4Errors.current = 0;
    ioFourTwentyOutErrors.current = 0;
    farcWizardChannelErrors.current = 0;
    ioNormallyOpenClosedErrors.current = 0;
  }

  function ErrorHandler() {
    clearErrors();

    if (!name) {
      setNameErrors("Name is required.\n");
      handleExistingErrors(true);
      generalErrors.current++;
    }

    if (
      existingIOExpansionAssignment.find((io) => io.name === name) !== undefined
    ) {
      setNameErrors("Name is already in use.\n");
      handleExistingErrors(true);
    }
  }

  async function onSubmit(e: any) {
    e.preventDefault();
    setErrorCheckFinished(true);

    // if no errors
    if (!existingErrors.current) {
      const newIOExpansionAssignment: IIOExpansionAssignment = {
        name: name,
        mac: mac,
        input1_tag: input1Tag,
        input1_span: input1Span,
        input1_out: input1Out,
        input1_dip: input1Dip,
        input2_tag: input2Tag,
        input2_span: input2Span,
        input2_out: input2Out,
        input2_dip: input2Dip,
        input3_tag: input3Tag,
        input3_span: input3Span,
        input3_out: input3Out,
        input3_dip: input3Dip,
        input4_tag: input4Tag,
        input4_span: input4Span,
        input4_out: input4Out,
        input4_dip: input4Dip,
        iofourtwentyout_name: ioFourTwentyOutName,
        iofourtwentyout_mode: ioFourTwentyOutMode,
        iofourtwentyout_controlinput: ioFourTwentyOutControlInput,
        io_expansion_output_is_farc_output: ioExpansionOutputIsFARCOutput,
        farcwizardchannelsettingname: farcWizardChannelSettingName,
        farcwizard_setting_direction_is_set_to_direct:
          farcWizardSettingDirectionIsSetToDirect,
        farcwizard_setting_post_purge_mode_is_set_to_purge:
          farcWizardSettingPostPurgeModeIsSetToPurge,
        farcwizardchannelsettingpurgeposition:
          farcWizardChannelSettingPurgePosition,
        farcwizardchannelsettingpilotposition:
          farcWizardChannelSettingPilotPosition,
        farcwizardchannelsettingoffposition:
          farcWizardChannelSettingOffPosition,
        ionormallyclosed_mode: ioNormallyClosedDryContactMode,
        ionormallyclosed_controlinput: ioNormallyClosedControlInput,
        ionormallyopen_mode: ioNormallyOpenDryContactMode,
        ionormallyopen_controlinput: ioNormallyOpenControlInput,
      };

      onAdd(newIOExpansionAssignment);
    }
  }
  return (
    <ModalBox
      dataTestname="add-io-expansion-assignment-modal-box"
      active={active}
      desktopContainerMinWidth={"765px"}
      mobileContainerMinWidth={"320px"}
      containerTitle="Add IO Expansion Assignment"
      maxHeight={"50%"}
    >
      <StyledAddIOExpansionAssignmentDiv>
        <StyledMainDiv data-testid="main-div">
          <Dropdown
            dataTestname={"io-expansion-assignment-settings-dropdown"}
            columns={settings}
            labelText={"Settings"}
            onchange={updateChosenSetting}
            selected={chosenSetting}
          />
          <StyledForm onSubmit={onSubmit}>
            {chosenSetting === "General" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-name-common-input"
                  type={"text"}
                  labelText={"Name"}
                  required={true}
                  readOnly={false}
                  value={name}
                  onChange={handleName}
                  maxLength={50}
                  placeholder={""}
                  errors={nameErrors}
                  autoFocus={true}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-mac-common-input"
                  type={"text"}
                  labelText={"MAC"}
                  required={false}
                  readOnly={false}
                  value={mac}
                  onChange={handleMAC}
                  maxLength={50}
                  placeholder={""}
                  errors={macErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "Input 1" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-1-tag-common-input"
                  type={"text"}
                  labelText={"Input 1 Tag"}
                  required={false}
                  readOnly={false}
                  value={input1Tag}
                  onChange={handleInput1Tag}
                  maxLength={50}
                  placeholder={""}
                  errors={input1TagErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-1-span-common-input"
                  type={"text"}
                  labelText={"Input 1 Span"}
                  required={false}
                  readOnly={false}
                  value={input1Span}
                  onChange={handleInput1Span}
                  maxLength={50}
                  placeholder={""}
                  errors={input1SpanErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-1-out-common-input"
                  type={"text"}
                  labelText={"Input 1 Out"}
                  required={false}
                  readOnly={false}
                  value={input1Out}
                  onChange={handleInput1Out}
                  maxLength={50}
                  placeholder={""}
                  errors={input1OutErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-1-dip-common-input"
                  type={"text"}
                  labelText={"Input 1 Dip"}
                  required={false}
                  readOnly={false}
                  value={input1Dip}
                  onChange={handleInput1Dip}
                  maxLength={50}
                  placeholder={""}
                  errors={input1DipErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "Input 2" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-2-tag-common-input"
                  type={"text"}
                  labelText={"Input 2 Tag"}
                  required={false}
                  readOnly={false}
                  value={input2Tag}
                  onChange={handleInput2Tag}
                  maxLength={50}
                  placeholder={""}
                  errors={input2TagErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-2-span-common-input"
                  type={"text"}
                  labelText={"Input 2 Span"}
                  required={false}
                  readOnly={false}
                  value={input2Span}
                  onChange={handleInput2Span}
                  maxLength={50}
                  placeholder={""}
                  errors={input2SpanErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-2-out-common-input"
                  type={"text"}
                  labelText={"Input 2 Out"}
                  required={false}
                  readOnly={false}
                  value={input2Out}
                  onChange={handleInput2Out}
                  maxLength={50}
                  placeholder={""}
                  errors={input2OutErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-2-dip-common-input"
                  type={"text"}
                  labelText={"Input 2 Dip"}
                  required={false}
                  readOnly={false}
                  value={input2Dip}
                  onChange={handleInput2Dip}
                  maxLength={50}
                  placeholder={""}
                  errors={input2DipErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "Input 3" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-3-tag-common-input"
                  type={"text"}
                  labelText={"Input 3 Tag"}
                  required={false}
                  readOnly={false}
                  value={input3Tag}
                  onChange={handleInput3Tag}
                  maxLength={50}
                  placeholder={""}
                  errors={input3TagErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-3-span-common-input"
                  type={"text"}
                  labelText={"Input 3 Span"}
                  required={false}
                  readOnly={false}
                  value={input3Span}
                  onChange={handleInput3Span}
                  maxLength={50}
                  placeholder={""}
                  errors={input3SpanErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-3-out-common-input"
                  type={"text"}
                  labelText={"Input 3 Out"}
                  required={false}
                  readOnly={false}
                  value={input3Out}
                  onChange={handleInput3Out}
                  maxLength={50}
                  placeholder={""}
                  errors={input3OutErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-3-dip-common-input"
                  type={"text"}
                  labelText={"Input 3 Dip"}
                  required={false}
                  readOnly={false}
                  value={input3Dip}
                  onChange={handleInput3Dip}
                  maxLength={50}
                  placeholder={""}
                  errors={input3DipErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "Input 4" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-4-tag-common-input"
                  type={"text"}
                  labelText={"Input 4 Tag"}
                  required={false}
                  readOnly={false}
                  value={input4Tag}
                  onChange={handleInput4Tag}
                  maxLength={50}
                  placeholder={""}
                  errors={input4TagErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-4-span-common-input"
                  type={"text"}
                  labelText={"Input 4 Span"}
                  required={false}
                  readOnly={false}
                  value={input4Span}
                  onChange={handleInput4Span}
                  maxLength={50}
                  placeholder={""}
                  errors={input4SpanErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-4-out-common-input"
                  type={"text"}
                  labelText={"Input 4 Out"}
                  required={false}
                  readOnly={false}
                  value={input4Out}
                  onChange={handleInput4Out}
                  maxLength={50}
                  placeholder={""}
                  errors={input4OutErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-input-4-dip-common-input"
                  type={"text"}
                  labelText={"Input 4 Dip"}
                  required={false}
                  readOnly={false}
                  value={input4Dip}
                  onChange={handleInput4Dip}
                  maxLength={50}
                  placeholder={""}
                  errors={input4DipErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "IO Four Twenty Out" ? (
              <StyledSectionDiv>
                <CommonInput
                  dataTestname="add-io-expansion-assignment-io-four-twenty-out-name-common-input"
                  type={"text"}
                  labelText={"IO Four Twenty Out Name"}
                  required={false}
                  readOnly={false}
                  value={ioFourTwentyOutName}
                  onChange={handleIOFourTwentyOutName}
                  maxLength={50}
                  placeholder={""}
                  errors={ioFourTwentyOutNameErrors}
                  autoFocus={false}
                />
                <Dropdown
                  dataTestname={
                    "add-io-expansion-assignment-io-four-twenty-out-mode-dropdown"
                  }
                  columns={fourTwentyOutputDropdown}
                  labelText={"IO Four Twenty Out Mode"}
                  onchange={handleIOFourTwentyOutMode}
                  selected={ioFourTwentyOutMode}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-io-four-twenty-out-control-input-common-input"
                  type={"text"}
                  labelText={"IO Four Twenty Out Control Input"}
                  required={false}
                  readOnly={false}
                  value={ioFourTwentyOutControlInput}
                  onChange={handleIOFourTwentyOutControlInput}
                  maxLength={50}
                  placeholder={""}
                  errors={ioFourTwentyOutControlInputErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "FARC Wizard Channel" ? (
              <StyledSectionDiv>
                <Checkbox
                  dataTestname={
                    "add-io-expansion-assignment-io-expansion-output-is-farc-output-checkbox"
                  }
                  isChecked={
                    ioExpansionOutputIsFARCOutput === null
                      ? false
                      : ioExpansionOutputIsFARCOutput
                  }
                  handleChange={handleIOExpansionOutputIsFARCOutput}
                  label={"IO Expansion Output is FARC Output?"}
                  arrayLetter={"E"}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-farc-wizard-channel-setting-name-common-input"
                  type={"text"}
                  labelText={"FARC Wizard Channel Setting Name"}
                  required={false}
                  readOnly={false}
                  value={farcWizardChannelSettingName}
                  onChange={handleFARCWizardChannelSettingName}
                  maxLength={50}
                  placeholder={""}
                  errors={farcWizardChannelSettingNameErrors}
                  autoFocus={false}
                />
                <Checkbox
                  dataTestname={
                    "add-io-expansion-assignment-farc-wizard-setting-direction-is-set-to-direct-output-checkbox"
                  }
                  isChecked={
                    farcWizardSettingDirectionIsSetToDirect === null
                      ? false
                      : farcWizardSettingDirectionIsSetToDirect
                  }
                  handleChange={handleFARCWizardSettingDirectionIsSetToDirect}
                  label={"Direction is set to Direct?"}
                  arrayLetter={"E"}
                />
                <Checkbox
                  dataTestname={
                    "add-io-expansion-assignment-farc-wizard-setting-post-purge-mode-is-set-to-purge-checkbox"
                  }
                  isChecked={
                    farcWizardSettingPostPurgeModeIsSetToPurge === null
                      ? false
                      : farcWizardSettingPostPurgeModeIsSetToPurge
                  }
                  handleChange={
                    handleFARCWizardSettingPostPurgeModeIsSetToPurge
                  }
                  label={"Post Purge Mode is set to Purge?"}
                  arrayLetter={"E"}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-farc-wizard-channel-setting-purge-position-common-input"
                  type={"text"}
                  labelText={"FARC Wizard Channel Setting Purge Position"}
                  required={false}
                  readOnly={false}
                  value={farcWizardChannelSettingPurgePosition}
                  onChange={handleFARCWizardChannelSettingPurgePosition}
                  maxLength={50}
                  placeholder={""}
                  errors={farcWizardChannelSettingPurgePositionErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-farc-wizard-channel-setting-pilot-position-common-input"
                  type={"text"}
                  labelText={"FARC Wizard Channel Setting Pilot Position"}
                  required={false}
                  readOnly={false}
                  value={farcWizardChannelSettingPilotPosition}
                  onChange={handleFARCWizardChannelSettingPilotPosition}
                  maxLength={50}
                  placeholder={""}
                  errors={farcWizardChannelSettingPilotPositionErrors}
                  autoFocus={false}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-farc-wizard-channel-setting-off-position-common-input"
                  type={"text"}
                  labelText={"FARC Wizard Channel Setting Off Position"}
                  required={false}
                  readOnly={false}
                  value={farcWizardChannelSettingOffPosition}
                  onChange={handleFARCWizardChannelSettingOffPosition}
                  maxLength={50}
                  placeholder={""}
                  errors={farcWizardChannelSettingOffPositionErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : chosenSetting === "IO Normally Open/Closed" ? (
              <StyledSectionDiv>
                <Dropdown
                  dataTestname={
                    "add-io-expansion-assignment-io-normally-closed-dry-contact-mode-dropdown"
                  }
                  columns={normallyOpenClosedDryContactModeDropdown}
                  labelText={"IO Normally Closed Dry Contact Mode"}
                  onchange={handleIONormallyClosedDryContactMode}
                  selected={ioNormallyClosedDryContactMode}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-io-normally-closed-control-input-common-input"
                  type={"text"}
                  labelText={"IO Normally Closed Control Input"}
                  required={false}
                  readOnly={false}
                  value={ioNormallyClosedControlInput}
                  onChange={handleIONormallyClosedControlInput}
                  maxLength={50}
                  placeholder={""}
                  errors={ioNormallyClosedControlInputErrors}
                  autoFocus={false}
                />
                <Dropdown
                  dataTestname={
                    "add-io-expansion-assignment-io-normally-open-dry-contact-mode-dropdown"
                  }
                  columns={normallyOpenClosedDryContactModeDropdown}
                  labelText={"IO Normally Open Dry Contact Mode"}
                  onchange={handleIONormallyOpenDryContactMode}
                  selected={ioNormallyOpenDryContactMode}
                />
                <CommonInput
                  dataTestname="add-io-expansion-assignment-io-normally-open-control-input-common-input"
                  type={"text"}
                  labelText={"IO Normally Open Control Input"}
                  required={false}
                  readOnly={false}
                  value={ioNormallyOpenControlInput}
                  onChange={handleIONormallyOpenControlInput}
                  maxLength={50}
                  placeholder={""}
                  errors={ioNormallyOpenControlInputErrors}
                  autoFocus={false}
                />
              </StyledSectionDiv>
            ) : (
              <StyledMessageDiv>
                This option hasn't been implemented yet. If you believe this is
                in error, please contact TJ Blakely via email at
                tblakely@profireenergy.com
              </StyledMessageDiv>
            )}

            {existingErrors.current && errorCheckFinished && (
              <StyledErrorMessage data-testid="submit-errors">
                Please fix the errors in the following Settings sections:
                <StyledSectionErrorList>
                  {generalErrors.current > 0 && <li>General</li>}
                  {input1Errors.current > 0 && <li>Input 1</li>}
                  {input2Errors.current > 0 && <li>Input 2</li>}
                  {input3Errors.current > 0 && <li>Input 3</li>}
                  {input4Errors.current > 0 && <li>Input 4</li>}
                  {ioFourTwentyOutErrors.current > 0 && (
                    <li>IO Four Twenty Out</li>
                  )}
                  {farcWizardChannelErrors.current > 0 && (
                    <li>FARC Wizard Channel</li>
                  )}
                  {ioNormallyOpenClosedErrors.current > 0 && (
                    <li>IO Normally Open/Closed</li>
                  )}
                </StyledSectionErrorList>
              </StyledErrorMessage>
            )}
            <StyledButtonRow>
              <StyledCancelButtonHolder>
                <ProfireButton
                  dataTestname="add-io-configuration-cancel-button"
                  text="Cancel"
                  onClickFunction={handleCancel}
                />
              </StyledCancelButtonHolder>
              <StyledSaveButtonHolder>
                <ProfireButton
                  dataTestname="add-io-configuration-save-button"
                  text="Save"
                  onClickFunction={ErrorHandler}
                  defaultButton={true}
                />
              </StyledSaveButtonHolder>
            </StyledButtonRow>
          </StyledForm>
        </StyledMainDiv>
      </StyledAddIOExpansionAssignmentDiv>
    </ModalBox>
  );
};

export default AddIOExpansionAssignment;

const StyledAddIOExpansionAssignmentDiv = styled.div`
  width: calc(100% - 10px);
  height: 100%;
  overflow-y: auto;
  padding-right: 10px;
`;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentErrorSize};
  font-weight: ${(props) => props.theme.contentErrorWeight};

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

const StyledSectionDiv = styled.div`
  width: Calc(100%);
  background-color: #ffffff;
  border-radius: 10px;
  text-align: left;
`;

const StyledForm = styled.form``;

const StyledButtonRow = styled.div`
  width: 100%;
  margin-bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

const StyledCancelButtonHolder = styled.div`
  margin-right: 20px;
`;

const StyledSaveButtonHolder = styled.div``;

const StyledMessageDiv = styled.div`
  width: 100%;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledSectionErrorList = styled.ul`
  margin: 0;
`;
