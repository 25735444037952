import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IIOExpansionAssignment, IEWPAutomationForm } from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddIOExpansionAssignment from "./ioExpansionAssignment/AddIOExpansionAssignment";
import EditIOExpansionAssignment from "./ioExpansionAssignment/EditIOExpansionAssignment";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const IOExpansionAssignment: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [ioExpansionAssignmentTable, setIOExpansionAssignmentTable] = useState<
    IIOExpansionAssignment[]
  >(CopyObjectNoPointer(form.physicaliomap));

  useEffect(() => {
    setIOExpansionAssignmentTable(form.physicaliomap);
  }, [form]);

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>(["name", "mac", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Name", "MAC", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditIOExpansionAssignment,
    showDeleteIOExpansionAssignmentConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add IOExpansionAssignment
  const [showAddIOExpansionAssignment, setShowAddIOExpansionAssignment] =
    useState<boolean>(false);
  const [
    addIOExpansionAssignmentCBActive,
    setAddIOExpansionAssignmentCBActive,
  ] = useState<boolean>(false);
  const [addIOExpansionAssignmentCBText, setAddIOExpansionAssignmentCBText] =
    useState<string>("");
  const [
    retainedNewIOExpansionAssignment,
    setRetainedNewIOExpansionAssignment,
  ] = useState<IIOExpansionAssignment>();

  function handleShowAddIOExpansionAssignment() {
    if (showAddIOExpansionAssignment) {
      setRetainedNewIOExpansionAssignment(undefined);
    }
    setShowAddIOExpansionAssignment(!showAddIOExpansionAssignment);
  }

  //This function runs on clicking Save in Add IOExpansionAssignment
  function handleAddIOExpansionAssignment(e: IIOExpansionAssignment) {
    setShowAddIOExpansionAssignment(false);
    setAddIOExpansionAssignmentCBActive(true);

    let ioeaUpdate: IIOExpansionAssignment[] = [...ioExpansionAssignmentTable];
    ioeaUpdate.push(e);
    onChange(ioeaUpdate, "physicaliomap");

    setAddIOExpansionAssignmentCBText(`${e.name} successfully added`);
    setRetainedNewIOExpansionAssignment(undefined);
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Document Info
  function completeAddIOExpansionAssignment() {
    setAddIOExpansionAssignmentCBActive(false);
    setAddIOExpansionAssignmentCBText("");

    if (retainedNewIOExpansionAssignment !== undefined) {
      setShowAddIOExpansionAssignment(true);
    }
  }

  //Edit IOExpansionAssignment
  const [showEditIOExpansionAssignment, setShowEditIOExpansionAssignment] =
    useState<boolean>(false);
  const [
    editIOExpansionAssignmentCBActive,
    setEditIOExpansionAssignmentCBActive,
  ] = useState<boolean>(false);
  const [editIOExpansionAssignmentCBText, setEditIOExpansionAssignmentCBText] =
    useState<string>("");
  const [
    editIOExpansionAssignmentCBFinalDisplay,
    setEditIOExpansionAssignmentCBFinalDisplay,
  ] = useState<boolean>(false);
  //retainedEditIOExpansionAssignment is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [
    retainedEditIOExpansionAssignment,
    setRetainedEditIOExpansionAssignment,
  ] = useState<IIOExpansionAssignment | undefined>();
  const [chosenEditIOExpansionAssignment, setChosenEditIOExpansionAssignment] =
    useState<IIOExpansionAssignment>();

  function handleShowEditIOExpansionAssignment(e: any = undefined) {
    if (showEditIOExpansionAssignment) {
      setShowEditIOExpansionAssignment(false);
      setChosenEditIOExpansionAssignment(undefined);
    } else {
      setShowEditIOExpansionAssignment(!showEditIOExpansionAssignment);
      setChosenEditIOExpansionAssignment(e);
    }
  }

  //This function runs on clicking Save in Edit IOExpansionAssignment
  function showEditIOExpansionAssignmentConfirmationBox(
    e: IIOExpansionAssignment
  ) {
    setShowEditIOExpansionAssignment(false);
    setEditIOExpansionAssignmentCBActive(true);
    setEditIOExpansionAssignmentCBText(
      "Are you sure you want to save these changes?"
    );
    setRetainedEditIOExpansionAssignment(e);
  }

  //This function runs on clicking Cancel in Edit IOExpansionAssignment
  function hideEditIOExpansionAssignmentConfirmationBox() {
    setShowEditIOExpansionAssignment(false);
    setEditIOExpansionAssignmentCBActive(false);
    setEditIOExpansionAssignmentCBText("");
    setRetainedEditIOExpansionAssignment(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit IOExpansionAssignment
  function handleEditIOExpansionAssignment() {
    setEditIOExpansionAssignmentCBText("");

    let ioeaUpdate: IIOExpansionAssignment[] = [...ioExpansionAssignmentTable];
    ioeaUpdate.splice(
      GetEqualityIndex(ioeaUpdate, chosenEditIOExpansionAssignment),
      1,
      retainedEditIOExpansionAssignment!
    );
    onChange(ioeaUpdate, "physicaliomap");

    setEditIOExpansionAssignmentCBText(
      `${retainedEditIOExpansionAssignment!.name} successfully updated`
    );
    setRetainedEditIOExpansionAssignment(undefined);
    save();

    setEditIOExpansionAssignmentCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit IOExpansionAssignment
  function completeEditIOExpansionAssignment() {
    setEditIOExpansionAssignmentCBActive(false);
    setEditIOExpansionAssignmentCBText("");
    setEditIOExpansionAssignmentCBFinalDisplay(false);
    setChosenEditIOExpansionAssignment(undefined);

    if (retainedEditIOExpansionAssignment !== undefined) {
      setShowEditIOExpansionAssignment(true);
    }
  }

  //Delete IOExpansionAssignment
  const [
    deleteIOExpansionAssignmentCBActive,
    setDeleteIOExpansionAssignmentCBActive,
  ] = useState<boolean>(false);
  const [
    deleteIOExpansionAssignmentCBText,
    setDeleteIOExpansionAssignmentCBText,
  ] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteIOExpansionAssignmentCBFinalDisplay,
    setDeleteIOExpansionAssignmentCBFinalDisplay,
  ] = useState<boolean>(false);
  const [
    chosenDeleteIOExpansionAssignment,
    setChosenDeleteIOExpansionAssignment,
  ] = useState<IIOExpansionAssignment>();

  //This function runs on clicking Save in Delete IOExpansionAssignment
  function showDeleteIOExpansionAssignmentConfirmationBox(
    e: IIOExpansionAssignment
  ) {
    setChosenDeleteIOExpansionAssignment(e);
    setDeleteIOExpansionAssignmentCBActive(true);
    setDeleteIOExpansionAssignmentCBText(
      `Are you sure you want to delete ${e.name}?`
    );
  }

  //This function runs on clicking Cancel in Delete IOExpansionAssignment
  function hideDeleteIOExpansionAssignmentConfirmationBox(e: any) {
    setDeleteIOExpansionAssignmentCBActive(false);
    setDeleteIOExpansionAssignmentCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete IOExpansionAssignment
  function handleDeleteIOExpansionAssignment() {
    setDeleteIOExpansionAssignmentCBText("");

    let ioeaUpdate: IIOExpansionAssignment[] = [...ioExpansionAssignmentTable];
    ioeaUpdate.splice(
      GetEqualityIndex(ioeaUpdate, chosenDeleteIOExpansionAssignment),
      1
    );
    onChange(ioeaUpdate, "physicaliomap");

    setDeleteIOExpansionAssignmentCBText(
      `${chosenDeleteIOExpansionAssignment!.name} successfully deleted`
    );
    save();

    setDeleteIOExpansionAssignmentCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete IOExpansionAssignment
  function completeDeleteIOExpansionAssignment(e: any) {
    setDeleteIOExpansionAssignmentCBActive(false);
    setDeleteIOExpansionAssignmentCBText("");
    setDeleteIOExpansionAssignmentCBFinalDisplay(false);
    setChosenDeleteIOExpansionAssignment(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="io-expansion-assignment-add-io-expansion-assignment-confirmation-box"
        heading={"Information"}
        message={addIOExpansionAssignmentCBText}
        active={addIOExpansionAssignmentCBActive}
        onOk={completeAddIOExpansionAssignment}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="io-expansion-assignment-edit-io-expansion-assignment-confirmation-box"
        heading={"Information"}
        message={editIOExpansionAssignmentCBText}
        active={editIOExpansionAssignmentCBActive}
        onOk={completeEditIOExpansionAssignment}
        onYes={handleEditIOExpansionAssignment}
        onCancel={hideEditIOExpansionAssignmentConfirmationBox}
        displayCancel={true}
        finalDisplay={editIOExpansionAssignmentCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="io-expansion-assignment-delete-io-expansion-assignment-confirmation-box"
        heading={"Information"}
        message={deleteIOExpansionAssignmentCBText}
        active={deleteIOExpansionAssignmentCBActive}
        onOk={completeDeleteIOExpansionAssignment}
        onYes={handleDeleteIOExpansionAssignment}
        onCancel={hideDeleteIOExpansionAssignmentConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteIOExpansionAssignmentCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="io-expansion-assignment-common-customizable-table"
        usageIdentifier="io-expansion-assignment"
        data={ioExpansionAssignmentTable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv>
        <ProfireButton
          dataTestname={"io-expansion-assignment-add-button"}
          onClickFunction={handleShowAddIOExpansionAssignment}
          text={"Add IO Expansion Assignment"}
          size={7}
        />
        {showAddIOExpansionAssignment && (
          <AddIOExpansionAssignment
            active={showAddIOExpansionAssignment}
            onCancel={handleShowAddIOExpansionAssignment}
            onAdd={handleAddIOExpansionAssignment}
            retainedData={retainedNewIOExpansionAssignment}
            existingIOExpansionAssignment={ioExpansionAssignmentTable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditIOExpansionAssignment && (
        <EditIOExpansionAssignment
          active={showEditIOExpansionAssignment}
          ioExpansionInputs={chosenEditIOExpansionAssignment!}
          onCancel={handleShowEditIOExpansionAssignment}
          onEdit={showEditIOExpansionAssignmentConfirmationBox}
          retainedData={retainedEditIOExpansionAssignment}
          existingIOExpansionAssignment={ioExpansionAssignmentTable}
        />
      )}
    </StyledMainDiv>
  );
};

export default IOExpansionAssignment;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
