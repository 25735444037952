export interface ITool {
  name: string;
  description: string;
  route: string;
}

export interface IFlamecoArrestorHousingDTO {
  sales_order_number: number | null;
  sales_rep: string;
  customer: string;
  customer_type: string;
  job_name_or_number: string;
  fire_tube_id: number | null;
  fire_tube_od: number | null;
  fire_tube_owl: number;
  net_duty: number;
  hinge_orientation: string;
  hole_size: number;
  number_of_holes: number | null;
  bolt_circle: number;
  flange_od: number;
  stack_od: number;
  stack_height: number;
  stack_location: string;
  multi_tube_center_distance: number;
  application: string;
  environment: string;
  elevation: number;
  stack_arrestor_required: Boolean;
  additional_information: string;
  sender_email: string;
}

export interface IEWPForm {
  id: number;
  name: string;
  date_created: string;
}

export interface IDWGInfo {
  dwgnumber: string | undefined;
  dwgdesc: string | undefined;
}

export interface IWebsiteDocumentInfo {
  docnumber: string | undefined;
  docname: string | undefined;
}

export interface IApplianceController {
  controllermac: string;
  controllerdecimalmodbusaddress: string;
  controllername: string;
  controllercomments: string;
  has_valve_wired_to_hfv_output: boolean;
  has_forced_draft_fan_wired_to_hfv_output: boolean;
  has_purge_fan_wired_to_hfv_output: boolean;
  main_flame_detection_enabled: boolean;
  main_permissive_enabled: boolean;
  appliance_firing_rate_enabled: boolean;
  pilotcardtable: IPilotCard[],
  pilotvalvetable: IPilotValve[],
  mainvalvetable: IMainValve[],
  highfirevalvetable: IHighFireValve[],
  tcvtable: ITCV[]
}

export interface IPilotCard {
  pilotcardname: string | undefined;
  is_uv_pilot_card: boolean | undefined;
  pilotcardcomments: string | undefined;
}

export interface IPilotValve {
  pilotvalvetag: string | undefined;
  is_bleed_valve: boolean | undefined;
  pilotburnertable: IPilotBurner[];
}

export interface IPilotBurner {
  pilotburnerelement: string;
  pilotigniterelement: string;
}

export interface IMainValve {
  mainvalvetag: string | undefined;
  is_incinerator_waste_gas_valve: boolean | undefined;
  is_incinerator_assist_gas_valve: boolean | undefined;
  is_bleed_valve: boolean | undefined;
  proof_of_closure: boolean | undefined;
  poctag: string | undefined;
}

export interface IHighFireValve {
  hfvvalvetag: string | undefined;
}

export interface ITCV {
  tcvtag: string | undefined;
  tcvpurgeposition: string | undefined;
  tcvpilotposition: string | undefined;
  tcvminimumposition: string | undefined;
  tcv_is_farc_output: boolean | undefined;
  farcwizardchannelsettingname: string | undefined;
  farcwizardchannelsettingoffposition: string | undefined;
  external_power_required: boolean | undefined;
}

export interface ITemperatureConfiguration {
  temptag: string | undefined;
  tempdesc: string | undefined;
  temptype: string | undefined;
  tempiotype: string | undefined;
  tempcontrolmode: string | undefined;
  tempapplianceshutdown: string | undefined;
  tempesdsp: string | undefined;
  tempmainoffsp: string | undefined;
  temppilotoffsp: string | undefined;
  tempprocesssp: string | undefined;
}

export interface ITemperatureAssignment {
  tempcardname: string | undefined;
  tc1a: string | undefined;
  tc1b: string | undefined;
  tc2a: string | undefined;
  tc2b: string | undefined;
}

export interface IIOExpansionInputs {
  iotag: string | undefined;
  iodesc: string | undefined;
  iotype: string | undefined;
  iosignal: string | undefined;
  iomode: string | undefined;
  iounits: string | undefined;
  iolowsp: string | undefined;
  iohighsp: string | undefined;
  iocomment: string | undefined;
}

export interface IIOExpansionAssignment {
  name: string;
  mac: string;
  input1_tag: string;
  input1_span: string;
  input1_out: string;
  input1_dip: string;
  input2_tag: string;
  input2_span: string;
  input2_out: string;
  input2_dip: string;
  input3_tag: string;
  input3_span: string;
  input3_out: string;
  input3_dip: string;
  input4_tag: string;
  input4_span: string;
  input4_out: string;
  input4_dip: string;
  iofourtwentyout_name: string;
  iofourtwentyout_mode: string;
  iofourtwentyout_controlinput: string;
  io_expansion_output_is_farc_output: boolean;
  farcwizardchannelsettingname: string;
  farcwizard_setting_direction_is_set_to_direct: boolean;
  farcwizard_setting_post_purge_mode_is_set_to_purge: boolean;
  farcwizardchannelsettingpurgeposition: string;
  farcwizardchannelsettingpilotposition: string;
  farcwizardchannelsettingoffposition: string;
  ionormallyclosed_mode: string;
  ionormallyclosed_controlinput: string;
  ionormallyopen_mode: string;
  ionormallyopen_controlinput: string;
}

export interface IBMSInstrumentation {
  input: string | undefined;
  iotag: string | undefined;
  iodescription: string | undefined;
  iotype: string | undefined;
  iocontrolmode: string | undefined;
  iocomment: string | undefined;
}

export interface IEWPAutomationForm {
    applicationsummary: string;
    projectnumber: string;
    clientname: string;
    siteid: string;
    appliancename: string;
    appliancetag: string;
    documentnumber: string;
    documentrevisionnumber: string;
    firmwareversion: string;
    generatenarrative: boolean;
    generatesdk: boolean;
    generatefat: boolean;
    generatewiringdiagram: boolean;
    is_panel: boolean;
    is_farc_application: boolean;
    is_incinerator_application: boolean;
    is_on_off_control_application: boolean;
    pilot_off_mode_setting_set_to_disabled: boolean;
    pilot_off_mode_setting_set_to_off_at_setpoint: boolean;
    pilot_off_mode_setting_set_to_off_after_main: boolean;
    pilot_off_mode_setting_set_to_follow_main: boolean;
    low_fire_mode_setting_set_to_disabled: boolean;
    low_fire_mode_setting_set_to_on_at_process_sp: boolean;
    low_fire_mode_setting_set_to_on_at_low_fire_sp: boolean;
    has_io_expansion_card: boolean;
    has_modbus_card: boolean;
    network_card_count: string;
    start_tb_number: string;
    pilot_relight_mode_set_to_during_flame_fail: boolean;
    pilot_relight_mode_set_to_during_timeout: boolean;
    pilot_flame_fail_response_time_setting: string;
    main_flame_fail_response_time_setting: string;
    pilot_relight_timeout_setting: string;
    relight_attempts_setting: number;
    minimum_pilots_running_setting: number;
    designdrawingtable: IDWGInfo[],
    referencedocumenttable: IWebsiteDocumentInfo[],
    applianceconfigtable: IApplianceController[],
    logicaltemptable: ITemperatureConfiguration[],
    physicaltempmap: ITemperatureAssignment[],
    logicaliotable: IIOExpansionInputs[],
    physicaliomap: IIOExpansionAssignment[],
    bmsio: IBMSInstrumentation[]
  }


export const temp: IEWPAutomationForm = {
    "applicationsummary": "",
    "projectnumber": "",
    "clientname": "",
    "siteid": "",
    "appliancename": "",
    "appliancetag": "",
    "documentnumber": "",
    "documentrevisionnumber": "",
    "firmwareversion": "",
    "generatenarrative": false,
    "generatesdk": false,
    "generatefat": false,
    "generatewiringdiagram": false,
    "is_panel": false,
    "is_farc_application": false,
    "is_incinerator_application": false,
    "is_on_off_control_application": false,
    "pilot_off_mode_setting_set_to_disabled": false,
    "pilot_off_mode_setting_set_to_off_at_setpoint": false,
    "pilot_off_mode_setting_set_to_off_after_main": false,
    "pilot_off_mode_setting_set_to_follow_main": false,
    "low_fire_mode_setting_set_to_disabled": false,
    "low_fire_mode_setting_set_to_on_at_process_sp": false,
    "low_fire_mode_setting_set_to_on_at_low_fire_sp": false,
    "has_io_expansion_card": false,
    "has_modbus_card": false,
    "network_card_count": "",
    "start_tb_number": "",
    "pilot_relight_mode_set_to_during_flame_fail": false,
    "pilot_relight_mode_set_to_during_timeout": false,
    "pilot_flame_fail_response_time_setting": "",
    "main_flame_fail_response_time_setting": "",
    "pilot_relight_timeout_setting": "",
    "relight_attempts_setting": 0,
    "minimum_pilots_running_setting": 0,
    "designdrawingtable": [],
    "referencedocumenttable": [],
    "applianceconfigtable": [],
    "logicaltemptable": [],
    "physicaltempmap": [],
    "logicaliotable": [],
    "physicaliomap": [],
    "bmsio": [
      {input: "esd", iocomment: "", iocontrolmode: "", iodescription: "", iotag: "", iotype: ""},
      {input: "power", iocomment: "", iocontrolmode: "", iodescription: "", iotag: "", iotype: ""},
    ]
  }

export interface IEWPFormListDetails {
  id: number;
  title: string;
  owner_id: string;
  last_edit_date: string;
  submit_date: string | null;
  active: boolean;
}

export interface IEWPUser {
  id: string;
  email: string;
}

export const sample: IEWPAutomationForm = {  

  "applicationsummary": "The High Fire Industries Big Ol' Line Heater 123-H-001 at 9-32-52-26-W4 is a made-up industrial heating appliance that is being used to test the control narrative automated generation using docxtpl.  It simultaneously is and is not (1) a farc application, (2) an on/off control application using Low Fire, (3) an on/off control application with no Low Fire, (4) an incinerator application.  It may or may not be a multiple burner appliance, may or may not use IO expansion card(s), and may or may not utilize Modbus.  It is meant to be configured to match all of the applications expected to be encountered to make sure that the control narrative template can accomodate.",

  "projectnumber": "22A00X",

  "clientname": "High Fire Industries",

  "siteid": "9-32-52-26-W4",

  "appliancename": "Big Ol' Line Heater",

  "appliancetag": "123-H-001",

  "documentnumber": "22A00X-C1",

  "documentrevisionnumber": "rev.0A",

  "firmwareversion": "NA-43",

  "generatenarrative":true,

  "generatesdk":true,

  "generatefat":false,

  "generatewiringdiagram":true,

  "is_panel":true,



  "is_farc_application":false,

  "is_incinerator_application":false,

  "is_on_off_control_application":false,



  "pilot_off_mode_setting_set_to_disabled":false,

  "pilot_off_mode_setting_set_to_off_at_setpoint":false,

  "pilot_off_mode_setting_set_to_off_after_main":true,

  "pilot_off_mode_setting_set_to_follow_main":false,



  "low_fire_mode_setting_set_to_disabled":false,

  "low_fire_mode_setting_set_to_on_at_process_sp":false,

  "low_fire_mode_setting_set_to_on_at_low_fire_sp":true,


  "has_io_expansion_card":true,

  "has_modbus_card":true,

  "network_card_count": "1",

  "start_tb_number": "04",



  "pilot_relight_mode_set_to_during_flame_fail":false,

  "pilot_relight_mode_set_to_during_timeout":true,

  "pilot_flame_fail_response_time_setting": "4 seconds",

  "main_flame_fail_response_time_setting": "3 seconds",

  "pilot_relight_timeout_setting": "30 seconds",

  "relight_attempts_setting": 3,

  "minimum_pilots_running_setting": 1,



  "designdrawingtable": [{

          "dwgnumber": "22A00X-W1",

          "dwgdesc": "Wiring Diagram"

      },

      {

          "dwgnumber": "22A00X-N1",

          "dwgdesc": "Network Diagram"

      },

      {

          "dwgnumber": "22A00X-S1",

          "dwgdesc": "Shutdown Key"

      },

      {

          "dwgnumber": "22A00X-L1",

          "dwgdesc": "Layout Diagram"

      }

  ],



  "referencedocumenttable": [{

          "docnumber": "DOC-001149 v5.0",

          "docname": "PF3100 Product Manual"

      },

      {

          "docnumber": "DOC-001071 v2.0",

          "docname": "PF3107-00 Modbus Register Map"

      },

      {

          "docnumber": "DOC-001065 v8.0",

          "docname": "PF3100 FARC User Guide"

      }

  ],

  "applianceconfigtable": [{

      "controllermac": "98:00:00:31:01:a1",

      "controllerdecimalmodbusaddress": "161",

      "controllername": "Burner A",

      "controllercomments": "--",

      "has_valve_wired_to_hfv_output":false,

      "has_forced_draft_fan_wired_to_hfv_output":true,

      "has_purge_fan_wired_to_hfv_output":false,

      "main_flame_detection_enabled":false,

      "main_permissive_enabled":false,

      "appliance_firing_rate_enabled": true,



      "pilotcardtable": [{

          "pilotcardname": "Pilot 1",        

          "is_uv_pilot_card": false,

          "pilotcardcomments": "--"

      },

      {

          "pilotcardname": "Main Detect",        

          "is_uv_pilot_card": true,

          "pilotcardcomments": "--"

      }

      ],

      "pilotvalvetable": [{

              "pilotvalvetag": "SSV-101",

              "is_bleed_valve": false,

              "pilotburnertable": [{

                  "pilotburnerelement": "BE-101A",

                  "pilotigniterelement": "IGN-101A"                  

              },

              {

                  "pilotburnerelement": "BE-101B",

                  "pilotigniterelement": "IGN-101B"

              }

              ]          

          },

          {

              "pilotvalvetag": "SSV-102",

              "is_bleed_valve": false,

              "pilotburnertable": [{

                  "pilotigniterelement": "IGN-102",

                  "pilotburnerelement": "BE-102"

              }]                          

          }

      ],

      "mainvalvetable": [{

              "mainvalvetag": "SSV-201",

              "is_incinerator_waste_gas_valve":false,

              "is_incinerator_assist_gas_valve":false,

              "is_bleed_valve":false,

              "proof_of_closure": false,

              "poctag": "ZSC-101"

          },

          {

              "mainvalvetag": "SSV-202",

              "is_incinerator_waste_gas_valve":false,

              "is_incinerator_assist_gas_valve":false,

              "is_bleed_valve":false,

              "proof_of_closure": false,

              "poctag": "ZSC-101"

          },

          {

              "mainvalvetag": "SOV-203",

              "is_incinerator_waste_gas_valve":false,

              "is_incinerator_assist_gas_valve":false,

              "is_bleed_valve":true,

              "proof_of_closure": false,

              "poctag": "ZSO-101"

          }

      ],

      "highfirevalvetable": [{

          "hfvvalvetag": "MS-101"

      }],

      "tcvtable": [{

          "tcvtag": "TCV-101",

          "tcvpurgeposition": "--%",

          "tcvpilotposition": "--%",

          "tcvminimumposition": "--%",

          "tcv_is_farc_output":true,

          "farcwizardchannelsettingname": "Fuel",

          "farcwizardchannelsettingoffposition": "--%",

          "external_power_required": true

      }]

  }],

 



  "logicaltemptable": [{

          "temptag": "TE-101",

          "tempdesc": "Outlet Temperature",

          "temptype": "Dual",

          "tempiotype": "TC",

          "tempcontrolmode": "Primary Process",

          "tempapplianceshutdown": "Enabled",

          "tempesdsp": "500 \u00b0C",

          "tempmainoffsp": "450 \u00b0C",

          "temppilotoffsp": "400 \u00b0C",

          "tempprocesssp": "200 \u00b0C"

      },

      {

          "temptag": "TE-102",

          "tempdesc": "Stack Temperature",

          "temptype": "Single",

          "tempiotype": "TC",

          "tempcontrolmode": "Aux Temp",

          "tempapplianceshutdown": "Enabled",

          "tempesdsp": "800 \u00b0C",

          "tempmainoffsp": "550 \u00b0C",

          "temppilotoffsp": "500 \u00b0C",

          "tempprocesssp": "300 \u00b0C"

      }

  ],


  "physicaltempmap": [{

          "tempcardname": "Temp Card A",

          "tc1a": "TE-101",

          "tc1b": "Dual",

          "tc2a": "-",

          "tc2b": "-"

      },

      {

          "tempcardname": "Temp Card B",

          "tc1a": "TE-102",

          "tc1b": "Dual",

          "tc2a": "-",

          "tc2b": "-"

      }

  ],



  "logicaliotable": [{

          "iotag": "DAL-101\nDAL-102",

          "iodesc": "Draft fan low low pressure switch",

          "iotype": "Pressure",

          "iosignal": "Digital",

          "iomode": "Proof of Airflow",

          "iounits": "N/A",

          "iolowsp": "N/A",

          "iohighsp": "N/A",

          "iocomment": ""

      }

  ],


  "physicaliomap": [{

          "name": "IO Expansion Card 1",

          "mac": "98:00:00:31:13:x1",

          "input1_tag": "DAL-101 / DAL-102",

          "input1_span": "N/A",

          "input1_out": "To PLC",

          "input1_dip": "Floating",

          "input2_tag": "ZT-101",

          "input2_span": "N/A",

          "input2_out": "N/A",

          "input2_dip": "Ground",

          "input3_tag": "ZT-102",

          "input3_span": "N/A",

          "input3_out": "N/A",

          "input3_dip": "Ground",

          "input4_tag": "N/A",

          "input4_span": "N/A",

          "input4_out": "N/A",

          "input4_dip": "Ground",

          "iofourtwentyout_name": "FARC Air Out",

          "iofourtwentyout_mode": "Air Position Controlled by FARC",

          "iofourtwentyout_controlinput": "VFD-101",

          "io_expansion_output_is_farc_output":true,

          "farcwizardchannelsettingname": "Air",

          "farcwizard_setting_direction_is_set_to_direct":true,

          "farcwizard_setting_post_purge_mode_is_set_to_purge":true,

          "farcwizardchannelsettingpurgeposition": "--%",

          "farcwizardchannelsettingpilotposition": "--%",

          "farcwizardchannelsettingoffposition": "--%",

          "ionormallyclosed_mode": "N/A",

          "ionormallyclosed_controlinput": "N/A",

          "ionormallyopen_mode": "N/A",

          "ionormallyopen_controlinput": "N/A"

      }

  ],

  "bmsio": [{

          "input": "auxin",

          "iotag": "PSLL-201",

          "iodescription": "Normally open low low pressure switch",

          "iotype": "Digital",

          "iocontrolmode": "Alarm",

          "iocomment": "LL: -- PSI (NOTE 1)"

      },

      {

          "input": "pressure",

          "iotag": "PT-201",

          "iodescription": "Pressure Transmitter",

          "iotype": "4-20",

          "iocontrolmode": "Alarm",

          "iocomment": "LL: -- PSI (NOTE 1) \n HH: -- PSI (NOTE 1)"

      },

      {

          "input": "level",

          "iotag": "LSLL-101",

          "iodescription": "Normally open low low level switch",

          "iotype": "Digital",

          "iocontrolmode": "Alarm",

          "iocomment": "LL: -- (NOTE 1)"

      },

      {

          "input": "esd",

          "iotag": "ESD",

          "iodescription": "BMS emergency shutdown switch",

          "iotype": "Digital",

          "iocontrolmode": "Alarm",

          "iocomment": "--"

      },

      {

          "input": "power",

          "iotag": "--",

          "iodescription": "High/low BMS supply voltage restart",

          "iotype": "Analog",

          "iocontrolmode": "Wait",

          "iocomment": "Low: 19.2V \nHigh: 33.6V"

      }

  ]

}

export interface IGenerateErrors {
  section: string,
  entry: string,
  field: string,
  error: string,
}
