import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm, IBMSInstrumentation } from "../../types/tools";
import ProfireButton from "../common/buttons/ProfireButton";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import AddBMSInstrumentation from "./bmsInstrumentation/AddBMSInstrumentation";
import EditBMSInstrumentation from "./bmsInstrumentation/EditBMSInstrumentation";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const BMSInstrumentation: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [temperatureConfigurations, setBMSInstrumentations] = useState<
    IBMSInstrumentation[]
  >(CopyObjectNoPointer(form.bmsio));

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "", ""]);

  const [headers] = useState<string[]>(["input", "iotag", "", ""]);

  const [overwriteHeaders] = useState<string[]>(["Input", "IO Tag", "", ""]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditBMSInstrumentation,
    showDeleteBMSInstrumentationConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add BMSInstrumentation
  const [showAddBMSInstrumentation, setShowAddBMSInstrumentation] =
    useState<boolean>(false);
  const [addBMSInstrumentationCBActive, setAddBMSInstrumentationCBActive] =
    useState<boolean>(false);
  const [addBMSInstrumentationCBText, setAddBMSInstrumentationCBText] =
    useState<string>("");
  const [retainedNewBMSInstrumentation, setRetainedNewBMSInstrumentation] =
    useState<IBMSInstrumentation>();

  function handleShowAddBMSInstrumentation() {
    if (showAddBMSInstrumentation) {
      setRetainedNewBMSInstrumentation(undefined);
    }
    setShowAddBMSInstrumentation(!showAddBMSInstrumentation);
  }

  //This function runs on clicking Save in Add BMSInstrumentation
  function handleAddBMSInstrumentation(e: IBMSInstrumentation) {
    setShowAddBMSInstrumentation(false);
    setAddBMSInstrumentationCBActive(true);

    temperatureConfigurations.push(e);
    onChange(temperatureConfigurations, "bmsio");

    setAddBMSInstrumentationCBText(`${e.input} successfully added`);
    setRetainedNewBMSInstrumentation(undefined);
    setBMSInstrumentationAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add Temperature Configuration
  function completeAddBMSInstrumentation() {
    setAddBMSInstrumentationCBActive(false);
    setAddBMSInstrumentationCBText("");

    if (retainedNewBMSInstrumentation !== undefined) {
      setShowAddBMSInstrumentation(true);
    }
  }

  function setBMSInstrumentationAfterAdd() {
    setBMSInstrumentations(form.bmsio);
  }

  //Edit BMSInstrumentation
  const [showEditBMSInstrumentation, setShowEditBMSInstrumentation] =
    useState<boolean>(false);
  const [editBMSInstrumentationCBActive, setEditBMSInstrumentationCBActive] =
    useState<boolean>(false);
  const [editBMSInstrumentationCBText, setEditBMSInstrumentationCBText] =
    useState<string>("");
  const [
    editBMSInstrumentationCBFinalDisplay,
    setEditBMSInstrumentationCBFinalDisplay,
  ] = useState<boolean>(false);
  //retainedEditBMSInstrumentation is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditBMSInstrumentation, setRetainedEditBMSInstrumentation] =
    useState<IBMSInstrumentation | undefined>();
  const [chosenEditBMSInstrumentation, setChosenEditBMSInstrumentation] =
    useState<IBMSInstrumentation>();

  function handleShowEditBMSInstrumentation(e: any = undefined) {
    if (showEditBMSInstrumentation) {
      setShowEditBMSInstrumentation(false);
      setChosenEditBMSInstrumentation(undefined);
    } else {
      setShowEditBMSInstrumentation(!showEditBMSInstrumentation);
      setChosenEditBMSInstrumentation(e);
    }
  }

  //This function runs on clicking Save in Edit BMSInstrumentation
  function showEditBMSInstrumentationConfirmationBox(e: IBMSInstrumentation) {
    setShowEditBMSInstrumentation(false);
    setEditBMSInstrumentationCBActive(true);
    setEditBMSInstrumentationCBText(
      "Are you sure you want to save these changes?"
    );
    setRetainedEditBMSInstrumentation(e);
  }

  //This function runs on clicking Cancel in Edit BMSInstrumentation
  function hideEditBMSInstrumentationConfirmationBox() {
    setShowEditBMSInstrumentation(false);
    setEditBMSInstrumentationCBActive(false);
    setEditBMSInstrumentationCBText("");
    setRetainedEditBMSInstrumentation(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit BMSInstrumentation
  function handleEditBMSInstrumentation() {
    setEditBMSInstrumentationCBText("");

    setBMSInstrumentations(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenEditBMSInstrumentation
        ),
        1,
        retainedEditBMSInstrumentation!
      )
    );
    onChange(temperatureConfigurations, "bmsio");

    setEditBMSInstrumentationCBText(
      `${retainedEditBMSInstrumentation!.input} successfully updated`
    );
    setRetainedEditBMSInstrumentation(undefined);
    setBMSInstrumentationAfterAdd();
    save();

    setEditBMSInstrumentationCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit BMSInstrumentation
  function completeEditBMSInstrumentation() {
    setEditBMSInstrumentationCBActive(false);
    setEditBMSInstrumentationCBText("");
    setEditBMSInstrumentationCBFinalDisplay(false);
    setChosenEditBMSInstrumentation(undefined);

    if (retainedEditBMSInstrumentation !== undefined) {
      setShowEditBMSInstrumentation(true);
    }
  }

  //Delete BMSInstrumentation
  const [
    deleteBMSInstrumentationCBActive,
    setDeleteBMSInstrumentationCBActive,
  ] = useState<boolean>(false);
  const [deleteBMSInstrumentationCBText, setDeleteBMSInstrumentationCBText] =
    useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [
    deleteBMSInstrumentationCBFinalDisplay,
    setDeleteBMSInstrumentationCBFinalDisplay,
  ] = useState<boolean>(false);
  const [chosenDeleteBMSInstrumentation, setChosenDeleteBMSInstrumentation] =
    useState<IBMSInstrumentation>();

  //This function runs on clicking Save in Delete BMSInstrumentation
  function showDeleteBMSInstrumentationConfirmationBox(e: IBMSInstrumentation) {
    setChosenDeleteBMSInstrumentation(e);
    setDeleteBMSInstrumentationCBActive(true);
    setDeleteBMSInstrumentationCBText(
      `Are you sure you want to delete ${e.input}?`
    );
  }

  //This function runs on clicking Cancel in Delete BMSInstrumentation
  function hideDeleteBMSInstrumentationConfirmationBox(e: any) {
    setDeleteBMSInstrumentationCBActive(false);
    setDeleteBMSInstrumentationCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete BMSInstrumentation
  function handleDeleteBMSInstrumentation() {
    setDeleteBMSInstrumentationCBText("");

    setBMSInstrumentations(
      temperatureConfigurations.splice(
        GetEqualityIndex(
          temperatureConfigurations,
          chosenDeleteBMSInstrumentation
        ),
        1
      )
    );
    onChange(temperatureConfigurations, "bmsio");

    setDeleteBMSInstrumentationCBText(
      `${chosenDeleteBMSInstrumentation!.input} successfully deleted`
    );
    setBMSInstrumentationAfterAdd();
    save();

    setDeleteBMSInstrumentationCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete BMSInstrumentation
  function completeDeleteBMSInstrumentation(e: any) {
    setDeleteBMSInstrumentationCBActive(false);
    setDeleteBMSInstrumentationCBText("");
    setDeleteBMSInstrumentationCBFinalDisplay(false);
    setChosenDeleteBMSInstrumentation(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="temperature-configuration-add-document-info-confirmation-box"
        heading={"Information"}
        message={addBMSInstrumentationCBText}
        active={addBMSInstrumentationCBActive}
        onOk={completeAddBMSInstrumentation}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-edit-document-info-confirmation-box"
        heading={"Information"}
        message={editBMSInstrumentationCBText}
        active={editBMSInstrumentationCBActive}
        onOk={completeEditBMSInstrumentation}
        onYes={handleEditBMSInstrumentation}
        onCancel={hideEditBMSInstrumentationConfirmationBox}
        displayCancel={true}
        finalDisplay={editBMSInstrumentationCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="temperature-configuration-delete-document-info-confirmation-box"
        heading={"Information"}
        message={deleteBMSInstrumentationCBText}
        active={deleteBMSInstrumentationCBActive}
        onOk={completeDeleteBMSInstrumentation}
        onYes={handleDeleteBMSInstrumentation}
        onCancel={hideDeleteBMSInstrumentationConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteBMSInstrumentationCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="temperature-configuration-common-customizable-table"
        usageIdentifier="temperatureConfigurations"
        data={form.bmsio}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-document-info-button-div">
        <ProfireButton
          dataTestname="temperature-configuration-add-document-info-button"
          onClickFunction={handleShowAddBMSInstrumentation}
          text="Add BMS Instrumentation"
          size={7}
        />
        {showAddBMSInstrumentation && (
          <AddBMSInstrumentation
            active={showAddBMSInstrumentation}
            onCancel={handleShowAddBMSInstrumentation}
            onAdd={handleAddBMSInstrumentation}
            retainedData={retainedNewBMSInstrumentation}
            existingBMSInstrumentation={form.bmsio}
          />
        )}
      </StyledAddButtonDiv>
      {showEditBMSInstrumentation && (
        <EditBMSInstrumentation
          active={showEditBMSInstrumentation}
          bmsInstrumentation={chosenEditBMSInstrumentation!}
          onCancel={handleShowEditBMSInstrumentation}
          onEdit={showEditBMSInstrumentationConfirmationBox}
          retainedData={retainedEditBMSInstrumentation}
          existingBMSInstrumentation={temperatureConfigurations}
        />
      )}
    </StyledMainDiv>
  );
};

export default BMSInstrumentation;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: end;
`;
