import React, { useState } from "react";
import styled from "styled-components";
import Dropdown from "../components/common/formInputs/Dropdown";
import ToolsLogo from "../assets/images/tools/Primary_Red.png";
import { IFlamecoArrestorHousingDTO } from "../types/tools";
import axios from "axios";
import Loading from "../components/common/modals/Loading";
import { useMsal } from "@azure/msal-react";
import ProfireButton from "../components/common/buttons/ProfireButton";
import CommonInput from "../components/common/formInputs/CommonInput";
import ConfirmationBox from "../components/common/modals/ConfirmationBox";
import {
  applicationDropdown,
  booleanDropdown,
  customerTypeDropdown,
  environmentDropdown,
  hingeOrientationDropdown,
  stackLocationDropdown,
} from "../utilities/staticObjects";
import { getToken } from "../utilities/msTokenFunc";

interface FlamecoDataSheetProps {}

let existingErrors: Boolean = false;

const FlamecoDataSheet: React.FC<FlamecoDataSheetProps> = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { accounts } = useMsal();

  const [salesOrderNumber, setSalesOrderNumber] = useState<number | null>(null);
  const [salesRep, setSalesRep] = useState<string>("");
  const [customer, setCustomer] = useState<string>("");
  const [customerType, setCustomerType] = useState<string>("0");
  const [jobNameOrNumber, setJobNameOrNumber] = useState<string>("");
  const [firetubeID, setFiretubeID] = useState<string | null>(null);
  const [firetubeOD, setFiretubeOD] = useState<string | null>(null);
  const [firetubeOWL, setFiretubeOWL] = useState<string>("");
  const [netDuty, setNetDuty] = useState<number | null>(null);
  const [hingeOrientation, setHingeOrientation] =
    useState<string>("Hinge on Left");
  const [holeSize, setHoleSize] = useState<string | null>(null);
  const [numberOfHoles, setNumberOfHoles] = useState<number | null>(null);
  const [boltCircle, setBoltCircle] = useState<string | null>(null);
  const [flangeOD, setFlangeOD] = useState<string | null>(null);
  const [stackOD, setStackOD] = useState<string | null>(null);
  const [stackHeight, setStackHeight] = useState<string | null>(null);
  const [stackLocation, setStackLocation] = useState<string>("Left");
  const [multiTubeCenterDistance, setMultiTubeCenterDistance] = useState<
    string | null
  >(null);
  const [application, setApplication] = useState<string>("Heater");
  const [environment, setEnvironment] = useState<string>("Normal");
  const [elevation, setElevation] = useState<string | null>(null);
  const [stackArrestorRequired, setStackArrestorRequired] =
    useState<Boolean>(false);
  const [additionalInformation, setAdditionalInformation] =
    useState<string>("");

  const integerRegex = /^[0-9\b]+$/;
  const floatRegex = /^[0-9]*\.?[0-9]+$/;

  const checkIfAcceptedFloat = (value: string) => {
    if (floatRegex.test(value) || value.slice(-1) === "." || value === "") {
      if (value.match(/\./g)?.length! > 1) {
        return false;
      }
      return true;
    }
    return false;
  };

  const handleSalesOrderNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!integerRegex.test(value) && value !== "") {
      setSalesOrderNumberErrors("Only whole numbers are accepted.");
    } else {
      setSalesOrderNumber(value === "" ? null : parseInt(value));
      setSalesOrderNumberErrors("");
    }
  };

  const handleSalesRep = (e: React.FormEvent<HTMLInputElement>) => {
    setSalesRep(e.currentTarget.value);
    setSalesRepErrors("");
  };

  const handleCustomer = (e: React.FormEvent<HTMLInputElement>) => {
    setCustomer(e.currentTarget.value);
    setCustomerErrors("");
  };

  function handleCustomerType(e: React.FormEvent<HTMLSelectElement>) {
    setCustomerType(`${e}`);
    setCustomerTypeErrors("");
  }

  const handleJobNameOrNumber = (e: React.FormEvent<HTMLInputElement>) => {
    setJobNameOrNumber(e.currentTarget.value);
    setJobNameOrNumberErrors("");
  };

  const handleFiretubeID = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setFiretubeID(value);
      setFiretubeIDErrors("");
      setFiretubeODErrors("");
    } else {
      setFiretubeIDErrors("Only numbers are accepted.");
    }
  };

  const handleFiretubeOD = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setFiretubeOD(value);
      setFiretubeODErrors("");
      setFiretubeODErrors("");
    } else {
      setFiretubeODErrors("Only numbers are accepted.");
    }
  };

  const handleFiretubeOWL = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setFiretubeOWL(value);
      setFiretubeOWLErrors("");
    } else {
      setFiretubeOWLErrors("Only numbers are accepted.");
    }
  };

  const handleNetDuty = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!integerRegex.test(value) && value !== "") {
      setNetDutyErrors("Only whole numbers are accepted.");
    } else {
      setNetDuty(value === "" ? null : parseInt(value));
      setNetDutyErrors("");
    }
  };

  function handleHingeOrientation(e: React.FormEvent<HTMLSelectElement>) {
    setHingeOrientation(`${e}`);
    setHingeOrientationErrors("");
  }

  const handleHoleSize = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setHoleSize(value === "" ? null : value);
      setHoleSizeErrors("");
    } else {
      setHoleSizeErrors("Only numbers are accepted.");
    }
  };

  const handleNumberOfHoles = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!integerRegex.test(value) && value !== "") {
      setNumberOfHolesErrors("Only whole numbers are accepted.");
    } else {
      setNumberOfHoles(value === "" ? null : parseInt(value));
      setNumberOfHolesErrors("");
    }
  };

  const handleBoltCircle = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setBoltCircle(value === "" ? null : value);
      setBoltCircleErrors("");
    } else {
      setBoltCircleErrors("Only numbers are accepted.");
    }
  };

  const handleFlangeOD = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setFlangeOD(value === "" ? null : value);
      setFlangeODErrors("");
    } else {
      setFlangeODErrors("Only numbers are accepted.");
    }
  };

  const handleStackOD = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setStackOD(value === "" ? null : value);
      setStackODErrors("");
    } else {
      setStackODErrors("Only numbers are accepted.");
    }
  };

  const handleStackHeight = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setStackHeight(value === "" ? null : value);
      setStackHeightErrors("");
    } else {
      setStackHeightErrors("Only numbers are accepted.");
    }
  };

  function handleStackLocation(e: React.FormEvent<HTMLSelectElement>) {
    setStackLocation(`${e}`);
    setStackLocationErrors("");
  }

  const handleMultiTubeCenterDistance = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setMultiTubeCenterDistance(value === "" ? null : value);
      setMultiTubeCenterDistanceErrors("");
    } else {
      setMultiTubeCenterDistanceErrors("Only numbers are accepted.");
    }
  };

  function handleApplication(e: React.FormEvent<HTMLSelectElement>) {
    setApplication(`${e}`);
    setApplicationErrors("");
  }

  function handleEnvironment(e: React.FormEvent<HTMLSelectElement>) {
    setEnvironment(`${e}`);
    setEnvironmentErrors("");
  }

  const handleElevation = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (checkIfAcceptedFloat(value)) {
      setElevation(value === "" ? null : value);
      setElevationErrors("");
    } else {
      setElevationErrors("Only numbers are accepted.");
    }
  };

  function handleStackArrestorRequired(e: React.FormEvent<HTMLSelectElement>) {
    if (`${e}` === "Yes") {
      setStackArrestorRequired(true);
      setStackArrestorErrors("");
    } else if (`${e}` === "No") {
      setStackArrestorRequired(false);
      setStackArrestorErrors("");
    }
  }

  const handleAdditionalInformation = (
    e: React.FormEvent<HTMLInputElement>
  ) => {
    setAdditionalInformation(e.currentTarget.value);
    setAdditionalInformationErrors("");
  };

  const [salesOrderNumberErrors, setSalesOrderNumberErrors] =
    useState<string>("");
  const [salesRepErrors, setSalesRepErrors] = useState<string>("");
  const [customerErrors, setCustomerErrors] = useState<string>("");
  const [customerTypeErrors, setCustomerTypeErrors] = useState<string>("");
  const [jobNameOrNumberErrors, setJobNameOrNumberErrors] =
    useState<string>("");
  const [firetubeIDErrors, setFiretubeIDErrors] = useState<string>("");
  const [firetubeODErrors, setFiretubeODErrors] = useState<string>("");
  const [firetubeOWLErrors, setFiretubeOWLErrors] = useState<string>("");
  const [netDutyErrors, setNetDutyErrors] = useState<string>("");
  const [hingeOrientationErrors, setHingeOrientationErrors] =
    useState<string>("");
  const [holeSizeErrors, setHoleSizeErrors] = useState<string>("");
  const [numberOfHolesErrors, setNumberOfHolesErrors] = useState<string>("");
  const [boltCircleErrors, setBoltCircleErrors] = useState<string>("");
  const [flangeODErrors, setFlangeODErrors] = useState<string>("");
  const [stackODErrors, setStackODErrors] = useState<string>("");
  const [stackHeightErrors, setStackHeightErrors] = useState<string>("");
  const [stackLocationErrors, setStackLocationErrors] = useState<string>("");
  const [multiTubeCenterDistanceErrors, setMultiTubeCenterDistanceErrors] =
    useState<string>("");
  const [applicationErrors, setApplicationErrors] = useState<string>("");
  const [environmentErrors, setEnvironmentErrors] = useState<string>("");
  const [elevationErrors, setElevationErrors] = useState<string>("");
  const [stackArrestorErrors, setStackArrestorErrors] = useState<string>("");
  const [additionalInformationErrors, setAdditionalInformationErrors] =
    useState<string>("");

  function handleExistingErrors(errors: Boolean) {
    existingErrors = errors;
  }

  function clearErrors() {
    setSalesOrderNumberErrors("");
    setSalesRepErrors("");
    setCustomerErrors("");
    setCustomerTypeErrors("");
    setJobNameOrNumberErrors("");
    setFiretubeIDErrors("");
    setFiretubeODErrors("");
    setFiretubeOWLErrors("");
    setNetDutyErrors("");
    setHoleSizeErrors("");
    setNumberOfHolesErrors("");
    setBoltCircleErrors("");
    setFlangeODErrors("");
    setStackODErrors("");
    setStackHeightErrors("");
    setStackLocationErrors("");
    setMultiTubeCenterDistanceErrors("");
    setApplicationErrors("");
    setEnvironmentErrors("");
    setElevationErrors("");
    setStackArrestorErrors("");
    setAdditionalInformationErrors("");

    handleExistingErrors(false);
  }

  const [confirmActive, setConfirmActive] = useState<boolean>(false);
  const [confirmText, setConfirmText] = useState<string>("");

  function handleConfirm() {
    setConfirmText("");
    setConfirmActive(false);
  }

  function clearForm() {
    setSalesOrderNumber(null);
    setSalesRep("");
    setCustomer("");
    setJobNameOrNumber("");
    setFiretubeID("");
    setFiretubeOD("");
    setFiretubeOWL("");
    setNetDuty(null);
    setHoleSize("");
    setNumberOfHoles(null);
    setBoltCircle("");
    setFlangeOD("");
    setStackOD("");
    setStackHeight("");
    setMultiTubeCenterDistance("");
    setElevation("");
    setAdditionalInformation("");
  }

  async function onSubmit(e: any) {
    e.preventDefault();

    if (salesRep === "") {
      setSalesRepErrors("Sales Representative is required.\n");
      handleExistingErrors(true);
    }
    if (!customer) {
      setCustomerErrors("Customer is required.\n");
      handleExistingErrors(true);
    }
    if (customerType === "0") {
      setCustomerTypeErrors("Customer Type is required.\n");
      handleExistingErrors(true);
    }
    if (!firetubeOD && !firetubeID) {
      setFiretubeIDErrors(
        "Either Firetube I.D. (in.) or Firetube O.D. (in.) is required.\n"
      );
      setFiretubeODErrors(
        "Either Firetube O.D. (in.) or Firetube I.D. (in.) is required.\n"
      );
      handleExistingErrors(true);
    }
    if (!firetubeOWL) {
      setFiretubeOWLErrors(
        "Firetube Overall Wetted Length (in.) is required.\n"
      );
      handleExistingErrors(true);
    }
    if (!netDuty) {
      setNetDutyErrors("Net Duty to the Process (BTU/hr) is required.\n");
      handleExistingErrors(true);
    }

    if (!existingErrors) {
      const submission: IFlamecoArrestorHousingDTO = {
        sales_order_number: salesOrderNumber,
        sales_rep: salesRep,
        customer: customer,
        customer_type: customerType,
        job_name_or_number: jobNameOrNumber,
        fire_tube_id: parseFloat(firetubeID!),
        fire_tube_od: parseFloat(firetubeOD!),
        fire_tube_owl: parseFloat(firetubeOWL),
        net_duty: netDuty!,
        hinge_orientation: hingeOrientation,
        hole_size: parseFloat(holeSize!),
        number_of_holes: numberOfHoles,
        bolt_circle: parseFloat(boltCircle!),
        flange_od: parseFloat(flangeOD!),
        stack_od: parseFloat(stackOD!),
        stack_height: parseFloat(stackHeight!),
        stack_location: stackLocation,
        multi_tube_center_distance: parseFloat(multiTubeCenterDistance!),
        application: application,
        environment: environment,
        elevation: parseFloat(elevation!),
        stack_arrestor_required: stackArrestorRequired,
        additional_information: additionalInformation,
        sender_email: accounts[0].username,
      };
      try {
        setLoading(true);
        const token: string = await getToken();
        await axios
          .post(
            `${process.env.REACT_APP_PORTAL_BACKEND_URL}/api/flameco`,
            submission,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
            setLoading(false);
            setConfirmActive(true);
            setConfirmText(
              "Data Sheet Submission successful. The form will now be cleared."
            );
            clearForm();
          })
          .catch((err) => {
            setLoading(false);
            setConfirmActive(true);
            setConfirmText(`There was a problem making the submission.`);
          });
      } catch (error) {
        setLoading(false);
        setConfirmActive(true);
        setConfirmText("There was an issue while making the submission.");
      }
    }
  }

  if (loading) return <Loading dataTestname="flameco-data-sheet-loading" />;

  return (
    <StyledFlamecoDataSheetDiv>
      <StyledProfireLogoDiv>
        <StyledLogo
          data-testid="logo"
          src={ToolsLogo}
          alt="profire primary red logo"
        />
      </StyledProfireLogoDiv>
      <StyledTitleDiv>
        <StyledFormTitle data-testid="title">
          Flameco Arrestor Housing Data Sheet
        </StyledFormTitle>
      </StyledTitleDiv>
      <StyledRequirementsDiv>
        <p data-testid="requirement-text">
          Required fields are marked with{" "}
          <StyledAsteriskSpan>*</StyledAsteriskSpan>
        </p>
      </StyledRequirementsDiv>
      <ConfirmationBox
        dataTestname="flameco-sheet-confirm-confirmation-box"
        heading={"Information"}
        message={confirmText}
        active={confirmActive}
        onOk={handleConfirm}
        displayCancel={false}
      />
      <StyledForm onSubmit={onSubmit}>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-sales-order-number"
            type="text"
            value={salesOrderNumber === null ? "" : salesOrderNumber.toString()}
            labelText="Sales Order Number"
            required={false}
            onChange={handleSalesOrderNumber}
            errors={salesOrderNumberErrors}
            placeholder="0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-sales-rep"
            type="text"
            value={salesRep}
            labelText="Sales Representative"
            required={true}
            onChange={handleSalesRep}
            errors={salesRepErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-customer"
            type="text"
            value={customer}
            labelText="Customer"
            required={true}
            onChange={handleCustomer}
            errors={customerErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel data-testid="customer-type-label">
            Customer Type <StyledAsteriskSpan>*</StyledAsteriskSpan>
          </StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-customer-type-dropdown"
            labelText={""}
            columns={customerTypeDropdown}
            selected={customerType}
            onchange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleCustomerType(e)
            }
            errors={customerTypeErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-job-name"
            type="text"
            value={jobNameOrNumber}
            labelText="Job Name or Number"
            required={false}
            onChange={handleJobNameOrNumber}
            errors={jobNameOrNumberErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-firetube-id"
            type="text"
            value={firetubeID === null ? "" : firetubeID.toString()}
            labelText="Firetube I.D. (in.)"
            required={true}
            onChange={handleFiretubeID}
            errors={firetubeIDErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-firetube-od"
            type="text"
            value={firetubeOD === null ? "" : firetubeOD.toString()}
            labelText="Firetube O.D. (in.)"
            required={true}
            onChange={handleFiretubeOD}
            errors={firetubeODErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-overall-wetted-length"
            type="text"
            value={firetubeOWL.toString()}
            labelText="Firetube Overall Wetted Length (in.)"
            required={true}
            onChange={handleFiretubeOWL}
            errors={firetubeOWLErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-net-duty"
            type="text"
            value={netDuty === null ? "" : netDuty.toString()}
            labelText="Net Duty to the Process (BTU/hr)"
            required={true}
            onChange={handleNetDuty}
            errors={netDutyErrors}
            placeholder="0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel>Hinge Orientation</StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-hinge-orientation-dropdown"
            labelText={""}
            columns={hingeOrientationDropdown}
            selected={hingeOrientation}
            onchange={(e: React.ChangeEvent<HTMLSelectElement>) =>
              handleHingeOrientation(e)
            }
            errors={hingeOrientationErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-hole-size"
            type="text"
            value={holeSize === null ? "" : holeSize.toString()}
            labelText="Size of Holes (in.)"
            required={false}
            onChange={handleHoleSize}
            errors={holeSizeErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-hole-number"
            type="text"
            value={numberOfHoles === null ? "" : numberOfHoles.toString()}
            labelText="Number of Holes"
            required={false}
            onChange={handleNumberOfHoles}
            errors={numberOfHolesErrors}
            placeholder="0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-bolt-circle"
            type="text"
            value={boltCircle === null ? "" : boltCircle.toString()}
            labelText="Bolt Circle - Center to Center (in.)"
            required={false}
            onChange={handleBoltCircle}
            errors={boltCircleErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-flange-od"
            type="text"
            value={flangeOD === null ? "" : flangeOD.toString()}
            labelText="Flange O.D. (in.)"
            required={false}
            onChange={handleFlangeOD}
            errors={flangeODErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-stack-od"
            type="text"
            value={stackOD === null ? "" : stackOD.toString()}
            labelText="Stack O.D. (in.)"
            required={false}
            onChange={handleStackOD}
            errors={stackODErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-stack-height"
            type="text"
            value={stackHeight === null ? "" : stackHeight.toString()}
            labelText="Stack Height (in.)"
            required={false}
            onChange={handleStackHeight}
            errors={stackHeightErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel data-testid="stack-location-label">
            Stack Location
          </StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-stack-location-dropdown"
            labelText={""}
            columns={stackLocationDropdown}
            selected={stackLocation}
            onchange={handleStackLocation}
            errors={stackLocationErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-multi-tube"
            type="text"
            value={
              multiTubeCenterDistance === null
                ? ""
                : multiTubeCenterDistance.toString()
            }
            labelText="Multi-Tube - Center to Center Distance (in.)"
            required={false}
            onChange={handleMultiTubeCenterDistance}
            errors={multiTubeCenterDistanceErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel data-testid="application-label">
            Application
          </StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-application-dropdown"
            data-testname={"flameco-application"}
            labelText={""}
            columns={applicationDropdown}
            selected={application}
            onchange={handleApplication}
            errors={applicationErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel data-testid="environment-label">
            Environment
          </StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-environment-dropdown"
            data-testname={"flameco-environment"}
            labelText={""}
            columns={environmentDropdown}
            selected={environment}
            onchange={handleEnvironment}
            errors={environmentErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-elevation"
            type="text"
            value={elevation === null ? "" : elevation.toString()}
            labelText="Elevation (ft.)"
            required={false}
            onChange={handleElevation}
            errors={elevationErrors}
            placeholder="0.0"
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <StyledFormInputLabel data-testid="stack-arrestor-label">
            Stack Arrestor with Wind Guard Required?
          </StyledFormInputLabel>
          <Dropdown
            dataTestname="flameco-sheet-stack-arrestor-required-dropdown"
            data-testname={"flameco-stack-arrestor-with-wind-guard-required"}
            labelText={""}
            columns={booleanDropdown}
            selected={stackArrestorRequired ? "Yes" : "No"}
            onchange={handleStackArrestorRequired}
            errors={stackArrestorErrors}
          />
        </StyledFormInputDiv>
        <StyledFormInputDiv>
          <CommonInput
            dataTestname="flameco-additional-information"
            type="text"
            value={additionalInformation}
            labelText="Additional Information"
            required={false}
            onChange={handleAdditionalInformation}
            errors={additionalInformationErrors}
          />
        </StyledFormInputDiv>
        <StyledFormButtonDiv>
          <ProfireButton
            dataTestname="flameco-data-sheet-submit-button"
            text="Submit"
            size={3}
            onClickFunction={clearErrors}
            defaultButton={true}
          />
          <StyledErrorMessage>
            {existingErrors ? (
              <>
                {"Please fix above errors and resubmit."}
                <br />
                {
                  "All fields requiring fixes will have messages like this one below them."
                }
              </>
            ) : (
              <div>
                {" "}
                &nbsp;
                <br /> &nbsp;
              </div>
            )}
          </StyledErrorMessage>
        </StyledFormButtonDiv>
      </StyledForm>
    </StyledFlamecoDataSheetDiv>
  );
};

export default FlamecoDataSheet;

const StyledFlamecoDataSheetDiv = styled.div`
  width: Calc(100% - 32px);
  max-width: Calc(1200px - 40px);
  min-width: Calc(320px - 40px);
  margin: 93px 16px 20px 16px;
  height: Calc(100% - 113px);
  background-color: white;

  z-index: 10;
  border-radius: 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    margin: 93px 20px 20px 20px;
    width: Calc(100% - 40px);
    padding-top: 25px;
  }
`;

const StyledProfireLogoDiv = styled.div`
  width: Calc(100% - 20px);
  text-align: left;

  padding: 0px 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 40px);
    padding: 0px 20px;
  }
`;

const StyledLogo = styled.img`
  width: 240px;
`;

const StyledTitleDiv = styled.div`
  width: Calc(100% - 20px);
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  padding: 0px 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 40px);
    padding: 0px 20px;
  }
`;

const StyledFormTitle = styled.h2`
  margin-top: 24px;
  margin-bottom: 0px;
`;

const StyledRequirementsDiv = styled.div`
  width: Calc(100% - 20px);
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  padding: 0px 10px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    width: Calc(100% - 40px);
    padding: 0px 20px;
  }

  p {
    margin-top: 0px;
    margin-bottom: 24px;
  }
`;

const StyledAsteriskSpan = styled.span`
  color: #9c1b30;
`;

const StyledForm = styled.form`
  padding: 0px 10px;
  background-color: white;
  margin-bottom: 25px;

  @media (min-width: ${(props) => props.theme.desktopMinBreakpoint}) {
    padding: 0px 20px;
    margin-bottom: 50px;
  }
`;

const StyledFormInputDiv = styled.div`
  text-align: left;
`;

const StyledFormInputLabel = styled.label`
  text-align: left;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};
`;

const StyledErrorMessage = styled.div`
  text-align: left;
  color: #9c1b30;
  font-size: ${(props) => props.theme.contentMainSize};
  font-weight: ${(props) => props.theme.contentMainWeight};

  width: 100%;
`;

const StyledFormButtonDiv = styled.div`
  text-align: left;
`;
