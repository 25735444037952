import React, { useState } from "react";
import styled from "styled-components";
import { IEWPAutomationForm } from "../../types/tools";
import Checkbox from "../common/formInputs/Checkbox";
import CommonInput from "../common/formInputs/CommonInput";
import { validateNumberString } from "../../utilities/validationFunctions";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
}

const CardConfigurationDetails: React.FC<SectionProps> = ({
  onChange,
  form,
}) => {
  const [hasIOExpansionCard, setHasIOExpansionCard] = useState<boolean | null>(
    form.has_io_expansion_card
  );
  const handleHasIOExpansionCard = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setHasIOExpansionCard(checked);

    onChange(checked, "has_io_expansion_card");
  };

  const [hasModbusCard, setHasModbusCard] = useState<boolean | null>(
    form.has_modbus_card
  );
  const handleHasModbusCard = (e: React.FormEvent<HTMLInputElement>) => {
    const { checked } = e.currentTarget;
    setHasModbusCard(checked);

    onChange(checked, "has_modbus_card");
  };

  const [networkCardCount, setNetworkCardCount] = useState<string | null>(
    form.network_card_count
  );
  const [networkCardCountErrors, setNetworkCardCountErrors] =
    useState<string>("");
  const handleNetworkCardCount = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setNetworkCardCountErrors("Only whole numbers are accepted.");
    } else {
      setNetworkCardCount(value === "" ? null : value);
      setNetworkCardCountErrors("");

      onChange(value, "network_card_count");
    }
  };

  const [startTBNumber, setStartTBNumber] = useState<string | null>(
    form.start_tb_number
  );
  const [startTBNumberErrors, setStartTBNumberErrors] = useState<string>("");
  const handleStartTBNumber = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    if (!validateNumberString(value) && value !== "") {
      setStartTBNumberErrors("Only whole numbers are accepted.");
    } else {
      setStartTBNumber(value === "" ? null : value);
      setStartTBNumberErrors("");

      onChange(value, "start_tb_number");
    }
  };

  return (
    <StyledMainDiv>
      <Checkbox
        dataTestname={"card-configuration-details-io-expansion-card"}
        isChecked={hasIOExpansionCard === null ? false : hasIOExpansionCard}
        handleChange={handleHasIOExpansionCard}
        label={"Has IO Expansion Card?"}
        arrayLetter={"C"}
      />
      <Checkbox
        dataTestname={"card-configuration-details-modbus-card"}
        isChecked={hasModbusCard === null ? false : hasModbusCard}
        handleChange={handleHasModbusCard}
        label={"Has Modbus Card?"}
        arrayLetter={"C"}
      />
      <CommonInput
        dataTestname={"card-configuration-details-network-card-count"}
        type={"text"}
        labelText="Network Card Count"
        value={networkCardCount === null ? "" : networkCardCount.toString()}
        onChange={handleNetworkCardCount}
        errors={networkCardCountErrors}
      />
      <CommonInput
        dataTestname={"card-configuration-details-start-tb-number"}
        type={"text"}
        labelText="Start TB Number"
        value={startTBNumber === null ? "" : startTBNumber.toString()}
        onChange={handleStartTBNumber}
        errors={startTBNumberErrors}
      />
    </StyledMainDiv>
  );
};

export default CardConfigurationDetails;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;
