import { IGenerateErrors } from "../../../types/tools";

export const SectionFinder = (firstField: string) => {

  let projectDetailsFields = [
    "applicationsummary",
    "projectnumber",
    "clientname",
    "siteid",
    "appliancename",
    "appliancetag",
    "documentnumber",
    "documentrevisionnumber",
    "firmwareversion",
    "generatenarrative",
    "generatesdk",
    "generatefat",
    "generatewiringdiagram",
    "is_panel",
  ]

  let applicationDetailsFields = [
    "is_farc_application",
    "is_incinerator_application",
    "is_on_off_control_application",
  ]

  /* the following code will be used in the case of the backend returning the radio button fields instead of hardcoded strings */
  // let processControlDetailsFields = [
  //   "pilot_off_mode_setting_set_to_disabled",
  //   "pilot_off_mode_setting_set_to_off_at_setpoint",
  //   "pilot_off_mode_setting_set_to_off_after_main",
  //   "pilot_off_mode_setting_set_to_follow_main",
  //   "low_fire_mode_setting_set_to_disabled",
  //   "low_fire_mode_setting_set_to_on_at_process_sp",
  //   "low_fire_mode_setting_set_to_on_at_low_fire_sp",
  // ]

  let cardConfigurationDetailsFields = [
    "has_io_expansion_card",
    "has_modbus_card",
    "network_card_count",
    "start_tb_number",
  ]

  let pilotConfigurationFields = [
    "pilot_relight_mode_set_to_during_flame_fail",
    "pilot_relight_mode_set_to_during_timeout",
    "pilot_flame_fail_response_time_setting",
    "main_flame_fail_response_time_setting",
    "pilot_relight_timeout_setting",
    "relight_attempts_setting",
    "minimum_pilots_running_setting",
  ]

  let section = "";

  switch (firstField) {
    case "designdrawingtable":
      section = "designdrawingtable";
      break;
    case "referencedocumenttable":
      section = "referencedocumenttable";
      break;
    case "applianceconfigtable":
      section = "applianceconfigtable";
      break;
    case "logicaltemptable":
      section = "logicaltemptable";
      break;
    case "physicaltempmap":
      section = "physicaltempmap";
      break;
    case "logicaliotable":
      section = "logicaliotable";
      break;
    case "physicaliomap":
      section = "physicaliomap";
      break;
    case "bmsio":
      section = "bmsio";
      break;
    case "Process Control Details":
      section = "Process Control Details";
      break;
    default:
      if (projectDetailsFields.includes(firstField)) {
        section = "projectdetails"
      } else if (applicationDetailsFields.includes(firstField)) {
        section = "applicationdetails"
      } else if (cardConfigurationDetailsFields.includes(firstField)) {
        section = "cardconfigurationdetails"
      } else if (pilotConfigurationFields.includes(firstField)) {
        section = "pilotconfiguration"
      }
      break;
  }

  return SectionTranslator(section)
};

export const SectionTranslator = (section: string) => {
  let translation: string = "";

  switch (section) {
    case "projectdetails":
      translation = "Project Details";
      break;
    case "applicationdetails":
      translation = "Application Details";
      break;
    case "processcontroldetails":
      translation = "Process Control Details";
      break;
    case "cardconfigurationdetails":
      translation = "Card Configuration Details";
      break;
    case "pilotconfiguration":
      translation = "Pilot Configuration";
      break;
    case "designdrawingtable":
      translation = "Engineering Documents";
      break;
    case "referencedocumenttable":
      translation = "Website Documents";
      break;
    case "applianceconfigtable":
      translation = "Appliance Details";
      break;
    case "logicaltemptable":
      translation = "Temperature Configuration";
      break;
    case "physicaltempmap":
      translation = "Temperature Assignment";
      break;
    case "logicaliotable":
      translation = "IO Expansion Inputs";
      break;
    case "physicaliomap":
      translation = "IO Expansion Assignment";
      break;
    case "bmsio":
      translation = "BMS Instrumentation";
      break;
    case "Process Control Details":
      translation = "Process Control Details";
      break;
    default:
      translation = "N/A"
      break;
  }

  return translation;
};

export const FieldTranslator = (field: string) => {
  let translation: string = "";

  switch (field) {
    case "applicationsummary":
      translation = "Application Summary";
      break;
    case "projectnumber":
      translation = "Project Number";
      break;
    case "clientname":
      translation = "Client Name";
      break;
    case "siteid":
      translation = "Site ID";
      break;
    case "appliancename":
      translation = "Appliance Name";
      break;
    case "appliancetag":
      translation = "Appliance Tag";
      break;
    case "documentnumber":
      translation = "Document Number";
      break;
    case "documentrevisionnumber":
      translation = "Document Revision Number";
      break;
    case "firmwareversion":
      translation = "Firmware Version";
      break;
    case "generatenarrative":
      translation = "Generate Narrative";
      break;
    case "generatesdk":
      translation = "Generate SDK";
      break;
    case "generatefat":
      translation = "Generate FAT";
      break;
    case "generatewiringdiagram":
      translation = "Generate Wiring Diagram";
      break;
    case "is_panel":
      translation = "Is Panel";
      break;
    case "is_farc_application" || "is_incinerator_application" || "is_on_off_control_application":
      translation = "Application Type";
      break;
    case "pilot_off_mode_setting_set_to_disabled" || "pilot_off_mode_setting_set_to_off_at_setpoint" || "pilot_off_mode_setting_set_to_off_after_main" || "pilot_off_mode_setting_set_to_follow_main":
      translation = "Pilot Off Mode";
      break;
    case "low_fire_mode_setting_set_to_disabled" || "low_fire_mode_setting_set_to_on_at_process_sp" || "low_fire_mode_setting_set_to_on_at_low_fire_sp":
      translation = "Low Fire Mode";
      break;
    case "has_io_expansion_card":
      translation = "Has IO Expansion Card";
      break;
    case "has_modbus_card":
      translation = "Has Modbus Card";
      break;
    case "network_card_count":
      translation = "Network Card Count";
      break;
    case "start_tb_number":
      translation = "Start TB Number";
      break;
    case "pilot_relight_mode_set_to_during_flame_fail" || "pilot_relight_mode_set_to_during_timeout":
      translation = "Pilot Relight Mode";
      break;
    case "pilot_flame_fail_response_time_setting":
      translation = "Pilot Flame Fail Response Time Setting";
      break;
    case "main_flame_fail_response_time_setting":
      translation = "Main Flame Fail Response Time Setting";
      break;
    case "pilot_relight_timeout_setting":
      translation = "Pilot Relight Timeout Setting";
      break;
    case "relight_attempts_setting":
      translation = "Relight Attempts Setting";
      break;
    case "minimum_pilots_running_setting":
      translation = "Minimum Pilots Running Setting";
      break;
    case "dwgnumber":
      translation = "DWG Number";
      break;
    case "dwgdesc":
      translation = "DWG Description";
      break;
    case "docnumber":
      translation = "Document Number";
      break;
    case "docname":
      translation = "Document Name";
      break;
    case "controllermac":
      translation = "Controller MAC";
      break;
    case "controllerdecimalmodbusaddress":
      translation = "Controller Decimal Modbus Address";
      break;
    case "controllername":
      translation = "Controller Name";
      break;
    case "controllercomments":
      translation = "Controller Comments";
      break;
    case "has_valve_wired_to_hfv_output" || "has_forced_draft_fan_wired_to_hfv_output" || "has_purge_fan_wired_to_hfv_output":
      translation = "Wired to HFV Output Setting";
      break;
    case "main_flame_detection_enabled":
      translation = "Main Flame Detection Enabled";
      break;
    case "main_permissive_enabled":
      translation = "Main Permissive Enabled";
      break;
    case "appliance_firing_rate_enabled":
      translation = "Appliance Firing Rate Enabled";
      break;
    case "pilotcardname":
      translation = "Pilot Card Name";
      break;
    case "is_uv_pilot_card":
      translation = "Is UV Pilot Card";
      break;
    case "pilotcardcomments":
      translation = "Pilot Card Comments";
      break;
    case "pilotvalvetag":
      translation = "Pilot Valve Tag";
      break;
    case "is_bleed_valve":
      translation = "Is Bleed Valve";
      break;
    case "pilotburnerelement":
      translation = "Burner Element";
      break;
    case "pilotigniterelement":
      translation = "Igniter Element";
      break;
    case "mainvalvetag":
      translation = "Main Valve Tag";
      break;
    case "is_incinerator_waste_gas_valve" || "is_incinerator_assist_gas_valve":
      translation = "Incinerator Setting";
      break;
    // case "is_bleed_valve":
    //   translation = "Is Bleed Valve";
    //   break;
    case "proof_of_closure":
      translation = "Proof of Closure";
      break;
    case "poctag":
      translation = "POC Tag";
      break;
    case "hfvvalvetag":
      translation = "High Fire Valve Tag";
      break;
    case "tcvtag":
      translation = "TCV Tag";
      break;
    case "tcvpurgeposition":
      translation = "TCV Purge Position";
      break;
    case "tcvpilotposition":
      translation = "TCV Pilot Position";
      break;
    case "tcvminimumposition":
      translation = "TCV Minimum Position";
      break;
    case "tcv_is_farc_output":
      translation = "Is FARC Output";
      break;
    case "farcwizardchannelsettingname":
      translation = "FARC Wizard Channel Setting Name";
      break;
    case "farcwizardchannelsettingoffposition":
      translation = "FARC Wizard Channel Setting Off Position";
      break;
    case "external_power_required":
      translation = "External Power Required";
      break;
    case "temptag":
      translation = "Temp Tag";
      break;
    case "tempdesc":
      translation = "Temp Description";
      break;
    case "temptype":
      translation = "Temp Type";
      break;
    case "tempiotype":
      translation = "Temp IO Type";
      break;
    case "tempcontrolmode":
      translation = "Temp Control Mode";
      break;
    case "tempapplianceshutdown":
      translation = "Temp Appliance Shutdown";
      break;
    case "tempesdsp":
      translation = "Temp ESD SP";
      break;
    case "tempmainoffsp":
      translation = "Temp Main Off SP";
      break;
    case "temppilotoffsp":
      translation = "Temp Pilot Off SP";
      break;
    case "tempprocesssp":
      translation = "Temp Process SP";
      break;
    case "tempcardname":
      translation = "Temp Card Name";
      break;
    case "tc1a":
      translation = "TC1A";
      break;
    case "tc1b":
      translation = "TC1B";
      break;
    case "tc2a":
      translation = "TC2A";
      break;
    case "tc2b":
      translation = "TC2B";
      break;
    case "iotag":
      translation = "IO Tag";
      break;
    case "iodesc":
      translation = "IO Description";
      break;
    case "iotype":
      translation = "IO Type";
      break;
    case "iosignal":
      translation = "IO Signal";
      break;
    case "iomode":
      translation = "IO Mode";
      break;
    case "iounits":
      translation = "IO Units";
      break;
    case "iolowsp":
      translation = "IO Low SP";
      break;
    case "iohighsp":
      translation = "IO High SP";
      break;
    case "iocomment":
      translation = "IO Comment";
      break;

    case "name":
      translation = "Name";
      break;
    case "mac":
      translation = "MAC";
      break;
    case "input1_tag":
      translation = "Input 1 Tag";
      break;
    case "input1_span":
      translation = "Input 1 Span";
      break;
    case "input1_out":
      translation = "Input 1 Out";
      break;
    case "input1_dip":
      translation = "Input 1 Dip";
      break;
    case "input2_tag":
      translation = "Input 2 Tag";
      break;
    case "input2_span":
      translation = "Input 2 Span";
      break;
    case "input2_out":
      translation = "Input 2 Out";
      break;
    case "input2_dip":
      translation = "Input 2 Dip";
      break;
    case "input3_tag":
      translation = "Input 3 Tag";
      break;
    case "input3_span":
      translation = "Input 3 Span";
      break;
    case "input3_out":
      translation = "Input 3 Out";
      break;
    case "input3_dip":
      translation = "Input 3 Dip";
      break;
    case "input4_tag":
      translation = "Input 4 Tag";
      break;
    case "input4_span":
      translation = "Input 4 Span";
      break;
    case "input4_out":
      translation = "Input 4 Out";
      break;
    case "input4_dip":
      translation = "Input 4 Dip";
      break;

    case "iofourtwentyout_name":
      translation = "IO Four Twenty Out Name";
      break;
    case "iofourtwentyout_mode":
      translation = "IO Four Twenty Out Mode";
      break;
    case "iofourtwentyout_controlinput":
      translation = "IO Four Twenty Out Control Input";
      break;
    case "io_expansion_output_is_farc_output":
      translation = "IO Expansion Output is FARC Output";
      break;
    // case "farcwizardchannelsettingname":
    //   translation = "FARC Wizard Channel Setting Name";
    //   break;
    case "farcwizard_setting_direction_is_set_to_direct":
      translation = "Direction is set to Direct";
      break;
    case "farcwizard_setting_post_purge_mode_is_set_to_purge":
      translation = "Post Purge Mode is set to Purge";
      break;
    case "farcwizardchannelsettingpurgeposition":
      translation = "FARC Wizard Channel Setting Purge Position";
      break;
    case "farcwizardchannelsettingpilotposition":
      translation = "FARC Wizard Channel Setting Pilot Position";
      break;
    // case "farcwizardchannelsettingoffposition":
    //   translation = "FARC Wizard Channel Setting Off Position";
    //   break;
    case "ionormallyclosed_mode":
      translation = "IO Normally Closed Dry Contact Mode";
      break;
    case "ionormallyclosed_controlinput":
      translation = "IO Normally Closed Control Input";
      break;
    case "ionormallyopen_mode":
      translation = "IO Normally Open Dry Contact Mode";
      break;
    case "ionormallyopen_controlinput":
      translation = "IO Normally Open Control Input";
      break;

    case "input":
      translation = "Input";
      break;
    // case "iotag":
    //   translation = "IO Tag";
    //   break;
    case "iodescription":
      translation = "IO Description";
      break;
    // case "iotype":
    //   translation = "IO Type";
    //   break;
    case "iocontrolmode":
      translation = "IO Control Mode";
      break;
    // case "iocomment":
    //   translation = "IO Comment";
    //   break;
  }
  //note: the commented out cases are duplicate names, which caused an error. the section should help define which is which, so I'm not too worried.

  return translation;
};

export const FixEntryNumber = (entry: string) => {
  let fixed = parseInt(entry) + 1;

  return fixed.toString();
};

export const DecodeBackendResponse = (data: any) => {
  let validationErrors: IGenerateErrors[] = [];

  data.detail.forEach((detail: any) => {
    if (detail.loc.length === 1) {
      validationErrors.push({
        section: SectionFinder(detail.loc[0]),
        entry: "N/A",
        field: FieldTranslator(detail.loc[0]),
        error: detail.msg
      })
    } else if (detail.loc.length === 2) {
      validationErrors.push({
        section: "N/A",
        entry: "N/A",
        field: FieldTranslator(detail.loc[1]),
        error: detail.msg
      })
    } else {
      validationErrors.push({
        section: SectionFinder(detail.loc[0]),
        entry: FixEntryNumber(detail.loc[1]),
        field: FieldTranslator(detail.loc[2]),
        error: detail.msg
      })
    }
  });

  return validationErrors
};