import React, { useState } from "react";
import styled from "styled-components";
import { IDWGInfo, IEWPAutomationForm } from "../../types/tools";
import {
  CopyObjectNoPointer,
  GetEqualityIndex,
} from "../../utilities/propertyAccess";
import ProfireButton from "../common/buttons/ProfireButton";
import ConfirmationBox from "../common/modals/ConfirmationBox";
import CommonCustomizableTable from "../common/tables/CommonCustomizableTable";
import AddDWGInfo from "./engineeringDocuments/AddDWGInfo";
import EditDWGInfo from "./engineeringDocuments/EditDWGInfo";

interface SectionProps {
  onChange: Function;
  form: IEWPAutomationForm;
  save: Function;
  saving: boolean;
}

const EngineeringDocuments: React.FC<SectionProps> = ({
  onChange,
  form,
  save,
  saving,
}) => {
  const [documents, setDocuments] = useState<IDWGInfo[]>(
    CopyObjectNoPointer(form.designdrawingtable)
  );

  //Table states
  const [elements] = useState<string[]>(["Data", "Data", "Edit", "Delete"]);

  const [elementsInfo] = useState<string[]>(["", "", "View", "", "", ""]);

  const [headers] = useState<string[]>(["dwgnumber", "dwgdesc", "", ""]);

  const [overwriteHeaders] = useState<string[]>([
    "DWG Number",
    "DWG Description",
    "",
    "",
  ]);

  const [elementsOnClick] = useState<any[]>([
    null,
    null,
    handleShowEditDWGInfo,
    showDeleteDWGInfoConfirmationBox,
  ]);

  const [colDisplayOnMobile] = useState<boolean[]>([true, true, true, true]);

  //Add DWGInfo
  const [showAddDWGInfo, setShowAddDWGInfo] = useState<boolean>(false);
  const [addDWGInfoCBActive, setAddDWGInfoCBActive] = useState<boolean>(false);
  const [addDWGInfoCBText, setAddDWGInfoCBText] = useState<string>("");
  const [retainedNewDWGInfo, setRetainedNewDWGInfo] = useState<IDWGInfo>();

  function handleShowAddDWGInfo() {
    if (showAddDWGInfo) {
      setRetainedNewDWGInfo(undefined);
    }
    setShowAddDWGInfo(!showAddDWGInfo);
  }

  //This function runs on clicking Save in Add DWGInfo
  function handleAddDWGInfo(e: IDWGInfo) {
    setShowAddDWGInfo(false);
    setAddDWGInfoCBActive(true);

    documents.push(e);
    onChange(documents, "designdrawingtable");

    setAddDWGInfoCBText(`${e.dwgnumber} successfully added`);
    setRetainedNewDWGInfo(undefined);
    setDWGInfoAfterAdd();
    save();
  }

  //This function runs on clicking OK after clicking Save in Add DWG Info
  function completeAddDWGInfo() {
    setAddDWGInfoCBActive(false);
    setAddDWGInfoCBText("");

    if (retainedNewDWGInfo !== undefined) {
      setShowAddDWGInfo(true);
    }
  }

  function setDWGInfoAfterAdd() {
    setDocuments(form.designdrawingtable);
  }

  //Edit DWGInfo
  const [showEditDWGInfo, setShowEditDWGInfo] = useState<boolean>(false);
  const [editDWGInfoCBActive, setEditDWGInfoCBActive] =
    useState<boolean>(false);
  const [editDWGInfoCBText, setEditDWGInfoCBText] = useState<string>("");
  const [editDWGInfoCBFinalDisplay, setEditDWGInfoCBFinalDisplay] =
    useState<boolean>(false);
  //retainedEditDWGInfo is the state that persists entered information that is used to repopulate the form in the event that the API Call errors out in the Confirmation Box
  const [retainedEditDWGInfo, setRetainedEditDWGInfo] = useState<
    IDWGInfo | undefined
  >();
  const [chosenEditDWGInfo, setChosenEditDWGInfo] = useState<IDWGInfo>();

  function handleShowEditDWGInfo(e: any = undefined) {
    if (showEditDWGInfo) {
      setShowEditDWGInfo(false);
      setChosenEditDWGInfo(undefined);
    } else {
      setShowEditDWGInfo(!showEditDWGInfo);
      setChosenEditDWGInfo(e);
    }
  }

  //This function runs on clicking Save in Edit DWGInfo
  function showEditDWGInfoConfirmationBox(e: IDWGInfo) {
    setShowEditDWGInfo(false);
    setEditDWGInfoCBActive(true);
    setEditDWGInfoCBText("Are you sure you want to save these changes?");
    setRetainedEditDWGInfo(e);
  }

  //This function runs on clicking Cancel in Edit DWGInfo
  function hideEditDWGInfoConfirmationBox() {
    setShowEditDWGInfo(false);
    setEditDWGInfoCBActive(false);
    setEditDWGInfoCBText("");
    setRetainedEditDWGInfo(undefined);
  }

  //This function runs on clicking Yes after clicking Save in Edit DWGInfo
  function handleEditDWGInfo() {
    setEditDWGInfoCBText("");

    setDocuments(
      documents.splice(
        GetEqualityIndex(documents, chosenEditDWGInfo),
        1,
        retainedEditDWGInfo!
      )
    );
    onChange(documents, "designdrawingtable");

    setEditDWGInfoCBText(
      `${retainedEditDWGInfo!.dwgnumber} successfully updated`
    );
    setRetainedEditDWGInfo(undefined);
    setDWGInfoAfterAdd();
    save();

    setEditDWGInfoCBFinalDisplay(true);
  }

  //This function runs on clicking OK after clicking Yes after clicking Save in Edit DWGInfo
  function completeEditDWGInfo() {
    setEditDWGInfoCBActive(false);
    setEditDWGInfoCBText("");
    setEditDWGInfoCBFinalDisplay(false);
    setChosenEditDWGInfo(undefined);

    if (retainedEditDWGInfo !== undefined) {
      setShowEditDWGInfo(true);
    }
  }

  //Delete DWGInfo
  const [deleteDWGInfoCBActive, setDeleteDWGInfoCBActive] =
    useState<boolean>(false);
  const [deleteDWGInfoCBText, setDeleteDWGInfoCBText] = useState<string>("");
  //finalDisplay is the bool that determines when it is time to show the single button when using a 2 button ConfirmationBox
  const [deleteDWGInfoCBFinalDisplay, setDeleteDWGInfoCBFinalDisplay] =
    useState<boolean>(false);
  const [chosenDeleteDWGInfo, setChosenDeleteDWGInfo] = useState<IDWGInfo>();

  //This function runs on clicking Save in Delete DWGInfo
  function showDeleteDWGInfoConfirmationBox(e: IDWGInfo) {
    setChosenDeleteDWGInfo(e);
    setDeleteDWGInfoCBActive(true);
    setDeleteDWGInfoCBText(`Are you sure you want to delete ${e.dwgnumber}?`);
  }

  //This function runs on clicking Cancel in Delete DWGInfo
  function hideDeleteDWGInfoConfirmationBox(e: any) {
    setDeleteDWGInfoCBActive(false);
    setDeleteDWGInfoCBText("");
  }

  //This function runs on clicking Yes after clicking Save in Delete DWGInfo
  function handleDeleteDWGInfo() {
    setDeleteDWGInfoCBText("");

    setDocuments(
      documents.splice(GetEqualityIndex(documents, chosenDeleteDWGInfo), 1)
    );
    onChange(documents, "designdrawingtable");

    setDeleteDWGInfoCBText(
      `${chosenDeleteDWGInfo!.dwgnumber} successfully deleted`
    );
    setDWGInfoAfterAdd();
    save();

    setDeleteDWGInfoCBFinalDisplay(true);
  }

  //This function runs on clicking Ok after clicking Yes in Delete DWGInfo
  function completeDeleteDWGInfo(e: any) {
    setDeleteDWGInfoCBActive(false);
    setDeleteDWGInfoCBText("");
    setDeleteDWGInfoCBFinalDisplay(false);
    setChosenDeleteDWGInfo(undefined);
  }

  return (
    <StyledMainDiv>
      <ConfirmationBox
        dataTestname="engineering-documents-add-dwg-info-confirmation-box"
        heading={"Information"}
        message={addDWGInfoCBText}
        active={addDWGInfoCBActive}
        onOk={completeAddDWGInfo}
        displayCancel={false}
      />
      <ConfirmationBox
        dataTestname="engineering-documents-edit-dwg-info-confirmation-box"
        heading={"Information"}
        message={editDWGInfoCBText}
        active={editDWGInfoCBActive}
        onOk={completeEditDWGInfo}
        onYes={handleEditDWGInfo}
        onCancel={hideEditDWGInfoConfirmationBox}
        displayCancel={true}
        finalDisplay={editDWGInfoCBFinalDisplay}
      />
      <ConfirmationBox
        dataTestname="engineering-documents-delete-dwg-info-confirmation-box"
        heading={"Information"}
        message={deleteDWGInfoCBText}
        active={deleteDWGInfoCBActive}
        onOk={completeDeleteDWGInfo}
        onYes={handleDeleteDWGInfo}
        onCancel={hideDeleteDWGInfoConfirmationBox}
        displayCancel={true}
        finalDisplay={deleteDWGInfoCBFinalDisplay}
      />
      <CommonCustomizableTable
        dataTestname="engineering-documents-common-customizable-table"
        usageIdentifier="engineering-documents"
        data={form.designdrawingtable}
        elements={elements}
        elementsInfo={elementsInfo}
        headers={headers}
        overwriteHeaders={overwriteHeaders}
        tableWidth={"100%"}
        tableMinWidth={"100%"}
        tableMaxHeight={"100%"}
        desktopColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        desktopColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        mobileColWidths={["33.33% - 33px", "66.66% - 33px", "3.33%", "3.33%"]}
        mobileColMinWidths={["33.33% - 33px", "66.66% - 33px", "33px", "33px"]}
        elementsOnClick={elementsOnClick}
        textAlign={["left", "left", "left", "left"]}
        tdHeight={"45px"}
        colDisplayOnMobile={colDisplayOnMobile}
        addPaddingRightForScroll={true}
        colJustify={[]}
        tdHeightMobile={"45px"}
        colFlexDirection={[]}
      />
      <StyledSpaceDiv />
      <StyledAddButtonDiv data-testid="add-dwg-info-button-div">
        <ProfireButton
          dataTestname="engineering-documents-add-dwg-info-button"
          onClickFunction={handleShowAddDWGInfo}
          text="Add DWG Info"
          size={4}
        />
        {showAddDWGInfo && (
          <AddDWGInfo
            active={showAddDWGInfo}
            onCancel={handleShowAddDWGInfo}
            onAdd={handleAddDWGInfo}
            retainedData={retainedNewDWGInfo}
            existingDWGInfo={form.designdrawingtable}
          />
        )}
      </StyledAddButtonDiv>
      {showEditDWGInfo && (
        <EditDWGInfo
          active={showEditDWGInfo}
          dwgInfo={chosenEditDWGInfo!}
          onCancel={handleShowEditDWGInfo}
          onEdit={showEditDWGInfoConfirmationBox}
          retainedData={retainedEditDWGInfo}
          existingDWGInfo={documents}
        />
      )}
    </StyledMainDiv>
  );
};

export default EngineeringDocuments;

const StyledMainDiv = styled.div`
  height: 100%;
  width: 100%;
`;

const StyledSpaceDiv = styled.div`
  height: 10px;
  width: 100%;
`;

const StyledAddButtonDiv = styled.div`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  display: block;
`;
